/**
 * Compares two arrays and returns the differences between them.
 */
function compareArrays<T>(
  oldArray: T[],
  newArray: T[]
): {
  added: T[];
  removed: T[];
} {
  const added = newArray.filter((item) => !oldArray.includes(item));
  const removed = oldArray.filter((item) => !newArray.includes(item));

  return { added, removed };
}

export default compareArrays;
