import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSessionStorage } from 'react-use';

interface TrackingContextType {
  trackedExperiences: Set<string>;
  addTrackedExperience: (trackingKey: string) => void;
}

const ExperienceTrackingContext = createContext<TrackingContextType>({} as any);

const TRACKED_EXPERIENCES_KEY = 'pd:tracked-experiences';

/**
 * A context used to track list names for performance analysis purposes
 */
export const ExperienceTrackingContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  // Tracked experiences are stored in session storage to prevent them from being persisted across sessions
  const [trackedExperiencesStorage, setTrackedExperiencesStorage] =
    useSessionStorage<string[]>(TRACKED_EXPERIENCES_KEY, []);

  const trackedExperiences = useMemo(
    () => new Set(trackedExperiencesStorage),
    [trackedExperiencesStorage]
  );

  /**
   * Adds a tracking key to the list of tracked experiences.
   */
  const addTrackedExperience = useCallback(
    (trackingKey: string): void =>
      setTrackedExperiencesStorage([...trackedExperiences, trackingKey]),
    [trackedExperiences, setTrackedExperiencesStorage]
  );

  return (
    <ExperienceTrackingContext.Provider
      value={{
        trackedExperiences,
        addTrackedExperience,
      }}
    >
      {children}
    </ExperienceTrackingContext.Provider>
  );
};

export const useExperienceTrackingContext = (): TrackingContextType =>
  useContext(ExperienceTrackingContext);
