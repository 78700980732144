import { useNinetailed } from '@ninetailed/experience.js-next';
import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  isAgeSegmentation,
  isBreedSegmentation,
  isNutritionSegmentation,
  NutritionSegmentationIds,
  SegmentationIds,
} from 'constants/segmentation';
import useSyncSegmentation from 'hooks/pet-profile/useSyncSegmentation';
import {
  InputProductType,
  isFoodType,
} from 'interfaces/api-input/input-product-type';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';
import convertProfileToArray from 'utils/pet-profile/convert-profile-to-array';
import { useAuth } from './auth';
import { useSegmentationContext } from './segmentation';

export interface PetProfile {
  selectedAge?: AgeSegmentationIds;
  selectedBreed?: BreedSegmentationIds;
  selectedNeeds?: Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  selectedAllergies?: Array<AllergySegmentationIds>;
  selectedFoodTypes?: Array<InputProductType>;
  /** For tracking last touched segmentation, allergy should be filtered out of from this property */
  lastTouched?: SegmentationIds;
}

interface PetProfileContextType {
  isProfileMinimal: boolean;
  petProfile: PetProfile;
  setPetProfile: React.Dispatch<React.SetStateAction<PetProfile>>;
  updatePetProfile: (
    value: SegmentationIds | undefined,
    allergies?: AllergySegmentationIds[]
  ) => void;
}

const PetProfileContext = createContext<PetProfileContextType>(
  {} as PetProfileContextType
);

const INITIAL_STATE = {
  selectedAge: undefined,
  selectedAllergies: [],
  selectedBreed: undefined,
  selectedFoodTypes: [
    InputProductType.DryFood,
    InputProductType.WetFood,
    InputProductType.Snacks,
    InputProductType.Barf,
  ],
  selectedNeeds: [],
  lastTouched: undefined,
};

export const PetProfileProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [petProfileStorage, setPetProfileStorage] = useLocalStorage<PetProfile>(
    'pd:pet-profile',
    INITIAL_STATE
  );

  const [petProfile, setPetProfile] = useState<PetProfile>({
    ...INITIAL_STATE,
    ...petProfileStorage,
  });

  useEffect(() => {
    setPetProfileStorage(petProfile);
  }, [petProfile, setPetProfileStorage]);

  // Check if the user has a 0 or 1 attribute in their pet profile
  const isProfileMinimal = useMemo(() => {
    return (
      convertProfileToArray({ petProfile }).filter((v) => !isFoodType(v))
        .length <= 1
    );
  }, [petProfile]);

  /**
   * Update the pet profile with a new value based on the segmentation id
   */
  const updatePetProfile = (
    value: SegmentationIds | undefined,
    allergies: AllergySegmentationIds[] | undefined
  ): void => {
    if (!value) return;

    const updateNeedsAndAllergies = (
      prevProfile: PetProfile,
      needs: (NutritionSegmentationIds | CatNutritionSegmentationIds)[],
      value: SegmentationIds,
      allergies: AllergySegmentationIds[] | undefined
    ): PetProfile => {
      if (
        !needs.includes(
          value as NutritionSegmentationIds | CatNutritionSegmentationIds
        ) &&
        value !== NutritionSegmentationIds.Intolerance
      ) {
        return {
          ...prevProfile,
          selectedNeeds: [
            ...needs,
            value as NutritionSegmentationIds | CatNutritionSegmentationIds,
          ],
        };
      } else if (
        value === NutritionSegmentationIds.Intolerance ||
        (needs.includes(value as NutritionSegmentationIds) &&
          allergies &&
          allergies.length > 0)
      ) {
        const selectedAllergies = new Set(prevProfile.selectedAllergies ?? []);
        if (allergies) {
          allergies.forEach((allergy) => selectedAllergies.add(allergy));
        }

        return {
          ...prevProfile,
          ...(needs.includes(value as NutritionSegmentationIds)
            ? { selectedNeeds: needs }
            : {
                selectedNeeds: [...needs, value as NutritionSegmentationIds],
              }),
          ...(selectedAllergies.size > 0 && {
            selectedAllergies: Array.from(selectedAllergies),
          }),
        };
      }
      return prevProfile;
    };

    setPetProfile((prevProfile) => {
      const needs = prevProfile.selectedNeeds ?? [];

      if (isAgeSegmentation(value)) {
        return { ...prevProfile, selectedAge: value as AgeSegmentationIds };
      }

      if (isBreedSegmentation(value)) {
        return { ...prevProfile, selectedBreed: value as BreedSegmentationIds };
      }

      if (isNutritionSegmentation(value)) {
        return updateNeedsAndAllergies(prevProfile, needs, value, allergies);
      }

      return prevProfile;
    });
  };

  const { identify } = useNinetailed();
  const { customer } = useAuth();

  /**
   * Sync segmentation to Ninetailed
   */
  useEffect(() => {
    const petProfileArray = convertProfileToArray({ petProfile });
    identify(`${customer ? customer.customerId : ''}`, {
      petProfile: petProfileArray,
    });
  }, [customer, identify, petProfile]);

  const { pushSegmentation, multipleSegmentation } = useSegmentationContext();
  useSyncSegmentation({
    petProfile,
    multipleSegmentation,
    pushSegmentation,
  });

  const value = {
    isProfileMinimal,
    petProfile,
    setPetProfile,
    updatePetProfile,
  };

  return (
    <PetProfileContext.Provider value={value}>
      {children}
    </PetProfileContext.Provider>
  );
};

export const usePetProfile = (): PetProfileContextType =>
  useContext(PetProfileContext);
