import { Handles } from 'constants/collection/handles';
import { SegmentationIds } from 'constants/segmentation';
import { useRouteContext } from 'contexts/route';
import { SegmentationStore } from 'contexts/segmentation';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

type Input = {
  segmentation?: string | SegmentationStore;
};

type OutPut = {
  shouldOmitPersonalization: boolean;
};

const ADULT_DOG_CDP = 'fur-hunde-adult';
const ADULT_CAT_CDP = Handles.allProductsForCat;

const AGE_RELATED_SEGMENTATION_IDS_SET = new Set<SegmentationIds>([
  SegmentationIds.Welpe,
  SegmentationIds.DogSenior,
  SegmentationIds.Kitten,
  SegmentationIds.CatSenior,
  SegmentationIds.DogSenior,
  SegmentationIds.DogAdult,
  SegmentationIds.CatSenior,
  SegmentationIds.CatAdult,
]);

/**
 * Check if PDP and CDP are related to adult pet food based on current and previous url.
 * And check if we should omit personalization as combination might be confusing for user.
 * e.g Segmentation welpe and adult collection page.
 * @see : https://petsdeli.atlassian.net/browse/PT-5327
 */
const useIsAdultRelated = ({ segmentation }: Input): OutPut => {
  const { asPath } = useRouter();

  const { previousPath } = useRouteContext();

  // We assume that it's adult related when adult cdp and pdp pages afterwards
  const isAdultRelated = useMemo(() => {
    if (!segmentation) {
      return false;
    }

    const _segmentation =
      typeof segmentation === 'string'
        ? segmentation
        : segmentation.ageSegmentation;

    if (!_segmentation) {
      return false;
    }

    if (
      !AGE_RELATED_SEGMENTATION_IDS_SET.has(_segmentation as SegmentationIds)
    ) {
      return false;
      // in age related cdp page
    } else if (
      asPath.indexOf(ADULT_DOG_CDP) > -1 ||
      asPath.indexOf(ADULT_CAT_CDP) > -1
    ) {
      return true;
      // in pdp page after age related pdp
    } else if (
      previousPath &&
      (previousPath.indexOf(ADULT_DOG_CDP) > -1 ||
        previousPath.indexOf(ADULT_CAT_CDP) > -1) &&
      asPath.indexOf('product') > -1
    ) {
      return true;
    }
    return false;
  }, [asPath, previousPath, segmentation]);

  const shouldOmitPersonalization = useMemo(() => {
    if (!segmentation) {
      return false;
    }

    const _segmentation =
      typeof segmentation === 'string'
        ? segmentation
        : segmentation.ageSegmentation;

    if (!_segmentation) {
      return false;
    }

    return !!(
      AGE_RELATED_SEGMENTATION_IDS_SET.has(_segmentation as SegmentationIds) &&
      isAdultRelated
    );
  }, [isAdultRelated, segmentation]);

  return {
    shouldOmitPersonalization,
  };
};

export default useIsAdultRelated;
