import { useEffect, useRef, useState } from 'react';

/**
 * Hook to detect changes in state
 */
export default function useDetectChanges<T>(state: T): Partial<T> | null {
  const previousStateRef = useRef<T | undefined>(undefined);
  const [changes, setChanges] = useState<Partial<T> | null>(null);

  useEffect(() => {
    if (
      previousStateRef.current !== undefined &&
      previousStateRef.current !== null
    ) {
      const newChanges: Partial<T> = {};

      for (const key in state) {
        if (state[key] !== previousStateRef.current[key]) {
          newChanges[key] = state[key];
        }
      }

      if (Object.keys(newChanges).length > 0) {
        setChanges(newChanges);
      } else {
        setChanges(null);
      }
    }

    previousStateRef.current = state;
  }, [state]);

  return changes;
}
