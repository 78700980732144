import { useEffect } from 'react';

/**
 * Hook to handle overflow hidden on body
 */
const useOverflowHidden = (active: boolean): void => {
  useEffect(() => {
    if (active) {
      const scrollY = window.scrollY;
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${scrollY}px`;

      return () => {
        document.body.style.overflow = originalStyle;
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        window.scrollTo(0, scrollY);
      };
    }
  }, [active]);
};

export default useOverflowHidden;
