import { TestData } from 'hooks/test/use-test-5830/testData';
import React, { useCallback, useMemo, useState } from 'react';
import { omitNullableHandler } from 'utils/type-helper';

type SegmentationId = string;
type ProductId = string;

/** Experience id and variant e.g 12QxHMfUIilJlxrUS5c6FW:1 */
type ExperienceIdVariant = string;
interface Context {
  alternativeSubtitles: { [key: SegmentationId]: string } | undefined;
  bestSellerData: { [key: SegmentationId]: ProductId[] } | undefined;
  experienceIds: Array<ExperienceIdVariant>;
  testData: TestData | undefined;
  getAdditionalProductHandles: (collectionHandle?: string) => string[];
  setAlternativeSubtitles: React.Dispatch<
    React.SetStateAction<Context['alternativeSubtitles'] | undefined>
  >;
  setBestSellerData: React.Dispatch<
    React.SetStateAction<Context['bestSellerData'] | undefined>
  >;
  setExperienceIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  setTestData: React.Dispatch<React.SetStateAction<TestData | undefined>>;
  shouldHideFnFactory: (
    collectionHandle?: string
  ) => (productHandle: string) => boolean;
}

/**
 * the idea is to have one global state for test variants
 * so that we only ever have to create the callback ONCE for each test
 */

const SpecialAbTestContext = React.createContext({} as Context);

const SpecialAbTestContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [testData, setTestData] = useState<Context['testData']>();
  const [bestSellerData, setBestSellerData] = useState<
    Context['bestSellerData']
  >({});
  const [alternativeSubtitles, setAlternativeSubtitles] = useState<
    Context['alternativeSubtitles']
  >({});
  const [experienceIds, setExperienceIds] = useState<Context['experienceIds']>(
    []
  );

  const shouldHideFnFactory = useMemo(() => {
    const fun = (collectionHandle?: string) => {
      const allHiddenHandles = !testData
        ? []
        : Object.keys(testData)
            .map((collectionHandle) => testData[collectionHandle]?.hideProducts)
            .filter(omitNullableHandler)
            .flat();

      return (productHandle: string) => {
        if (typeof testData === 'undefined') {
          return true;
        }

        if (
          collectionHandle &&
          testData[collectionHandle]?.hideProducts?.includes(productHandle)
        ) {
          return false;
        } else if (allHiddenHandles.includes(productHandle)) {
          return false;
        }
        return true;
      };
    };
    return fun;
  }, [testData]);

  const getAdditionalProductHandles = useCallback(
    (collectionHandle?: string) => {
      if (
        !collectionHandle ||
        typeof testData === 'undefined' ||
        testData[collectionHandle] === undefined
      ) {
        return [];
      }

      return testData[collectionHandle]?.showProducts || [];
    },
    [testData]
  );

  return (
    <SpecialAbTestContext.Provider
      value={{
        alternativeSubtitles,
        bestSellerData,
        experienceIds,
        testData,
        getAdditionalProductHandles,
        setAlternativeSubtitles,
        setBestSellerData,
        setExperienceIds,
        setTestData,
        shouldHideFnFactory,
      }}
    >
      {children}
    </SpecialAbTestContext.Provider>
  );
};

const useSpecialAbTestContext = (): Context =>
  React.useContext(SpecialAbTestContext);

export { SpecialAbTestContextProvider, useSpecialAbTestContext };
