import IconChevronRight from 'assets/icons/chevron-right-stroke.svg';
import DefaultDogProfileIcon from 'assets/icons/default-dog-profile-icon.svg';
import { usePetProfile } from 'contexts/pet-profile';
import { isFoodType } from 'interfaces/api-input/input-product-type';
import getPetProfileArray from 'modules/pet-profile/personalized-cdp/utils/get-pet-profile-array';
import isPetProfileEmpty from 'modules/pet-profile/personalized-cdp/utils/is-pet-profile-empty';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import { BREED_IMAGE_MAP } from '../constants';

interface Props {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Mobile navigation link for pet profile
 */
const PetProfileMobileNavigationLink: React.FC<Props> = ({ setVisibility }) => {
  const { petProfile } = usePetProfile();

  const handlePetProfileClick = (): void => {
    setVisibility(false);
    gtm(
      selectElement({
        element: ['Navigation', 'Hunde', 'Pet Profile'],
      })
    );
  };

  /**
   * Show edit modal when user has less than 2 input
   */
  const href = useMemo(() => {
    const array = getPetProfileArray({ petProfile }).filter(
      (v) => !isFoodType(v)
    );
    let url = '/pet-profile';
    if (array.length <= 1) {
      url = url + '?edit=1';
    }
    return url;
  }, [petProfile]);

  return (
    <Link href={href}>
      <a
        className="relative flex items-center gap-1.5 border-b border-grey-300 px-4 py-3 leading-none"
        onClick={() => handlePetProfileClick()}
      >
        {petProfile?.selectedBreed ? (
          <span className="h-8 shrink-0 overflow-hidden rounded-full">
            <Image
              src={BREED_IMAGE_MAP[petProfile.selectedBreed]}
              alt={`Breed ${petProfile.selectedBreed}`}
              width={32}
              height={32}
            />
          </span>
        ) : (
          <DefaultDogProfileIcon className="h-8" />
        )}
        <span className="text-sm font-black leading-4 text-primary-main">
          <FormattedMessage
            id={
              isPetProfileEmpty({ petProfile })
                ? 'common:pet-profile:create-new-profile'
                : 'common:pet-profile:your-pet'
            }
          />
        </span>

        <span className="bg-secondary-main rounded px-1.5 py-0.5 text-xs leading-[18px] text-text-dark-bg-contrast-white">
          <FormattedMessage id="header:pet-profile:badge" />
        </span>
        <IconChevronRight className="ml-auto h-8 w-6 text-primary-main" />
      </a>
    </Link>
  );
};

export default PetProfileMobileNavigationLink;
