import IconCloseBold from 'assets/icons/close-bold.svg';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import useMobile from 'hooks/common/use-mobile';
import useOverflowHidden from 'hooks/common/use-overflow-hidden';
import { Sheet } from 'react-modal-sheet';
import { SheetProps } from 'react-modal-sheet/src/types';

export type SheetComProps = Omit<SheetProps, 'children'>;

/**
 * Sheet component based on react-modal-sheet library
 *
 * @see https://github.com/Temzasse/react-modal-sheet
 * In order to avoid an error related the use of framer-motion, we need to wrap the component with LazyMotionWrapper with strict set to false
 */
const SheetComponent: React.FC<React.PropsWithChildren<SheetComProps>> = ({
  children,
  isOpen,
  onClose,
  onOpenStart,
  onCloseStart,
  ...others
}) => {
  const isMobile = useMobile();

  useOverflowHidden(isOpen);

  return (
    <LazyMotionWrapper strict={false}>
      <Sheet
        detent="content-height"
        isOpen={isOpen}
        onClose={onClose}
        tweenConfig={{ ease: 'easeOut', duration: 0.3 }}
        disableScrollLocking={true}
        onOpenStart={() => {
          onOpenStart?.();
        }}
        onCloseStart={() => {
          onCloseStart?.();
        }}
        {...others}
      >
        <Sheet.Container
          style={{
            borderTopLeftRadius: isMobile ? '24px' : '8px',
            borderTopRightRadius: isMobile ? '24px' : '8px',
          }}
        >
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              {!isMobile && (
                <button onClick={onClose}>
                  <IconCloseBold className="absolute right-0 top-0 mr-4 mt-4 h-4 w-4 text-text-light-bg-secondary" />
                </button>
              )}
              {children}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} />
      </Sheet>
    </LazyMotionWrapper>
  );
};

export default SheetComponent;
