/**
 * DynamicTestScenario const
 */
export const DynamicTestScenario = {
  /** Current UX with current collection */
  control: 0,
  /** Current UX with Data driven logic with 1 segmentation */
  variantA: 1,
  /** Dynamic UX with logic (scoring) with multiple segmentation */
  variantB: 2,
  /** Dynamic UX with logic (scoring / data driven) with multiple segmentation */
  variantC: 3,
} as const;

export type DynamicTestScenario =
  (typeof DynamicTestScenario)[keyof typeof DynamicTestScenario];

export function isDynamicTestScenario(
  value: any
): value is DynamicTestScenario {
  return Object.values(DynamicTestScenario).includes(value);
}
