import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import IconMinus from '../../assets/icons/minus.svg';
import IconPlus from '../../assets/icons/plus.svg';

const DEBOUNCE_DELAY = 500;

export interface Props {
  value: number;
  debounced?: boolean;
  minValue?: number;
  maxValue?: number;
  suborderPage?: boolean;
  onChange: (newValue: number) => void;
}

const AmountInput: React.FC<Props> = ({
  debounced,
  minValue = 0,
  maxValue = Infinity,
  suborderPage,
  value,
  onChange,
}) => {
  const [internalValue, setIntervalValue] = useState(value || minValue);

  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (value && value !== internalValue) {
      setIntervalValue(value);
    }
  }, [value]); // `internalValue` shouldn't be in deps

  const onChangeDebounced = useMemo(() => {
    if (!debounced) {
      return (value: number) => onChangeRef.current(value);
    }
    return debounce(
      (value: number) => onChangeRef.current(value),
      DEBOUNCE_DELAY
    );
  }, [debounced]);

  const onIncrement = useCallback(
    (event) => {
      event.preventDefault();
      const newValue = clamp(internalValue + 1, minValue, maxValue);
      setIntervalValue(newValue);
      onChangeDebounced(newValue);
    },
    [internalValue, minValue, maxValue, onChangeDebounced]
  );

  const onDecrement = useCallback(
    (event) => {
      event.preventDefault();
      const newValue = clamp(internalValue - 1, minValue, maxValue);
      setIntervalValue(newValue);
      onChangeDebounced(newValue);
    },
    [internalValue, minValue, maxValue, onChangeDebounced]
  );

  const onInputChange = useCallback(
    (event) => {
      event.stopPropagation();
      const newValue =
        clamp(parseInt(event.target.value, 10), minValue, maxValue) || minValue;
      setIntervalValue(newValue);
      if (newValue !== 0) {
        onChangeDebounced(newValue);
      }
    },
    [minValue, maxValue, setIntervalValue, onChangeDebounced]
  );

  const onInputBlur = useCallback(
    (event) => {
      const newValue =
        clamp(parseInt(event.target.value, 10), minValue, maxValue) || minValue;
      setIntervalValue(newValue);
      onChangeRef.current(newValue);
    },
    [minValue, maxValue, setIntervalValue]
  );

  return (
    <>
      {/* Desktop Version */}
      <div
        data-test="amount-section-desktop"
        className="hidden h-10 w-full flex-grow overflow-hidden rounded-lg bg-other-white text-text-light-bg-secondary sm:flex "
      >
        <button
          className="flex h-12 w-12 flex-shrink-0 items-center justify-center bg-primary-main text-other-white focus:outline-none disabled:cursor-default disabled:opacity-50"
          onClick={onDecrement}
          disabled={suborderPage}
        >
          <IconMinus className="h-3 w-3" />
        </button>
        <input
          className="border-grey-200 flex flex-grow items-center justify-center border-b border-t px-1 text-center font-black outline-none"
          style={{ minWidth: '2rem' }}
          value={internalValue}
          onBlur={onInputBlur}
          onChange={onInputChange}
        />
        <button
          className="flex h-12 w-12 flex-shrink-0 items-center justify-center bg-primary-main text-other-white focus:outline-none disabled:cursor-default disabled:opacity-50"
          onClick={onIncrement}
          disabled={suborderPage}
        >
          <IconPlus className="h-3 w-3" />
        </button>
      </div>

      {/* Mobile Version */}
      <div
        data-test="amount-section-mobile"
        className="mt-2 flex h-10 w-full flex-grow overflow-hidden rounded-lg bg-other-white text-text-light-bg-secondary shadow-tight sm:hidden md:w-40"
      >
        <button
          data-test="amount-button-minus"
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center bg-primary-main text-other-white focus:outline-none disabled:cursor-default disabled:opacity-50"
          onClick={onDecrement}
          disabled={suborderPage}
        >
          <IconMinus className="h-3 w-3" />
        </button>
        <input
          data-test="amount-input"
          className="flex flex-grow items-center justify-center px-1 text-center font-black outline-none"
          style={{ minWidth: '2rem' }}
          value={internalValue}
          onBlur={onInputBlur}
          onChange={onInputChange}
        />
        <button
          data-test="amount-button-plus"
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center bg-primary-main text-other-white focus:outline-none disabled:cursor-default disabled:opacity-50"
          onClick={onIncrement}
          disabled={suborderPage}
        >
          <IconPlus className="h-3 w-3" />
        </button>
      </div>
    </>
  );
};

export default AmountInput;
