import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface SegmentationStore {
  allSegments: string[] | undefined;
  updateAllSegments: (segments: string[] | undefined) => void;
}

const areArraysEqual = (a: Set<string>, b: Set<string>): boolean => {
  if (a.size !== b.size) return false;
  return [...a].every((value) => b.has(value));
};

/**
 * Zustand Store to manage segmentation states
 */
export const useSegmentationStore = create<SegmentationStore>()(
  persist(
    (set) => ({
      allSegments: undefined,
      updateAllSegments: (segments) =>
        set((state) => {
          if (!segments) {
            return { allSegments: state.allSegments };
          }

          if (!state.allSegments) {
            const uniqueSegments = [...new Set(segments)];
            return { allSegments: uniqueSegments };
          }

          const uniqueNewSegments = [...new Set(segments)];

          if (
            areArraysEqual(
              new Set(state.allSegments),
              new Set(uniqueNewSegments)
            )
          ) {
            return state;
          }

          const filteredNewSegments = uniqueNewSegments.filter(
            (segment) => !state.allSegments?.includes(segment)
          );

          if (filteredNewSegments.length === 0) {
            return state;
          }

          return {
            allSegments: [...filteredNewSegments, ...state.allSegments],
          };
        }),
    }),
    {
      name: 'pd:all-segments-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
