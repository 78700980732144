export default {
  'header:account': 'Account',
  'header:account:greeting': 'Hi, {firstName}',
  'header:cart': 'Warenkorb',
  'header:search': 'Suche',
  'header:pet-profile': 'Dein Hund',
  'header:pet-profile:badge': 'Neu',
  'header:discount': 'Rabatt',
  'header:navigation-dogs': 'Hunde',
  'header:navigation-cats': 'Katzen',
  'header:navigation:mobile:back': 'Zurück',
  'header:navigation:mobile:magazine': 'Magazin',
  'header:navigation:mobile:consulting': 'Beratung',
  'header:usp:trusted-shop': '4,89/5,0 ,,Sehr gut”- bei über 4.856 Bewertungen',
  'header:usp:faq-help': 'FAQ & Hilfe',
  'header:usp:free-shipping': 'Versandkostenfreie Lieferung ab 50 €',
  'header:usp:recipes': 'Auserwählte und leckere Rezepturen',
  'header:usp:acceptance': 'Besonders hohe Akzeptanz',
  'header:usp:no-grains': '100% echtes Fleisch - 0% Getreide',
  'header:usp:ratings':
    '98% Positive Bewertungen: über 55.000 glückliche Fellnasen',
  'header:usp:donations':
    '1000% Tierliebe - regelmäßige Spenden für Tiere in Not',
  'header:usp:good-choice': 'Gute Wahl! Deine Fellnase wird es dir danken.',
  'header:search:placeholder': 'Futter finden',
  'header:search:last-queries': 'LETZTE SUCHEN',
  'header:search:delete': 'LÖSCHEN',
  'header:subs-promo-bar:title': 'Rabatt mit jeder Lieferung!',
  'header:subs-promo-bar:info-link': 'Jetzt mehr erfahren',

  'header:usp:free-shipping:EC': 'Versandkostenfreie Lieferung ab € 60 ',
  'header:usp:free-shipping:NC': '<b> Versandkostenfrei für Neukunden </b>',
  'header:tastyguarantee:money-back:modal:title':
    'Pets Deli löst Futterprobleme - nachweislich.',
  'header:tastyguarantee:money-back:modal:sub-title':
    "Unsere Devise: <b>Schmeckt nicht, gibt's nicht!</b> Falls es deiner Fellnase wiedererwartend nicht schmecken sollte, erhältst du von uns eine kostenlose Futter-Alternative.",
  'header:tastyguarantee:money-back:modal:list-title': "So einfach geht's:",
  'header:tastyguarantee:money-back:modal:step-1':
    'Fülle einfach unser Kontaktformular aus, welches du hier findest. Nutze bei Problem bitte “Lecker Garantie” <a>Anfrage einreichen - PETS DELI</a>',
  'header:tastyguarantee:money-back:modal:step-2':
    'Erhalte eine kostenlose Futterberatung mit unseren Ernährungsexperten, um das richtige Alternativ-Produkt passend für eure Bedürfnisse zu finden.',
  'header:tastyguarantee:money-back:modal:step-3':
    'Wir kümmern uns, dass du dein neues Produkt schnellstmöglich zugeschickt bekommst.',
  'header:usp:test:text-1': 'Gratis Lieferung für Neukunden',
  'header:usp:test:text-2': '91% gelöste Futterprobleme',
  'header:usp:test:text-3': '10% Spar-Abo Rabatt',
  'header:usp:test:text-4': 'Über 98.654 zufriedene Kunden',
};
