import { gtmPush } from './tagmanager';

export enum EventName {
  CartSelectCheckout = 'Cart Select Checkout',
  CheckoutExpressDisplay = 'Checkout Express Display',
  CheckoutExpressSubmitOrder = 'Checkout Express Submit Order',
  CheckoutPurchase = 'Checkout Purchase',
  NewsletterSignup = 'newsletterSignup',
  LeadFormSignup = 'leadFormSignup',
  CartAddTo = 'Add To Cart',
  CDPOpenSwitcher = 'Open CDP Switcher',
  CDPSwitch = 'Switch CDP',
  CDPOpenVariantSelector = 'Open CDP Variant Selector',
  CheckoutShippingAddressSubmit = 'Checkout ShippingAddress Submit',
  CookieConsentDisplay = 'DisplayCookieConsent',
  CookieConsentDismissed = 'DismissedCookieConsent',
  CookieConsentAccepted = 'AcceptedCookieConsent',
  ContactClose = 'ContactButton_Close',
  ContactOpen = 'ContactButton_Open',
  ContactClickWhatsApp = 'ContactButton_WhatsApp',
  ContactClickEmail = 'ContactButton_Mail',
  ContactClickPhone = 'ContactButton_Phone',
  CountryModalClicked = 'ClickedShopCountryModal',
  CountryModalDismissed = 'DismissedShopCountryModal',
  DOISubscribeToNewsletter = 'DOI Subscribe To Newsletter',
  PromoBarClicked = 'ClickedPromoBar',
  PromoBarDismissed = 'DismissedPromoBar',
  CheckoutInitiated = 'Checkout Initiated',
  WebVitals = 'Web Vitals',
  NextJsMetric = 'NextJS Metric',
  ReorderMyAccount = 'ReorderMyAccount',
  ReorderDetail = 'ReorderDetail',
  SearchStart = 'SearchStart',
  HomeStoryClick = 'HomeStoryClick',
  RafShare = 'RAF_SHARE',
  PDPZoomStart = 'zoom_start',
  QuickFilterClick = 'QuickFilterClick',
  RegularFilterClick = 'RegularFilterClick',
  NewBliebtSectionClick = 'NewBliebtSectionClick',
  SubsActivationAtCart = 'SubsActivationAtCart',
  IntervalChangeAtCart = 'IntervalChangeAtCart',
}

export enum EventNameEE {
  CartAddToEE = 'eeAddToCart',
}

type EventNames = EventName | EventNameEE;

interface EventData {
  ecommerce?: any; //for the newer GA Advanced Eccommerce Tags
  attributes?: any; // 'event' could be seen as a function, attributes would be the functions attributes
  [key: string]: any;
}

export const trackEvent = async (
  name: EventNames,
  data?: EventData,
  eventCallback?: any
) => {
  const { ecommerce, attributes, ...additionalAttributes } = data || {};
  const isEcommerceEvent = /^ee/.test(name);
  if (isEcommerceEvent && !ecommerce) {
    throw Error('Ecommerce Property required');
  }
  if (ecommerce && !isEcommerceEvent) {
    throw Error(`Ecommerce Event Name Invalid: ${name}`);
  }

  const trackingObject = {
    event: name,
    attributes: attributes || {},
    ecommerce: ecommerce || {},
    ...additionalAttributes,
    eventCallback,
  };

  gtmPush(trackingObject);
  // console.log(
  //   eventCallback ? 'Track async event' : 'Track event',
  //   JSON.stringify(trackingObject, null, 2)
  // );
};

export const trackAsyncEvent = (name: EventNames, data: EventData) =>
  new Promise((resolve) => {
    trackEvent(name, data, () => {
      setTimeout(resolve, 300);
    });
    setTimeout(resolve, 3000);
  });
