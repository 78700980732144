import { HttpStatus } from 'constants/http-status';
import { Product } from 'interfaces/product';
import { CollectionProduct as CollectionProductClass } from 'models/collection/collection';
import { axios, axiosWithoutRequestInterceptor } from 'utils/axios';
import { PetContextValues } from '../contexts/pet';
import {
  ProductSegmentation,
  StaticPdp,
} from '../modules/product-details/types';
import { getCollectionHandleByProductDetails } from '../utils/collection';
import { PersonalizedProductDetailsResponse } from './product/getPersonalizedProductDetails/types';

export type SearchProductResponse = {
  products: Array<CollectionProductClass>;
  total: number;
};

export type NewSearchProductsRequest = {
  searchTerm: string;
  perPage?: number;
  from?: number;
  petType: PetContextValues | 'both';
};

export const newSearchProducts = async ({
  searchTerm,
  perPage = 20,
  from = 0,
  petType,
}: {
  searchTerm: string;
  perPage?: number;
  from?: number;
  petType: PetContextValues | 'both';
}): Promise<SearchProductResponse> => {
  const response = await elasticSearchProducts({
    query: searchTerm,
    hitsPerPage: perPage,
    from,
    indexName: 'products',
    petType: petType === 'both' ? undefined : petType,
  });

  return {
    products: response[0].hits
      .map((product) => {
        return {
          ...product,
          image: {
            src: product?.images[0].originalSrc || '',
            altText: product?.images[0].altText,
            id: product?.images[0].id,
          },
          variants: product.variants.map((variant) => {
            return {
              ...variant,
              image: {
                src: variant.image.originalSrc,
                alt: variant.image.altText || '',
              },
            };
          }),
        };
      })
      .map((value) => {
        return new CollectionProductClass(value);
      }),
    total: response[0].nbHits,
  };
};

type ElasticSearchRequest = {
  indexName: string;
  query: string;
  hitsPerPage?: number;
  from?: number;
  petType?: PetContextValues | 'both';
};

enum MetafieldNameSpaces {
  Petsdeli = 'petsdeli',
  Subscription = 'subscription',
  Accentuate = 'accentuate',
  Rating = 'rating',
}

type ElasticSearchResponse = Array<{
  hits: Array<{
    availableForSale: boolean;
    handle: string;
    shopId: string;
    accentuateMainTitle: string;
    tags: Array<string>;
    id: number;
    image: string;
    images: Array<{
      altText: string;
      id: number;
      originalSrc: string;
    }>;
    objectID: string;
    petType: string;
    productId: string;
    productType: string;
    title: string;
    metadata: {
      [key in MetafieldNameSpaces]: {
        [key in string]: any;
      };
    };
    variants: Array<{
      availableForSale: boolean;
      image: {
        altText?: null | string;
        originalSrc: string;
        id: number;
      };
      quantityAvailable: number;
      metadata: {
        accentuate: { [key: string]: string };
        subscription: { [key: string]: string };
        petsdeli: { [key: string]: string };
      };
      weight: number;
      id: number;
      sku: string;
      title: string;
      priceV2: {
        amount: string;
        currencyCode: string;
      };
      compareAtPrice?: string;
      weightUnit: 'KILOGRAMS' | 'GRAMS' | 'POUNDS' | 'OUNCES';
    }>;
  }>;
  /** number of the results */
  nbHits: number;
}>;

const elasticSearchProducts = async ({
  indexName,
  query,
  hitsPerPage,
  from,
  petType,
}: ElasticSearchRequest): Promise<ElasticSearchResponse> =>
  await axios
    .post<ElasticSearchResponse>(
      `${process.env.API_ORIGIN_ELASTIC_SEARCH}`,
      [{ indexName, hitsPerPage, query, from, petType }],
      { withCredentials: true, withoutNinetailed: true }
    )
    .then(({ data }) => data);

/**
 * Collection Product Response
 */
export type CollectionProductResponse = {
  metadata: {
    petsdeli: {
      uid: string;
      /** Sales number in last 7 days */
      salesCounter: number;
    };
    /** Metadata configured in Accentuate plugin on Shopify */
    accentuate: {
      ribbonText1: string;
      preorderText: string;
      /** @deprecated */
      hideCollections: Array<{
        index?: number;
        id: number;
      }>;
      subTitle: string;
      ribbonColor: string;
      /** Title for display purpose. */
      mainTitle: string;
    };
    /** Subscription config */
    subscription: {
      /** such as 2week,4week,6week,8week */
      intervals: string;
      enabled: boolean;
      variants: Array<{
        interval: '2week' | '4week' | '6week' | '8week';
        variantId: number;
        productId: number;
        originVariantId: number;
        price: number;
      }>;
    };
    rating: {
      count: string;
      score: string;
    };
  };
  handle: string;
  productType: string;
  productId: string;
  variants: Array<{
    availableForSale: boolean;
    image: {
      alt?: string;
      src: string;
    };
    weight: number;
    id: number;
    title: string;
    sku: string;
    priceV2: {
      currencyCode: string;
      amount: string;
    };
    weightUnit: 'KILOGRAMS' | 'GRAMS' | 'POUNDS' | 'OUNCES';
    compareAtPrice: number;
    points?: number;
  }>;
  _updatedAt: number;
  createdAt: string;
  /** Image of a product or a variant when a variant is assigned as product on collection dashboard */
  image: {
    alt?: string;
    src: string;
  };
  /** This property is assigned when a variant is assigned as product to promote a specific variant on dashboard */
  masterProductId?: number;
  availableForSale: boolean;
  /** Product Id. This property is assigned variantId when a variant is assigned as product to promote a specific variant on dashboard */
  id: number;
  tags: Array<string>;
  /** This title should NOT be used for display.
   * It is used as human readable "ID" for management purpose.
   * For display title, refer to metadata.accentuate.mainTitle
   * */
  title: string;
  /** Property for DB */
  _updated: number;
  /** Property for DB : Surrogate Key, reflection of id */
  sk: string;
  /** Property for DB : Prime Key */
  pk: string;
  /** Prooduct segmentation*/
  segmentation?: ProductSegmentation;
};

/**
 * Legacy type to define obsolete properties.
 */
type CollectionApiResponse = {
  handle: string;
  /** Property for DB : Surrogate Key */
  sk: string;
  /** Property for DB : Prime Key */
  pk: string;
  products: Array<CollectionProductResponse>;
  title: string;
  missing: Array<any>;
  published: boolean;
  exportedAt: number;
  /** Property for DB */
  _updatedAt: number;
};

export type CollectionResponse = {
  handle: string;
  products: Array<CollectionProductResponse>;
  title: string;
};

export function isLegacyCollection(
  response: NonNullable<any>
): response is CollectionResponse {
  return !!response && typeof response['handle'] !== 'undefined';
}

/**
 * @NOTE : CORS error occurs when requested from client side.
 * To fix the problem BE deployment is required.
 */
export const fetchCollectionProducts = async (
  collectionHandle: string,
  /** Omit 404 error toast if true */
  shouldOmitError?: boolean
): Promise<CollectionResponse> => {
  const url = `${process.env.API_ORIGIN_PRODUCT}/collection/${collectionHandle}`;
  const config = {
    withoutAudience: false,
    ...(shouldOmitError
      ? {
          errorHandling: {
            statusesToOmit: [HttpStatus.NOT_FOUND],
          },
        }
      : {}),
  };
  return await axios
    .get<CollectionResponse>(url, config)
    .then(({ data }) => data);
};

/**
 * Fetch Collection details including segmentation data
 */
export const fetchCollectionWithSegmentation = async (
  collectionHandle: string,
  /** Omit 404 error toast if true */
  shouldOmitError?: boolean
): Promise<CollectionResponse> => {
  const url = `${process.env.API_ORIGIN_COLLECTION_SEGMENTATION}/${collectionHandle}`;
  const config = {
    withoutAudience: false,
    ...(shouldOmitError
      ? {
          errorHandling: {
            statusesToOmit: [HttpStatus.NOT_FOUND],
          },
        }
      : {}),
  };
  return await axios
    .get<CollectionApiResponse>(url, config)
    .then(({ data }) => data);
};

export type FetchProductDetailsResponse = StaticPdp | null;

/**
 * Fetch ProductDetails from productHandle and without audience header.
 * When according details doesn't exist, returns null;
 * @param productHandle
 * @param withoutAudience
 * @returns productDetails
 */
export const fetchProductDetails = async (
  productHandle: string,
  withoutAudience?: boolean,
  /** Omit 404 error toast if true */
  shouldOmitError?: boolean
): Promise<FetchProductDetailsResponse> => {
  const url = `${process.env.API_ORIGIN_PRODUCT}/detail/${productHandle}`;

  const config = {
    withoutAuthentication: false,
    withoutAudience,
    ...(shouldOmitError
      ? {
          errorHandling: {
            statusesToOmit: [HttpStatus.NOT_FOUND],
          },
        }
      : {}),
  };
  try {
    return await axios.get<StaticPdp>(url, config).then(({ data }) => data);
  } catch (error) {
    if (error.response && [403, 404].includes(error.response.status)) {
      return null;
    }
    throw error;
  }
};

export type NewProductSegmentation = Omit<
  PersonalizedProductDetailsResponse,
  'variants' | 'couponDefinition'
>;

export function isNewProductSegmentation(
  input: ProductSegmentation | NewProductSegmentation
): input is NewProductSegmentation {
  return input && typeof input['bestseller'] === 'boolean';
}

// this is temporal implementation. Should be done on back-end
const MAX_RECOMMENDATIONS = 4;
export const getPetType = (product: ProductType) => {
  const isForDogs = product.tags!.includes('Für Hunde');
  return isForDogs ? 'dog' : 'cat';
};
type ProductType = Pick<Product, 'id' | 'tags' | 'productType' | 'title'>;
export const fetchProductRecommendations = async (product: ProductType) => {
  const petType = getPetType(product);

  const collectionHandle = getCollectionHandleByProductDetails(
    petType,
    product.productType
  );
  const collectionProducts = await fetchCollectionProducts(collectionHandle)
    .then(({ products }) => products)
    .catch(() => []);

  const recommendationProducts = [...(collectionProducts || [])].slice(
    0,
    MAX_RECOMMENDATIONS
  );

  return recommendationProducts;
};

type JudgeMeGlobalOverview = {
  rating?: number;
  count?: number;
};

export const fetchJudgeMeGlobalOverview =
  async (): Promise<JudgeMeGlobalOverview | null> => {
    try {
      const url = '/api/judge-me/global-overview';
      return await axios.post(url).then(({ data }) => data);
    } catch (error) {
      if (
        error.response &&
        [403, 404, 500, 400].includes(error.response.status)
      ) {
        return null;
      }
      throw error;
    }
  };

export interface EstimatedTimeOfArrival {
  [key: string]: {
    variantId: string;
    eta: string;
    shopId: string;
  };
}

/**
 * Fetch Estimated Time Of Arrival (ETA) on Variant level
 */
export const fetchEstimatedTimeOfArrival = async (
  variantsId: number[],
  shouldOmitError?: boolean
): Promise<EstimatedTimeOfArrival | null> => {
  try {
    const config = {
      withoutAuthentication: false,
      ...(shouldOmitError
        ? {
            errorHandling: {
              statusesToOmit: [
                HttpStatus.NOT_FOUND,
                HttpStatus.TOO_MANY_REQUESTS,
              ],
            },
          }
        : {}),
    };
    const url = `${process.env.API_ORIGIN_OOS}/subscriptions-oos/get-variants-eta/`;
    const response = await axiosWithoutRequestInterceptor
      .post(url, { variantsId }, config)
      .then(({ data }) => data);
    return response;
  } catch (error) {
    if (
      error.response &&
      [403, 404, 500, 400].includes(error.response.status)
    ) {
      return null;
    }
    throw error;
  }
};
