import { PetProfile } from 'contexts/pet-profile';
import { omitNullableHandler } from 'utils/type-helper';

const keysToOmit: (keyof PetProfile)[] = [
  'selectedFoodTypes',
  'selectedAllergies',
  'lastTouched',
];

/**
 * Convert segmentation store to array string for Ninetailed Trails or GA
 * Currently specific allergies and foodtype are omitted for business descion
 */
export default function convertProfileToArray({
  petProfile,
}: {
  petProfile: PetProfile;
}): Array<string> {
  return Object.entries(petProfile)
    .map(([key, value]) => {
      if (!keysToOmit.includes(key as keyof PetProfile)) {
        return value;
      }
    })
    .flat()
    .filter(omitNullableHandler)
    .sort();
}
