export const InputProductType = {
  /** Nassfutter */
  WetFood: 'wet_food',
  /** Trockenfutter */
  DryFood: 'dry_food',
  /** Frische-Menüs / barf */
  Barf: 'barf',
  /** Snacks */
  Snacks: 'snacks',
  /** Nahrungsergänzung */
  FoodSupplement: 'food_supplement',
  /** Zubehör */
  Accessories: 'accessories',
  /** Geschenk */
  Gift: 'gift',
  /** Pflege */
  Care: 'care_product',
  /** Testpaket */
  TestPackage: 'test_package',
  /** Med */
  Med: 'med',
} as const;

export type InputProductType =
  (typeof InputProductType)[keyof typeof InputProductType];

const foodTypeSet = new Set([Object.values(InputProductType)].flat());

/**
 * Assert function for NutritionSegmentationIds
 */
export function isFoodType(input: any): input is InputProductType {
  return foodTypeSet.has(input as unknown as any);
}
