import { BreedSegmentationIds } from 'constants/segmentation';

export const BREED_IMAGE_MAP = {
  [BreedSegmentationIds.Bulldog]: '/images/breed/bulldog.jpg',
  [BreedSegmentationIds.Chihuahua]: '/images/breed/chihuahua.jpg',
  [BreedSegmentationIds.Havanese]: '/images/breed/havanese.jpg',
  [BreedSegmentationIds.Poodle]: '/images/breed/poodle.jpg',
  [BreedSegmentationIds.Retriever]: '/images/breed/retriever.jpg',
  [BreedSegmentationIds.ShepherdDog]: '/images/breed/shepherd-dog.jpg',
  [BreedSegmentationIds.Spitz]: '/images/breed/spitz.jpg',
  [BreedSegmentationIds.Terrier]: '/images/breed/terrier.jpg',
  [BreedSegmentationIds.Dachshund]: '/images/breed/dachshund.jpg',
  [BreedSegmentationIds.Herdingdog]: '/images/breed/herdingdog.jpg',
  [BreedSegmentationIds.MixedSmall]: '/images/breed/mixed-small.jpg',
  [BreedSegmentationIds.MixedLarge]: '/images/breed/mixed-big.jpg',
};
