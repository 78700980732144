import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type BaseEntryId = string;

export type ExperimentCache = {
  [key in BaseEntryId]?: {
    variant: number;
    testData?: unknown;
    /** `${experienceId}:${exVariant}` */
    experienceId: string;
  };
};

interface AbTestCacheStoreInterface {
  abTestCacheStore: ExperimentCache;
  setAbTestCacheStore: (
    value: (arg: ExperimentCache) => ExperimentCache
  ) => void;
}

export const useAbTestCacheStore = create<AbTestCacheStoreInterface>()(
  persist(
    (set) => ({
      abTestCacheStore: {},
      setAbTestCacheStore: (updater) =>
        set((state) => ({ abTestCacheStore: updater(state.abTestCacheStore) })),
    }),
    {
      name: 'pd:ab-test-cache',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
