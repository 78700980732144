import { isAllergySegmentation, SegmentationIds } from 'constants/segmentation';
import { PetProfile } from 'contexts/pet-profile';
import { SegmentationStore } from 'contexts/segmentation';
import useDetectChanges from 'hooks/common/use-detect-changes';
import { useEffect, useRef } from 'react';
import compareArrays from 'utils/compare-array';

type Input = {
  petProfile: PetProfile;
  pushSegmentation: (arg: { segmentation: string | SegmentationStore }) => void;
  multipleSegmentation: SegmentationStore | undefined;
};

// use 'keyof' so that type check can detect update in property name
const SELECTED_BREED_KEY: keyof PetProfile = 'selectedBreed';
const SELECTED_AGE_KEY: keyof PetProfile = 'selectedAge';

/**
 * Hooks to sync segmentation with changes in pet profile
 */
const useSyncSegmentation = ({
  petProfile,
  pushSegmentation,
  multipleSegmentation,
}: Input) => {
  const changes = useDetectChanges(petProfile);
  const changesRef = useRef('');

  useEffect(() => {
    // Quick and dirty way to compare object by JSON, not optimal.
    // use try catch to detect undefined or circular references exists in object
    try {
      const currentJson = JSON.stringify(changes);
      if (changes && currentJson !== changesRef.current) {
        changesRef.current = JSON.stringify(changes);
        let partial: SegmentationStore = {
          // we need to check existing key instead of value, since value could be undefined
          ...(SELECTED_AGE_KEY in changes
            ? { ageSegmentation: changes.selectedAge }
            : {}),
          ...(SELECTED_BREED_KEY in changes
            ? { breedSegmentation: changes.selectedBreed }
            : {}),
        };

        if (changes.selectedNeeds) {
          const storedNeeds = multipleSegmentation?.needSegmentation || [];
          const { added, removed } = compareArrays(
            storedNeeds,
            changes.selectedNeeds
          );
          partial = {
            ...partial,
            needSegmentation: [...storedNeeds, ...added].filter(
              (seg) => !removed.includes(seg)
            ),
          };
        }
        if (changes.selectedAllergies) {
          const storedAllergies =
            multipleSegmentation?.allergySegmentation || [];
          const { added, removed } = compareArrays(
            storedAllergies,
            changes.selectedAllergies
          );
          partial = {
            ...partial,
            allergySegmentation: [...storedAllergies, ...added].filter(
              (seg) => !removed.includes(seg)
            ),
          };
        }

        pushSegmentation({
          segmentation: {
            ...multipleSegmentation,
            ...partial,
            ...(!isAllergySegmentation(changes.lastTouched as SegmentationIds)
              ? {
                  lastSegmentation:
                    changes.lastTouched ||
                    multipleSegmentation?.lastSegmentation,
                }
              : {}),
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    changes,
    multipleSegmentation,
    multipleSegmentation?.needSegmentation,
    pushSegmentation,
  ]);
};

export default useSyncSegmentation;
