import CheckCircle from 'assets/icons/check-circle.svg';
import cn from 'classnames';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import { AnimatePresence, m } from 'framer-motion';
import useIsClient from 'hooks/common/use-is-client';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import useMobile from 'hooks/common/use-mobile';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'react-use';
import HeartUsp from '../../../assets/icons/heart-usp.svg';
import Meat from '../../../assets/icons/meat.svg';
import Paw from '../../../assets/icons/paw-icon.svg';
import ThumbsUp from '../../../assets/icons/thumbs-up.svg';
import Truck from '../../../assets/icons/truck.svg';

interface UspType {
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  textId: string;
  iconStyle?: string;
}

const TEXT_PARENT_ID = 'header:usp:';

const DEFAULT_ANIMATION_DURATION = 10000;
const TEST_ANIMATION_DURATION = 3000;

const DEFAULT_USP_DATA: Array<UspType> = [
  {
    icon: Meat,
    textId: `${TEXT_PARENT_ID}no-grains`,
    iconStyle: 'h-6 lg:h-7 mb-1',
  },
  {
    icon: ThumbsUp,
    textId: `${TEXT_PARENT_ID}ratings`,
    iconStyle: 'h-4 lg:h-5 mb-2',
  },
  {
    icon: HeartUsp,
    textId: `${TEXT_PARENT_ID}donations`,
    iconStyle: 'h-6 mb-1',
  },
];

const getUspData = (newCustomer: boolean | undefined): Array<UspType> => {
  switch (newCustomer) {
    case false:
      return [
        {
          icon: Truck,
          // Versandkostenfreie Lieferung ab 60€
          textId: `${TEXT_PARENT_ID}free-shipping:EC`,
          iconStyle: 'h-5 mb-1',
        },
        ...DEFAULT_USP_DATA,
      ];

    case true:
      return [
        {
          icon: Truck,
          // BOLD :  Versandkostenfrei für Neukunden
          textId: `${TEXT_PARENT_ID}free-shipping:NC`,
          iconStyle: 'h-5 mb-1',
        },
        ...DEFAULT_USP_DATA,
      ];

    default:
      return [
        {
          icon: Truck,
          textId: `${TEXT_PARENT_ID}free-shipping`,
          iconStyle: 'h-5 mb-1',
        },
        ...DEFAULT_USP_DATA,
      ];
  }
};

interface IUspBar {
  testVariant: number;
  variant: 'single' | 'embedded-top';
}

const UspBar: React.ComponentType<IUspBar> = ({ testVariant, variant }) => {
  const { isEc } = useIsExistingCustomer();
  const { pathname, query } = useRouter();
  const isCart = useMemo(() => pathname === '/cart', [pathname]);
  const isMobile = useMobile();
  const isCDP = useMemo(() => pathname === '/collections/[...all]', [pathname]);
  // test-cdp-qa -  add for testing pourpose
  const isFoodguideCDP = useMemo(() => {
    if (query?.all) {
      return query?.all[0] === 'test-cdp-qa' || query?.all[0] === 'futterguide';
    } else {
      return false;
    }
  }, [query]);

  const { isClient } = useIsClient();

  const uspData = useMemo(() => {
    if (isClient && testVariant === 1) {
      return [
        {
          icon: CheckCircle,
          textId: `${TEXT_PARENT_ID}test:text-1`,
          iconStyle: 'h-5 w-5 text-green-300',
        },
        {
          icon: CheckCircle,
          textId: `${TEXT_PARENT_ID}test:text-2`,
          iconStyle: 'h-5 w-5 text-green-300',
        },
        {
          icon: CheckCircle,
          textId: `${TEXT_PARENT_ID}test:text-3`,
          iconStyle: 'h-5 w-5 text-green-300',
        },
        {
          icon: CheckCircle,
          textId: `${TEXT_PARENT_ID}test:text-4`,
          iconStyle: 'h-5 w-5 text-green-300',
        },
      ];
    }

    if (isClient && isCDP && isMobile && !isEc) {
      return [
        {
          icon: Truck,
          // BOLD :  Versandkostenfrei für Neukunden
          textId: `${TEXT_PARENT_ID}free-shipping:NC`,
          iconStyle: 'h-5 mb-1',
        },
      ];
    }

    if (isClient && isCart && isMobile) {
      return [
        {
          icon: Paw,
          textId: 'header:usp:good-choice',
          iconStyle: 'h-5 mb-1 -rotate-[22.5deg]',
        },
      ];
    }
    return getUspData(isClient && !isEc);
  }, [isClient, testVariant, isCDP, isMobile, isEc, isCart]);

  const [visibleIdx, setVisibleIdx] = useState<number>(0);

  // Set up timer to animate transition.
  const animationDuration =
    testVariant === 1 ? TEST_ANIMATION_DURATION : DEFAULT_ANIMATION_DURATION;

  useInterval(
    () => {
      setVisibleIdx((prev) => (prev >= uspData.length - 1 ? 0 : prev + 1));
    },
    testVariant === 1
      ? animationDuration
      : isCart && isMobile
        ? null
        : animationDuration
  );

  if (!uspData || !uspData.length || isFoodguideCDP) return null;

  return (
    <LazyMotionWrapper>
      <div className={cn(variant === 'single' && 'lg:hidden')}>
        <AnimatePresence>
          {uspData.length > 0 && (
            <div
              className={cn(
                'flex h-9 items-center overflow-x-hidden text-center text-xs lg:h-auto lg:text-sm',
                isCart
                  ? 'bg-primary-main text-other-white lg:bg-grey-100 lg:text-primary-main'
                  : 'bg-grey-100 text-primary-main'
              )}
            >
              {(uspData as UspType[]).map(
                ({ icon: Icon, textId, iconStyle }, idx) =>
                  idx === visibleIdx && (
                    <m.div
                      key={textId}
                      className={'m-auto'}
                      initial={{ x: 100, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -100, opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="flex items-center ">
                        {Icon && (
                          <div className="mr-2 flex items-center justify-center">
                            <Icon className={iconStyle} />
                          </div>
                        )}
                        <p>
                          <FormattedMessage
                            id={textId}
                            values={{ b: (chunks) => <b>{chunks}</b> }}
                          />
                        </p>
                      </div>
                    </m.div>
                  )
              )}
            </div>
          )}
        </AnimatePresence>
      </div>
    </LazyMotionWrapper>
  );
};

export default UspBar;
