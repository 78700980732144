import {
  AllergySegmentationIds,
  CatNutritionSegmentationIds,
  NutritionSegmentationIds,
} from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';
import { omitNullableHandler } from 'utils/type-helper';

const mergeArrays = (
  arrayA: string[] | undefined,
  arrayB: string[] | undefined
): string[] => {
  const combinedArray = [...(arrayA || []), ...(arrayB || [])];
  return combinedArray.sort();
};

/**
 * Merges two SegmentationStore objects into one.
 **/
export default function mergeSegmentationStores({
  target,
  source,
}: {
  target: SegmentationStore;
  source: SegmentationStore;
}): SegmentationStore {
  const mergedStore: SegmentationStore = {};

  // Merge breedSegmentation
  mergedStore.breedSegmentation =
    source.breedSegmentation || target.breedSegmentation;

  // Merge needSegmentation
  if (target.needSegmentation || source.needSegmentation) {
    mergedStore.needSegmentation = [
      ...mergeArrays(target.needSegmentation, source.needSegmentation).filter(
        omitNullableHandler
      ),
    ] as Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  }

  // Merge ageSegmentation (only one value allowed, take the one from storeB if it exists)
  mergedStore.ageSegmentation =
    source.ageSegmentation || target.ageSegmentation;

  // Merge allergySegmentation
  if (target.allergySegmentation || source.allergySegmentation) {
    mergedStore.allergySegmentation = [
      ...mergeArrays(target.allergySegmentation, source.allergySegmentation),
    ] as Array<AllergySegmentationIds>;
  }

  mergedStore.lastSegmentation =
    source.lastSegmentation || target.lastSegmentation;

  return mergedStore;
}
