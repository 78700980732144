import { SegmentationIds } from 'constants/segmentation';

export default {
  'cdp:general:price_from': 'ab',
  'cdp:general:product': 'Produkt',
  'cdp:general:products':
    '{amount, plural, =0 {# Produkte} one {# Produkt} other {# Produkte}}',
  'cdp:filter:showproducts': 'Produkte anzeigen',
  'cdp:filter:showproduct': 'Produkt anzeigen',
  'cdp:filter:close': 'Schliessen',
  'cdp:filter:foodtypes': 'Futtertypen',
  'cdp:filter:foodtypes:nassfutter': 'Nassfutter',
  'cdp:filter:foodtypes:trockenfutter': 'Trockenfutter',
  'cdp:filter:foodtypes:frische-menus': 'BARF-Menüs',
  'cdp:filter:foodtypes:nahrungserganzung': 'Nahrungsergänzung',
  'cdp:filter:foodtypes:snacks': 'Snacks',
  'cdp:filter:foodtypes:bundles': 'Vorteilspakete',

  'cdp:filter:species': 'Tierart',
  'cdp:filter:species:fur-hunde': 'Hunde',
  'cdp:filter:species:fur-katzen': 'Katzen',

  'cdp:filter:sizes': 'Tiergrösse',
  'cdp:filter:sizes:klein': 'Klein',
  'cdp:filter:sizes:mittel': 'Mittel',
  'cdp:filter:sizes:gross': 'Groß',

  'cdp:filter:sorts': 'Sorten',
  'cdp:filter:sorts:huhn': 'Huhn',
  'cdp:filter:sorts:rind': 'Rind',
  'cdp:filter:sorts:ente': 'Ente',
  'cdp:filter:sorts:pute': 'Pute',
  'cdp:filter:sorts:kalb': 'Kalb',
  'cdp:filter:sorts:wild': 'Wild',
  'cdp:filter:sorts:lachs': 'Lachs',
  'cdp:filter:sorts:pferd': 'Pferd',
  'cdp:filter:sorts:lamm': 'Lamm',
  'cdp:filter:sorts:insekten': 'Insekten',
  'cdp:filter:sorts:veggi': 'Veggi',
  'cdp:filter:sorts:vegan': 'Vegan',
  'cdp:filter:sorts:ziege': 'Ziege',
  'cdp:filter:sorts:thunfisch': 'Thunfisch',
  'cdp:filter:sorts:forelle': 'Forelle',
  'cdp:filter:sorts:kanguru': 'Känguru',
  'cdp:filter:sorts:kaninchen': 'Kaninchen',
  'cdp:filter:sorts:straus': 'Strauß',
  'cdp:filter:sorts:wasserbuffel': 'Wasserbüffel',
  'cdp:filter:sorts:krill': 'Krill',
  'cdp:filter:sorts:sprotten': 'Sprotten',
  'cdp:filter:sorts:hirsch': 'Hirsch',
  'cdp:filter:sorts:geflugel': 'Geflügel',
  'cdp:filter:sorts:truthahn': 'Truthahn',
  'cdp:filter:sorts:fisch': 'Fisch',
  'cdp:filter:sorts:schwein': 'Schwein',
  'cdp:filter:sorts:rind-and-kalb': 'Rind & Kalb',
  'cdp:filter:sorts:vegetarisch-vegan': 'Vegetarisch / Vegan',
  'cdp:filter:sorts:bowls-and-similars': 'Näpfe, Schleckmatte & Tränken',
  'cdp:filter:sorts:toys': 'Spielzeuge',
  'cdp:filter:sorts:walking': 'Gassi gehen',
  'cdp:filter:sorts:sleeping': 'Schlafplätze',
  'cdp:filter:sorts:other-accessories': 'Anderes Zubehör',

  'cdp:filter:desires': 'Bedürfnis',
  'cdp:filter:desires:getreidefrei': 'Getreidefrei',
  'cdp:filter:desires:glutenfrei': 'Glutenfrei',
  'cdp:filter:desires:hypoallergen': 'Hypoallergen',
  'cdp:filter:desires:leicht-verdaulich': 'Leicht verdaulich',
  'cdp:filter:desires:monoprotein': 'Monoprotein',
  'cdp:filter:desires:zuckerfrei': 'Zuckerfrei',
  'cdp:filter:desires:kalorienreduziert': 'Kalorienreduziert',
  'cdp:filter:desires:low-energy': 'Low Energy',
  'cdp:filter:desires:high-energy': 'High Energy',

  'cdp:filter:age': 'Alter',
  'cdp:filter:age:adult': 'Adult',
  'cdp:filter:age:senior': 'Senior',
  'cdp:filter:age:welpe': 'Welpe',
  'cdp:filter:age:kitten': 'Kitten',
  'cdp:filter:age:junior': 'Junior',
  'cdp:filter:age:adult-1-6-jahre': 'Adult (1-6 Jahre)',
  'cdp:filter:age:senior-7-jahre': 'Senior (7+ Jahre)',

  'cdp:filter:line': 'Linie',
  'cdp:filter:line:classic': 'Classic',
  'cdp:filter:line:sensitiv': 'Sensitiv',
  'cdp:filter:line:pure-meat': 'Pure Meat',
  'cdp:filter:line:extra-meat': 'Extra Meat',
  'cdp:filter:line:breed-fit': 'Breed Fit',
  'cdp:filter:line:rollen': 'Rollen',
  'cdp:filter:line:denta-snacks': 'Denta Snacks',
  'cdp:filter:line:filet-menu': 'Filet Menü',
  'cdp:filter:line:silkycream': 'Silky Cream',
  'cdp:filter:line:puremeat': 'Pure Meat',
  'cdp:filter:line:meatypate': 'Meaty Pâté',
  'cdp:filter:line:juicyfilets': 'Juicy Filets',
  'cdp:filter:line:green': 'Green',
  'cdp:filter:line:genieser-filets': 'Genießer Filets',
  'cdp:filter:line:superfood': 'Superfood',
  'cdp:filter:line:junior': 'Junior',
  'cdp:filter:line:hypocare': 'Hypocare',
  'cdp:filter:line:feinschmecker-pate': 'Feinschmecker Pate',
  'cdp:filter:line:kaltgepresst': 'Kaltgepresst',
  'cdp:filter:line:mini': 'Mini',
  'cdp:filter:line:limited': 'Limited',
  'cdp:filter:line:softies': 'Softies',
  'cdp:filter:line:happchen': 'Häppchen',
  'cdp:filter:line:pasteten': 'Pasteten',
  'cdp:filter:line:kausnacks': 'Kausnacks ',
  'cdp:filter:line:saucen-menu': 'Saucen Menü',
  'cdp:filter:line:akzeptanz': 'Akzeptanz',
  'cdp:filter:line:creme-snack': 'Creme Snack',
  'cdp:filter:line:soft-snacks': 'Soft Snacks ',
  'cdp:filter:line:filet-snack': 'Filet Snack ',
  'cdp:filter:line:suppe': 'Suppe',
  'cdp:filter:line:vet': 'VET',
  'cdp:filter:line:mousse': 'Mousse',
  'cdp:filter:line:tasties': 'Tasties',
  'cdp:filter:line:soft': 'Soft',
  'cdp:filter:line:cookies': 'Cookies',
  'cdp:filter:line:bundle': 'Probierpakete',
  'cdp:filter:line:knochen-and-kaurollen': 'Knochen & Kaurollen',
  'cdp:filter:line:trainingssnacks': 'Trainingssnacks',
  'cdp:filter:line:zahnpflege': 'Zahnpflege',
  'cdp:filter:line:pasten-and-cremesnacks': 'Pasten & Cremesnacks',
  'cdp:filter:line:hundekekse': 'Hundekekse',
  'cdp:filter:line:kaustreifen-and-sticks': 'Kaustreifen & Sticks',
  'cdp:filter:line:toppers': 'Toppers',

  'cdp:filter:funktion': 'Funktion',
  'cdp:filter:funktion:gelenk': 'Gelenk',
  'cdp:filter:funktion:haut-and-fell': 'Haut & Fell',
  'cdp:filter:funktion:zeckensaison': 'Zeckensaison',
  'cdp:filter:funktion:denta': 'Denta',
  'cdp:filter:funktion:entspannung': 'Entspannung',
  'cdp:filter:funktion:leber': 'Leber',
  'cdp:filter:funktion:verdauung': 'Verdauung',
  'cdp:filter:funktion:erkaltung': 'Erkältung',
  'cdp:filter:funktion:digestion': 'Verdauung & Schlingen',
  'cdp:filter:funktion:busy': 'Beschäftigung',
  'cdp:filter:funktion:smart-accessories': 'Intelligenzzubehör',

  'cdp:filter:sort-menu:label:default': 'Nach Relevanz',
  'cdp:filter:sort-menu:label:price-ascending': 'Preis, aufsteigend',
  'cdp:filter:sort-menu:label:price-descending': 'Preis, absteigend',
  'cdp:filter:sort-menu:label:new': 'Neuheiten',
  'cdp:filter:sort-menu:label:best-selling': 'Topseller',
  'cdp:filter:sort-menu:label:title': 'Artikelbezeichnung',
  'cdp:filter:sort-menu:aria-label-select': 'Produkte sortieren',

  'cdp:filter:nutritional-needs': 'Ernährungsbedürfnis',
  'cdp:filter:nutritional-needs:sehr-wahlerisch': 'Sehr wählerisch',
  'cdp:filter:nutritional-needs:pickyeatercat': 'Sehr wählerisch',
  'cdp:filter:nutritional-needs:digestioncat': 'Verdauungsprobleme',
  'cdp:filter:nutritional-needs:verdauungsprobleme': 'Verdauungsprobleme',
  'cdp:filter:nutritional-needs:unvertraglichkeiten-allergien':
    'Unverträglichkeiten / Allergien',
  'cdp:filter:nutritional-needs:haut-and-fellprobleme': 'Haut- & Fellprobleme ',
  'cdp:filter:nutritional-needs:ubergewicht': 'Übergewicht',
  'cdp:filter:nutritional-needs:overweightcat': 'Übergewicht',
  'cdp:filter:nutritional-needs:outdoorcat': 'Freigänger',
  'cdp:filter:nutritional-needs:sterilizedcat': 'Steriliziert',

  'cdp:filter:health-needs': 'Gesundheitsbedürfnis',
  'cdp:filter:health-needs:gelenk': 'Gelenk',
  'cdp:filter:health-needs:ungeziefer': 'Ungeziefer- und Zeckenschutz',
  'cdp:filter:health-needs:haut-and-fell': 'Haut & Fell',
  'cdp:filter:health-needs:zeckensaison': 'Zeckensaison',
  'cdp:filter:health-needs:denta': 'Denta',
  'cdp:filter:health-needs:entspannung': 'Entspannung',
  'cdp:filter:health-needs:leber': 'Leber',
  'cdp:filter:health-needs:verdauung': 'Verdauung',
  'cdp:filter:health-needs:erkaltung': 'Erkältung',

  'cdp:filter:feed-type': 'Futterart',
  'cdp:filter:feed-type:nassfutter': 'Nassfutter',
  'cdp:filter:feed-type:trockenfutter': 'Trockenfutter',
  'cdp:filter:feed-type:barf': 'BARF',
  'cdp:filter:feed-type:snacks': 'Snacks',

  'cdp:filter:snack-type': 'Snackart',
  'cdp:filter:snack-type:knochen-and-kaurollen': 'Knochen & Kaurollen',
  'cdp:filter:snack-type:trainingssnacks': 'Trainingssnacks',
  'cdp:filter:snack-type:zahnpflege': 'Zahnpflege',
  'cdp:filter:snack-type:pasten-and-cremesnacks': 'Pasten & Cremesnacks',
  'cdp:filter:snack-type:hundekekse': 'Hundekekse',
  'cdp:filter:snack-type:kaustreifen-and-sticks': 'Kaustreifen & Sticks',
  'cdp:filter:snack-type:creme-snack': 'Cremesnacks',
  'cdp:filter:snack-type:filet-snack': 'Filetsnack',
  'cdp:filter:snack-type:suppe': 'Suppen ',
  'cdp:filter:snack-type:soft-snacks': 'Softnacks',
  'cdp:filter:snack-type:sensitiv': 'Sensitivsnacks',
  'cdp:filter:snack-type:pasten': 'Pasten',
  'cdp:filter:snack-type:toppers': 'Toppers',
  'cdp:filter:snack-type:functional-snacks': 'Functional Snacks',

  'cdp:allergy-filter:header': 'Mein Hund verträgt kein...',
  'cdp:allergy-filter:without': 'Ohne',
  'cdp:allergy-filter:rind': 'Rind',
  'cdp:allergy-filter:getreidefrei': 'Getreide',
  'cdp:allergy-filter:huhn': 'Huhn',
  'cdp:allergy-filter:pute': 'Pute',
  'cdp:allergy-filter:ente': 'Ente',
  'cdp:allergy-filter:fisch': 'Fisch',
  'cdp:allergy-filter:kartoffel': 'Kartoffel',
  'cdp:allergy-filter:lamm': 'Lamm',
  'cdp:allergy-filter:pferd': 'Pferd',
  'cdp:allergy-filter:ziege': 'Ziege',
  'cdp:allergy-filter:suskartoffel': 'Süßkartoffel',

  'cdp:productcard:back-soon': 'Ausverkauft',

  'cdp:filter:label': 'Filter',
  'cdp:noproductsfound': 'Keine Produkte gefunden.',
  'cdp:similarproducts': 'Weitere Produkte',
  'cdp:resetfilters': 'Filter zurücksetzen',
  'cdp:reset': 'Zurücksetzen',
  'cdp:toproductbutton': 'Zum Produkt',
  'cdp:showmoreproducts': 'Alle Produkte anzeigen',

  'cdp:configureproduct': 'In den Warenkorb',
  'cdp:sizes': 'Grösse',
  'cdp:packages': 'Einheit',

  'cdp:addtocart': 'In den Warenkorb',
  'cdp:buy-panel:preorder': 'Jetzt vorbestellen',
  'cdp:intervals': 'Intervall',

  'cdp:availableas': 'Erhältlich in',
  'cdp:petsdelifordogs': 'Pets Deli für Hunde',
  'cdp:petsdeliforcats': 'Pets Deli für Katzen',
  'cdp:singlepurchase': 'Einzelkauf',
  'cdp:regulardelivery': 'Regelmäßige Lieferung',
  'cdp:notavailable': 'Derzeit nicht verfügbar',

  'cdp:subscription-intervals:2-weeks': 'Alle 2 Wochen',
  'cdp:subscription-intervals:4-weeks': 'Alle 4 Wochen',
  'cdp:subscription-intervals:6-weeks': 'Alle 6 Wochen',
  'cdp:subscription-intervals:8-weeks': 'Alle 8 Wochen',

  'cdp:findoutmore': 'Jetzt entdecken',

  // Following translations has beend added for an A/B test
  // see https://petsdeli.atlassian.net/browse/PT-3690
  'cdp:filter:special-desires': 'Spezielle Bedürfnisse',
  'cdp:filter:taste': 'Geschmack',
  'cdp:filter:special-desires:fur-welpen': 'Für Welpen & Junghunde',
  'cdp:filter:special-desires:fur-senioren': 'Für Senioren',
  'cdp:filter:special-desires:getreide-glutenfrei':
    'Getreide- & glutenfreie Rezeptur',
  'cdp:filter:special-desires:ohne-kartoffeln': 'Ohne Kartoffeln',
  'cdp:filter:special-desires:fur-allergiker': 'Für Allergiker',
  'cdp:filter:special-desires:sensible-verdauung': 'Sensible Verdauung',
  'cdp:filter:special-desires:weniger-kalorien': 'Weniger Kalorien',
  'cdp:filter:special-desires:fur-aktive-hunde': 'Für aktive Hunde',
  'cdp:filter:special-desires:fur-kleine-rassen': 'Für kleine Rassen',
  'cdp:filter:special-desires:spezielle-erkrankungen': 'Spezielle Erkrankungen',

  'cdp:filter:taste:geflugel': 'Geflügel',
  'cdp:filter:taste:rind-kalb': 'Rind & Kalb',
  'cdp:filter:taste:wild': 'Wild',
  'cdp:filter:taste:fisch': 'Fisch',
  'cdp:filter:taste:lamm': 'Lamm',
  'cdp:filter:taste:pferd': 'Pferd',
  'cdp:filter:taste:ziege': 'Ziege',
  'cdp:filter:taste:kanguru': 'Känguru',
  'cdp:filter:taste:insekten': 'Insekten',
  'cdp:filter:taste:vegetarisch-vegan': 'Vegetarisch / Vegan',

  'cdp:nutrition-needs-guidance:description':
    'Individuelle <strong>Bedürfnisse</strong>. Individuelle <strong>Rezepturen</strong>. Wir finden das perfekte Futter für deinen Hund!',
  'cdp:nutrition-needs-guidance:dropwdown:bundle':
    'Wähle das Bedürfnis deines Hundes',
  'cdp:nutrition-needs-guidance:dropwdown:pickyeater':
    'Mein Hund ist sehr wählerisch',
  'cdp:nutrition-needs-guidance:dropwdown:intolerance':
    'Mein Hund hat Unverträglichkeiten',
  'cdp:nutrition-needs-guidance:dropwdown:digestion':
    'Mein Hund hat Verdauungsprobleme',
  'cdp:nutrition-needs-guidance:dropwdown:welpe': 'Mein Hund ist ein Welpe',
  'cdp:nutrition-needs-guidance:dropwdown:foodaid': 'Ich möchte beraten werden',
  'cdp:nutrition-needs-guidance:dropwdown:no-help-needed':
    'Ich brauche keine Hilfe',

  'cdp:toast:add-single-item':
    'Gute Wahl! Dein Probierpaket wurde dem Warenkorb hinzugefügt.',

  'cdp:standalone-cdp:headline':
    'Entdecke unsere <RibbonText>Vielfalt</RibbonText>',
  'cdp:standalone-cdp:subheadline':
    'Individuelle Bedürfnisse. Individuelle Rezepturen.',
  'cdp:standalone-cdp:subheadline:segment': 'Mehr Auswahl',
  'cdp:standalone-cdp:load-more': 'Mehr Produkte laden',
  [`cdp:standalone-cdp:headline:${SegmentationIds.DogAdult}`]:
    'Für für <RibbonText> erwachsene Hunde </RibbonText>',
  'cdp:standalone-cdp:headline:intolerance':
    'Für Hunde mit <RibbonText>Unverträglichkeiten</RibbonText>',
  'cdp:standalone-cdp:headline:pickyeater':
    'Für <RibbonText>wählerische Hunde</RibbonText>',
  'cdp:standalone-cdp:headline:digestion':
    'Für Hunde mit <RibbonText>Verdauungsproblemen</RibbonText>',
  'cdp:standalone-cdp:headline:skin-fur':
    'Für Hunde mit <RibbonText>Haut- & Fellproblemen</RibbonText>',
  'cdp:standalone-cdp:headline:overweight':
    'Für Hunde mit <RibbonText>Übergewicht</RibbonText>',
  'cdp:standalone-cdp:headline:welpe': 'Für <RibbonText>Welpen</RibbonText>',
  'cdp:standalone-cdp:headline:senior': 'Für <RibbonText>Senioren</RibbonText>',
  'cdp:standalone-cdp:headline:spitz': 'Für <RibbonText>Spitze</RibbonText>',
  'cdp:standalone-cdp:headline:chihuahua':
    'Für <RibbonText>Chihuahuas</RibbonText>',
  'cdp:standalone-cdp:headline:shepherd-dog':
    'Für <RibbonText>Schäferhunde</RibbonText>',
  'cdp:standalone-cdp:headline:bulldog':
    'Für <RibbonText>Bulldoggen</RibbonText>',
  'cdp:standalone-cdp:headline:terrier': 'Für <RibbonText>Terrier</RibbonText>',
  'cdp:standalone-cdp:headline:poodle': 'Für <RibbonText>Pudel</RibbonText>',
  'cdp:standalone-cdp:headline:havanese':
    'Für <RibbonText>Havaneser & Malteser</RibbonText>',
  'cdp:standalone-cdp:headline:dachshund':
    'Für <RibbonText>Dackel</RibbonText>',
  'cdp:standalone-cdp:headline:herdingdog':
    'Für <RibbonText>Hütehunde</RibbonText>',
  'cdp:standalone-cdp:headline:retriever':
    'Für <RibbonText>Retriever</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.MixedLarge}`]:
    'Für <RibbonText>große Mischlinge</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.MixedSmall}`]:
    'Für <RibbonText>kleine Mischling</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.CatPickyEater}`]:
    'Für <RibbonText>wählerische Katzen</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.CatDigestion}`]:
    'Für Katzen mit <RibbonText>Verdauungsproblemen</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.CatOverweight}`]:
    'Für Katzen mit <RibbonText>Übergewicht</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.Sterilized}`]:
    'Für <RibbonText>sterilisierte Katzen</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.Outdoor}`]:
    'Für <RibbonText>Freigänger</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.Kitten}`]:
    'Für <RibbonText>Kitten</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.CatSenior}`]:
    'Für <RibbonText>Senioren</RibbonText>',
  [`cdp:standalone-cdp:headline:${SegmentationIds.Noneedcat}`]:
    'Entdecke unsere <RibbonText>Vielfalt</RibbonText>',

  'cdp:tile:badge:save': 'Save {amount}%',

  'cdp:pet-profile:selector:no-pet-profile:title-minimal-profile':
    'Hat dein Hund besondere Bedürfnisse?',
  'cdp:pet-profile:selector:no-pet-profile:title-extended-profile':
    'Sollen die Bedürfnisse deines Vierbeiners berücksichtigt werden?',
  'cdp:pet-profile:selector:pet-profile-available:title':
    'Möchtest du, dass wir deine anderen Bedfürnisse auch mit berücksichtigen?',
  'cdp:pet-profile:selector:pet-profile-available:title-general-cdp':
    'Auswahl an die Bedürfnisse meines Hundes anpassen?',
  'cdp:pet-profile:selector:no-pet-profile:description':
    'Wir sind für euch da! Erhalte eine individuelle Produktempfehlung für deinen Liebling.',
  'cdp:pet-profile:selector:cta-minimal-profile:generic-cdp':
    'Bedürfnisse hinzufügen',
  'cdp:pet-profile:selector:cta-minimal-profile:segment-cdp':
    'Weitere Bedürfnisse hinzufügen',
  'cdp:pet-profile:selector:cta-extended-profile': 'Ja',
};
