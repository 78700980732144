import { FormattedShopifyCheckModel } from 'interfaces/checkout';
import { Customer } from 'interfaces/customer';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pull from 'lodash/pull';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import {
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutFragmentFragment,
  CheckoutLineItemInput,
  CheckoutLineItemsReplaceMutation,
  CheckoutLineItemsReplaceMutationVariables,
} from 'shopifyTypes';
import {
  createShopifyCheckout,
  fetchShopifyCheckout,
  updateShopifyCheckoutItems,
} from '../../connectors/shopify-graphql';
import { ShopifyNamespace } from '../../interfaces/shopify';
import { customerLastIncompleteCheckoutStore } from '../../local-storage';
import * as CheckoutModel from '../../models/checkout';
import { customAttributesToShopifyFormat } from '../../models/shopify-common';
import { normalizeShopifyResponse, shopifyGid } from '../../utils/shopify';
import {
  areLineItemsEqual,
  createError,
  formatItems,
  ItemMergeKeys,
  mergeAttributes,
  mergeItems,
} from './utils';

type ShopifyCheckout = CheckoutFragmentFragment | null;

interface Props {
  id?: string;
  customer?: Customer;
}

type UpdateLineItemIF = {
  variantId: number;
  quantity: number;
  /** CustomAttributes to identify item  */
  targetCustomAttributes: CheckoutLineItemInput['customAttributes'];
  /** CustomAttributes variables to update  */
  updateCustomAttributes?: CheckoutLineItemInput['customAttributes'];
  /** Key to specify on which customAttributes to merge items */
  itemMergeKey: ItemMergeKeys;
};
export interface CheckoutHookProps {
  checkout: FormattedShopifyCheckModel;
  loading: boolean;
  isCartUpdating: boolean;
  addItem: (
    variantId: number | string,
    quantity: number,
    customAttributes?: CheckoutLineItemInput['customAttributes']
  ) =>
    | ReturnType<typeof updateShopifyCheckoutItems>
    | ReturnType<typeof createShopifyCheckout>;
  addItems: (
    items: Array<LineItem>
  ) =>
    | ReturnType<typeof updateShopifyCheckoutItems>
    | ReturnType<typeof createShopifyCheckout>;
  updateLineItem: (
    variantId: number,
    quantity: CheckoutLineItemInput['quantity'],
    interval?: string,
    customAttributes?: CheckoutLineItemInput['customAttributes']
  ) => void;
  refetchCheckout: () => void;
  resetCheckout: () => void;
  newUpdateLineItems: (arg: {
    items: Array<UpdateLineItemIF>;
  }) => Promise<CheckoutLineItemsReplaceMutation>;
  newUpdateLineItem: (
    arg: UpdateLineItemIF
  ) => Promise<CheckoutLineItemsReplaceMutation>;
}

export type LineItem = Pick<CheckoutLineItemInput, 'customAttributes'> & {
  variantId: number | string;
  quantity?: CheckoutLineItemInput['quantity'];
};

type Output = Array<{
  variantId: string;
  quantity: number;
  customAttributes: Array<{
    key: string;
    value: string;
  }>;
}>;

const reduceItems: (prev: Output, current: UpdateLineItemIF) => Output = (
  prev,
  {
    variantId,
    targetCustomAttributes,
    quantity,
    updateCustomAttributes,
    itemMergeKey,
  }
) => {
  const shopifyVariantId = shopifyGid(
    ShopifyNamespace.ProductVariant,
    variantId
  );

  const targetLineItem = prev
    // filter by Shopify Id & attributes
    .filter(
      (lineItem) =>
        lineItem.variantId === shopifyVariantId &&
        isEqual(lineItem.customAttributes, targetCustomAttributes)
    );

  // At this point, we should know which item to update.
  // If this error is thrown, the chances are implementation is wrong
  if (targetLineItem.length !== 1) {
    console.log({
      targetLineItem,
    });
    throw new Error('Item is not identified');
  }

  // Update item's quantity and customAttributes
  const updatedTargetLineItem = targetLineItem.map((lineItem) => ({
    ...lineItem,
    quantity,
    customAttributes: mergeAttributes(
      lineItem.customAttributes,
      updateCustomAttributes
    ),
  }));

  const targetAtr = updateCustomAttributes?.find(
    (atr) => atr.key === itemMergeKey
  );

  // find items to merged with updated item.
  // e.g onetime purchase is changed into 2 weeks and already 2weeks subs are already in the cart
  const lineItemToBeMerged = prev.filter((lineItem) => {
    return (
      lineItem.variantId === shopifyVariantId &&
      lineItem.customAttributes.find(
        (atr) => atr.key === itemMergeKey && atr.value === targetAtr?.value
      )
    );
  });
  // merge with new value
  const mergeValue = [...lineItemToBeMerged, ...updatedTargetLineItem].reduce(
    (pre, cur) => {
      return {
        ...pre,
        variantId: cur.variantId,
        quantity: pre.quantity + cur.quantity,
        customAttributes: mergeAttributes(
          pre.customAttributes,
          cur.customAttributes
        ),
      };
    },
    {
      variantId: '',
      quantity: 0,
      customAttributes: [],
    }
  );

  // remove items that has been merged.
  const pulledLineItemsPayload = pull(
    [...prev],
    ...[...lineItemToBeMerged, ...targetLineItem]
  );
  pulledLineItemsPayload.push(mergeValue);

  // If quantity is zero, we need to let Shopify know we don't need it
  const lineItemsPayloadRemoved = pulledLineItemsPayload.filter(
    (item) => item.quantity > 0
  );

  return lineItemsPayloadRemoved;
};

const EMPTY_CHECKOUT: FormattedShopifyCheckModel = {
  id: '',
  items: [],
  totalItemsQuantity: 0,
};

export const useCheckout = ({ id, customer }: Props): CheckoutHookProps => {
  const [isCartUpdating, setIsCartUpdating] = useState(false);
  const [checkout, setCheckout] = useState(EMPTY_CHECKOUT);
  const [originalGraphqlCheckout, setOriginalGraphqlCheckout] =
    useState<ShopifyCheckout>(null);

  const lineItemsPayload = useMemo(() => {
    // deconstruct to keep original order
    const _items = [...checkout.items];
    // Sorts cart items based on the order they are added to the cart using customAttributes
    _items.sort((a, b) => {
      return (
        Number(b.customAttributes['sort']) - Number(a.customAttributes['sort'])
      );
    });
    return _items.map((item) => ({
      /** Shopify identifer */
      variantId: item.variant.gid,
      quantity: item.quantity,
      /** customAttributes in Shopify format  */
      customAttributes: customAttributesToShopifyFormat(item.customAttributes),
    }));
  }, [checkout.items]);

  const setNewCheckout = useCallback((data: ShopifyCheckout) => {
    if (!data) return;
    setOriginalGraphqlCheckout(data);
    const normalizedData = normalizeShopifyResponse(data);
    const checkout = CheckoutModel.fromShopifyFormat(normalizedData);
    setCheckout(checkout);
  }, []);

  // TODO : should be replace with useCallback or update react-use to propagate error to sentry.
  // PT-1915
  const [{ loading }, refetchCheckout] = useAsyncFn(async () => {
    if (!id) {
      setCheckout(EMPTY_CHECKOUT);
      return;
    }
    const data = await fetchShopifyCheckout({ id });
    if (!data || !data.node) {
      customerLastIncompleteCheckoutStore.remove();
      setCheckout(EMPTY_CHECKOUT);
      return;
    }
    if (data.node.__typename === 'Checkout') {
      setNewCheckout(data.node);
    }
  }, [id]);

  const resetCheckout = (): void => {
    setCheckout(EMPTY_CHECKOUT);
  };

  useEffect(() => {
    refetchCheckout();
  }, [id, refetchCheckout]);

  /** Creates a new checkout. */
  const createCheckoutMutation = useCallback(
    async (
      input: CheckoutCreateMutationVariables
    ): Promise<CheckoutCreateMutation> => {
      try {
        setIsCartUpdating(true);
        const data = await createShopifyCheckout(input);

        if (!data || !data.checkoutCreate) {
          console.log('error!', data);
          throw 'Unexpected';
        } else if (data.checkoutCreate?.checkoutUserErrors.length > 0) {
          // NOTE : we could provide more detailed message but for mvp this should be sufficient
          throw data.checkoutCreate?.checkoutUserErrors[0].code;
        }

        // Satisfy type assertion
        if (data.checkoutCreate.checkout?.__typename === 'Checkout') {
          setNewCheckout(data.checkoutCreate.checkout);
          customerLastIncompleteCheckoutStore.set(
            data.checkoutCreate.checkout.id
          );
        }

        return data;
      } catch (error) {
        customerLastIncompleteCheckoutStore.remove();
        setCheckout(EMPTY_CHECKOUT);
        if (!(error instanceof Error)) {
          throw createError({
            errorType: error,
            operationName: 'createCheckout',
            lineItems: input.input.lineItems,
          });
        }
        throw error;
      } finally {
        setIsCartUpdating(false);
      }
    },
    [setNewCheckout]
  );

  /** Sets a list of line items to a checkout. */
  const updateLineItemsMutation = useCallback(
    async (
      variables: CheckoutLineItemsReplaceMutationVariables,
      newItemIds: Array<string>
    ): Promise<CheckoutLineItemsReplaceMutation> => {
      const prevOriginalGraphqlCheckout = originalGraphqlCheckout;
      try {
        setIsCartUpdating(true);
        const data = await updateShopifyCheckoutItems(variables);

        if (!data || !data.checkoutLineItemsReplace) {
          console.error('shopify too many request error');
          throw 'Unexpected';
        }

        // Since Shopify endpoint doesn't detect invalid variant Id for update item mutation
        // We need to look up whether if all the new items are actually in the response.
        newItemIds.forEach((newItemId) => {
          const idx =
            data.checkoutLineItemsReplace?.checkout?.lineItems.edges.findIndex(
              (edge) =>
                edge.node.variant?.id && edge.node.variant.id === newItemId
            );
          if (idx === undefined || idx < 0) {
            console.error('INVALID', {
              data,
              newItemIds,
            });
            throw 'INVALID';
          }
        });

        // Satisfy type assertion
        if (
          data.checkoutLineItemsReplace?.checkout?.__typename === 'Checkout'
        ) {
          setNewCheckout(data.checkoutLineItemsReplace?.checkout);
        }

        return data;
      } catch (error) {
        console.log({ error });
        setNewCheckout(prevOriginalGraphqlCheckout);
        if (!(error instanceof Error)) {
          throw createError({
            errorType: error,
            operationName: 'createCheckout',
            lineItems: variables.lineItems as CheckoutLineItemInput[],
          });
        }
        throw error;
      } finally {
        setIsCartUpdating(false);
      }
    },
    [originalGraphqlCheckout, setNewCheckout]
  );

  const newUpdateLineItems: CheckoutHookProps['newUpdateLineItems'] =
    useCallback(
      async ({ items }) => {
        const merged = items.reduce(reduceItems, lineItemsPayload);

        return await updateLineItemsMutation(
          {
            checkoutId: checkout.id,
            lineItems: merged,
          },
          []
        );
      },
      [checkout.id, lineItemsPayload, updateLineItemsMutation]
    );

  const newUpdateLineItem: CheckoutHookProps['newUpdateLineItem'] = useCallback(
    async ({
      variantId,
      quantity,
      updateCustomAttributes,
      targetCustomAttributes,
      itemMergeKey,
    }) => {
      const merged = [
        {
          variantId,
          quantity,
          updateCustomAttributes,
          targetCustomAttributes,
          itemMergeKey,
        },
      ].reduce(reduceItems, lineItemsPayload);

      return await updateLineItemsMutation(
        {
          checkoutId: checkout.id,
          lineItems: merged,
        },
        []
      );
    },
    [checkout.id, lineItemsPayload, updateLineItemsMutation]
  );

  /**
   * @TODO : Simplify method; too many args are exposed and we should expose multiple single-responsible methods.
   * such as updateQuantity, updateInterval, toggleSubscription and so on.
   * @deprecated : use newUpdateLineItem
   * */
  const updateLineItem: CheckoutHookProps['updateLineItem'] = useCallback(
    (variantId, quantity, interval, customAttributes) => {
      const shopifyVariantId = shopifyGid(
        ShopifyNamespace.ProductVariant,
        variantId
      );
      const lineItemIndex = lineItemsPayload.findIndex((lineItem) => {
        const lineItemInterval = get(lineItem, 'customAttributes', []).find(
          ({ key }) => key === 'interval'
        );
        // workaround for redeemItem feature pt-3524
        const lineItemReddem = get(lineItem, 'customAttributes', []).find(
          ({ key }) => key === 'redeemItem'
        );
        return (
          lineItem.variantId === shopifyVariantId &&
          !lineItemReddem &&
          (!interval ||
            (lineItemInterval && lineItemInterval.value === interval))
        );
      });

      updateLineItemsMutation(
        {
          checkoutId: checkout.id,
          lineItems: lineItemsPayload
            .map((item, index) => ({
              ...item,
              quantity: index === lineItemIndex ? quantity : item.quantity,
              customAttributes:
                // overwrite the value if the new value is assigned, e.g subscription interval
                index === lineItemIndex
                  ? mergeAttributes(item.customAttributes, customAttributes)
                  : item.customAttributes,
            }))
            .filter((lineItem) => lineItem.quantity > 0),
        },
        quantity ? [shopifyVariantId] : []
      );
    },
    [checkout.id, lineItemsPayload, updateLineItemsMutation]
  );

  const addItems: CheckoutHookProps['addItems'] = useCallback(
    (items) => {
      const formattedItems = mergeItems(formatItems(items));

      if (!checkout.id) {
        const lineItems = formattedItems;
        const email = customer ? customer.email : undefined;
        return createCheckoutMutation({ input: { lineItems, email } });
      }

      const updatedLineItems = lineItemsPayload.map((lineItem) => {
        const item = formattedItems.find((item) =>
          areLineItemsEqual(item, lineItem)
        );
        if (item) {
          return {
            ...lineItem,
            quantity: lineItem.quantity + item.quantity,
          };
        }
        return lineItem;
      });

      const newLineItems = formattedItems.filter((item) => {
        return updatedLineItems.every(
          (lineItem) => !areLineItemsEqual(item, lineItem)
        );
      });

      return updateLineItemsMutation(
        {
          lineItems: [...updatedLineItems, ...newLineItems],
          checkoutId: checkout.id,
        },
        newLineItems.map((v) => v.variantId)
      );
    },
    [
      checkout.id,
      customer,
      createCheckoutMutation,
      lineItemsPayload,
      updateLineItemsMutation,
    ]
  );

  const addItem = useCallback(
    (variantId: number | string, quantity: number, customAttributes: any) => {
      return addItems([{ variantId, customAttributes, quantity }]);
    },
    [addItems]
  );

  return {
    checkout,
    loading,
    isCartUpdating,
    addItem,
    addItems,
    refetchCheckout,
    updateLineItem,
    resetCheckout,
    newUpdateLineItem,
    newUpdateLineItems,
  };
};
