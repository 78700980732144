import { MINIMUM_INPUT_CDP } from 'constants/test/dynamic-ux-test';
import { useSegmentationContext } from 'contexts/segmentation';
import useExperiment, {
  Output as ExperimentOutput,
} from 'hooks/common/use-experiment';
import { useMemo } from 'react';
import convertSegmentationToArray from 'utils/segmentation/convert-segmentation-to-array';
import { DynamicTestScenario, isDynamicTestScenario } from './const';

export type Output = {
  loading: boolean;
  /** True if dynamic Ux should be shown */
  showDynamicUx: boolean;
  testScenario: DynamicTestScenario | undefined;
  /** True if fallback logics of collection for audience B should be applied  */
  shouldApplyFallBack: boolean;
} & ExperimentOutput;

/**
 * Hooks for Dynamic UX test
 * @see : https://petsdeli.atlassian.net/browse/PT-6331
 */
const useDynamicTest = (): Output => {
  const { multipleSegmentation } = useSegmentationContext();

  const { loading, variant, ...others } = useExperiment({
    baseEntryId: '1qZZRTMrh63LnVGXc2kX7T',
    shouldAddToHeader: true,
  });

  let testScenario: DynamicTestScenario | undefined;
  if (isDynamicTestScenario(variant)) {
    testScenario = variant;
  }

  const shouldApplyFallBack = useMemo(() => {
    if (
      typeof testScenario === 'undefined' ||
      testScenario === DynamicTestScenario.control ||
      testScenario === DynamicTestScenario.variantA
    ) {
      return false;
    }

    if (!multipleSegmentation) return true;

    const selectedSegment = convertSegmentationToArray({
      store: multipleSegmentation,
      shouldOmitLastSegment: true,
    });

    return selectedSegment.length < MINIMUM_INPUT_CDP;
  }, [multipleSegmentation, testScenario]);

  return {
    loading,
    showDynamicUx:
      testScenario !== undefined &&
      testScenario !== DynamicTestScenario.control &&
      testScenario !== DynamicTestScenario.variantA,
    testScenario,
    variant,
    shouldApplyFallBack,
    ...others,
  };
};

export default useDynamicTest;
