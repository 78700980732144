import Button from 'components/button-new';
import ImpressionTracker from 'components/impression-tracker';
import Sheet from 'components/sheet';
import { FormattedMessage } from 'react-intl';

type CouponModalPopUpProps = {
  discountHelpText: React.ReactNode;
  discountUsps: Array<React.ReactNode>;
  discountValue: React.ReactNode;
  impressionTrackingConfig?: {
    trackingGroupName: string;
    trackingLabel: string;
  };
  isOpen: boolean;
  titleText: React.ReactNode;
  onRequestClose: () => void;
};

/**
 * Generic component to show discount or coupon
 */
const GenericDiscountModalPopUp: React.FC<CouponModalPopUpProps> = ({
  discountHelpText,
  discountUsps,
  discountValue,
  impressionTrackingConfig,
  isOpen,
  titleText,
  onRequestClose,
}) => {
  const child = (
    <div className="px-4 pb-6 lg:px-6">
      <div className="text-center text-sm text-text-secondary">{titleText}</div>
      <div className="my-6 bg-grey-50 py-6">
        <p className="text-center text-2xl font-black">
          <span className="text-text-light-bg-discounted-price">{discountValue}</span>{' '}
          {discountHelpText}
        </p>
        {discountUsps && (
          <ul className="text-center text-sm text-text-secondary">
            {discountUsps.map((usp, index) => (
              <li key={index}>{usp}</li>
            ))}
          </ul>
        )}
      </div>
      <Button size="large" onClick={onRequestClose}>
        <FormattedMessage id="cart:coupon:successfully-applied-variant:close" />
      </Button>
    </div>
  );

  const wrapper = impressionTrackingConfig ? (
    <ImpressionTracker
      trackingGroupName={impressionTrackingConfig.trackingGroupName}
      trackingLabel={impressionTrackingConfig.trackingLabel}
    >
      {child}
    </ImpressionTracker>
  ) : (
    <>{child}</>
  );

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onRequestClose}
       
    >
      {wrapper}
    </Sheet>
  );
};

export default GenericDiscountModalPopUp;
