import cn from 'classnames';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import { m, useAnimation, useMotionValue } from 'framer-motion';
import useExperiment from 'hooks/common/use-experiment';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useHeaderStore } from 'stores/header';
import Nav from './nav';
import PromoBar from './promo-bar';
import HeaderTop from './top-header';
import UspSection from './usp-bar';

interface HeaderProps {
  disableScrollHide?: boolean;
}

/**
 * Header component. This component is used to render the header of the website.
 */
const Header: React.FC<HeaderProps> = ({ disableScrollHide = false }) => {
  // PT-6244: FE: ABC test: if removing the USP vs. updating it or keeping it the same has any effect on NC CVR
  // @see https://petsdeli.atlassian.net/browse/PT-6244
  const { variant, loading } = useExperiment({
    baseEntryId: '6osTIt51FwDYRkU0VfK4Kf',
  });
  const { isEc } = useIsExistingCustomer();

  const isUspBarHidden =
    loading ||
    variant === undefined ||
    variant === 2 ||
    (isEc && variant !== undefined);
  const lastPosition = useRef(0);
  const { pathname } = useRouter();

  const { setHeaderHidden } = useHeaderStore();

  const scrollY = useMotionValue(0);
  const controls = useAnimation();

  const onScroll = useCallback(() => {
    const previousY = lastPosition.current;
    lastPosition.current = window.scrollY;

    scrollY.set(window.scrollY);

    if (disableScrollHide) {
      controls
        .start({
          y: '0%',
          opacity: 1,
          transition: { type: 'spring', stiffness: 100, damping: 20 },
        })
        .then(() => setHeaderHidden(false));
    } else {
      if (window.scrollY === 0 || previousY > window.scrollY) {
        controls
          .start({
            y: '0%',
            opacity: 1,
            transition: { type: 'spring', stiffness: 100, damping: 20 },
          })
          .then(() => setHeaderHidden(false));
      } else {
        controls
          .start({
            y: '-200%',
            opacity: 0,
            transition: { type: 'spring', stiffness: 100, damping: 20 },
          })
          .then(() => setHeaderHidden(true));
      }
    }
  }, [controls, scrollY, disableScrollHide, setHeaderHidden]);

  useEffect(() => {
    if (disableScrollHide) return;
    const debounced = debounce(onScroll, 75);
    window.addEventListener('scroll', debounced);

    return () => {
      window.removeEventListener('scroll', debounced);
    };
  }, [disableScrollHide, onScroll]);

  useEffect(() => {
    const headerWrapper = document.getElementById('header');

    if (headerWrapper) {
      let animationFrameId: number | null = null;
      const resizeObserver = new ResizeObserver((entries) => {
        // "double rAF" which stands for "double requestAnimationFrame".
        // This technique ensures that the resize handler is not called more often than the frame rate of the browser.
        if (animationFrameId !== null) cancelAnimationFrame(animationFrameId);

        animationFrameId = requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            for (const entry of entries) {
              if (entry.target === headerWrapper) {
                document.documentElement.style.setProperty(
                  '--header-height',
                  `${entry.contentRect.height}px`
                );
              }
            }
          });
        });
      });

      resizeObserver.observe(headerWrapper);

      return () => {
        if (animationFrameId !== null) {
          cancelAnimationFrame(animationFrameId);
        }
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <LazyMotionWrapper>
      <div
        style={{ height: 'var(--header-height)' }}
        className={cn(
          'transition-all duration-100 ease-out',
          pathname === '/pages/jobs' && 'hidden'
        )}
      >
        <m.header
          animate={controls}
          className={cn(
            'fixed left-0 right-0 top-0 z-navigation bg-other-white shadow transition-transform duration-500 ease-out'
          )}
          id="header"
          data-test="header"
        >
          <PromoBar />
          <HeaderTop />
          <Nav />

          {isUspBarHidden ? null : (
            <UspSection testVariant={variant} variant="single" />
          )}
        </m.header>
      </div>
    </LazyMotionWrapper>
  );
};

export default Header;
