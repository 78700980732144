import { axios } from 'utils/axios';
import { isEmptyObject } from 'utils/type-helper';

export type CouponSettingResponse = {
  /** Comments for internal use  */
  campaignName: string;
  /** Comments for internal use  */
  comments: string;
  /** Valid from date in Unix time */
  validFrom: number;
  /** Valid to date in Unix time */
  validTo: number;
  /** Discount in percentage  */
  percentage: number;
  /** Discount in actual number from cart total   */
  absoluteValue: number;
  /** True if shipping is free  */
  freeShipping: boolean;
  /** Minimum order value for     */
  minimumBasketValue: number;
  /** Type of customer applicable to this coupon, all customer, new or existing */
  customerFidelity?: string;
  include: {
    productIds: Array<number>;
    variantIds: Array<number>;
    tags: Array<string>;
    customerTags: Array<string>;
    productTypes: Array<string>;
  };
  exclude: {
    productIds: Array<number>;
    variantIds: Array<number>;
    tags: Array<string>;
    customerTags: Array<string>;
    productTypes: Array<string>;
  };
  /** The limit to the number of application of the coupon */
  maxRedemptions: number;
  /** The limit to the number of application of the coupon per customer*/
  maxRedemptionsPerCustomer: number;
  /** True if it's applicable to subscription  */
  appliesToSubscriptions: boolean;
  countryCode?: string;
};

/**
 * Price Coupon Personalization in PDP
 * @see : https://petsdeli.atlassian.net/browse/PT-5429
 */
export interface DiscountCouponResponse {
  couponDefinition: CouponDefinition | null;
  discountedVariants: DiscountedVariant[];
}

export interface DiscountedVariant {
  /** Variant ID */
  variantId: number;
  /** Orginal price  w/o coupon */
  originalPrice: number;
  /** Subscription price  w/o coupon */
  originalSubscriptionPrice: number; // global sub discount is accounted for in this value
  /** Discount price  with coupon */
  discountedPrice: number | null;
  /** Subs discount price  with coupon */
  discountedSubscriptionPrice: number | null;
  /** Global subs discount */
  subscriptionDiscount: number | null;
  /** Compare price */
  compareAtPrice: number | null;
}

/**
 * Get coupon setting details
 * Returns undefined when coupon code is not defined
 * @TODO : API should return 404 error codes when coupon is not registered
 */
export const getCouponCodesSettings = (
  couponCode: string
): Promise<CouponSettingResponse | undefined> =>
  axios
    .get<CouponSettingResponse>(
      `${process.env.API_ORIGIN_COUPON}/detail/${couponCode}`
    )
    .then(({ data }) => (!isEmptyObject(data) ? data : undefined));

export interface CouponDefinition extends CouponSettingResponse {
  code: string;
  appliesToOneOff?: boolean;
}
