import { SmartCollectionItem, SmartCollectionResponse } from '../types';

/**
 * Type guard for SmartCollectionResponse
 */
export function isSmartCollectionResponse(
  input: unknown
): input is SmartCollectionResponse {
  return (
    input !== null &&
    typeof input === 'object' &&
    'items' in input &&
    Array.isArray(input.items)
  );
}

/**
 * Type guard for SmartCollectionResponse
 */
export function isSmartCollectionItem(
  input: unknown
): input is SmartCollectionItem {
  return (
    input !== null &&
    typeof input === 'object' &&
    'product_title' in input &&
    typeof input.product_title === 'string'
  );
}
