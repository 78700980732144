import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  isAgeSegmentation,
  isAllergySegmentation,
  isBreedSegmentation,
  isNutritionSegmentation,
  NutritionSegmentationIds,
  SegmentationIds,
} from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';

type Mode = 'add' | 'remove';

/**
 * Updates the SegmentationStore by adding or removing a segmentation value.
 *
 * This function allows you to either add a new segmentation to the existing SegmentationStore
 * or remove an existing segmentation from it. The segmentation arrays within the store are sorted alphabetically after each update.
 */
export default function updateSegmentationStore({
  existingStore,
  segmentation,
  mode = 'add',
}: {
  existingStore: SegmentationStore;
  segmentation: SegmentationIds;
  mode?: Mode;
}): SegmentationStore {
  const newVal = { ...existingStore };

  const modifyArray = (
    array: string[] | undefined,
    segment: string
  ): string[] => {
    if (!array) return mode === 'add' ? [segment] : [];

    const updatedArray =
      mode === 'add'
        ? [...array, segment]
        : array.filter((item) => item !== segment);

    return Array.from(new Set([...updatedArray])).sort();
  };

  if (isNutritionSegmentation(segmentation)) {
    newVal.needSegmentation = modifyArray(
      newVal.needSegmentation,
      segmentation
    ) as Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  } else if (isAllergySegmentation(segmentation)) {
    newVal.allergySegmentation = modifyArray(
      newVal.allergySegmentation,
      segmentation
    ) as Array<AllergySegmentationIds>;
  } else if (isAgeSegmentation(segmentation)) {
    newVal.ageSegmentation =
      mode === 'add' ? (segmentation as AgeSegmentationIds) : undefined;
  } else if (isBreedSegmentation(segmentation)) {
    newVal.breedSegmentation =
      mode === 'add' ? (segmentation as BreedSegmentationIds) : undefined;
  }

  if (!isAllergySegmentation(segmentation) && mode === 'add') {
    newVal.lastSegmentation = segmentation as BreedSegmentationIds;
  }

  return newVal;
}
