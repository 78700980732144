import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';

const NewsletterSectionAsync = dynamic(() => import('../newsletter-section'), {
  ssr: false,
});

/**
 * Newsletter section for the footer
 */
const Newsletter: React.FC = () => {
  return (
    <>
      <div className="-mx-4 bg-primary-main px-8 pb-6 pt-4 text-other-white lg:mx-0 lg:-mt-16 lg:w-1/2 lg:pt-0">
        <h5 className="text-center text-2xl text-brand-pink md:text-3xl lg:-mt-5 lg:text-left lg:text-5xl">
          <FormattedMessage id="footer:newsletter-main-title" />
        </h5>

        <p
          data-test="footer:newsletter-title"
          className="pb-4 font-medium leading-tight"
        >
          <FormattedMessage id="footer:newsletter-title" />
        </p>

        <NewsletterSectionAsync />
      </div>
    </>
  );
};

export default Newsletter;
