import cn from 'classnames';
import Link from 'next/link';
import React, { CSSProperties, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import IconAnimatedSpinner from '../../assets/icons/animated-spinner.svg';

export type Layout =
  | 'brand'
  | 'light'
  | 'orange'
  | 'white'
  | 'outline'
  | 'dog'
  | 'cat'
  | 'blank'
  | 'blackFriday'
  | 'selectedCTA';

export type ButtonProps = {
  children?: React.ReactNode;
  translationValues?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  layout?: Layout;
  size?: 'xs' | 'sm' | 'base' | 'md' | 'lg';
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  active?: boolean;
  target?: '_blank' | '_top' | '_self';
  href?: string;
  as?: string;
  style?: CSSProperties;
  loading?: boolean;
};

const LayoutClassNames: {
  [key in Layout]: { default: string; notDisabled: string; active: string };
} = {
  brand: {
    default:
      'bg-primary-main text-other-white active:bg-primary-main focus:bg-primary-main lg:hover:bg-primary-contained-hover-background',
    notDisabled: 'lg:hover:bg-opacity-90 lg:hover:bg-primary-main',
    active: 'bg-opacity-90 bg-primary-main',
  },
  orange: {
    default: 'bg-brand-cta text-other-white active:bg-opacity-90',
    notDisabled: 'lg:hover:bg-opacity-90',
    active: 'bg-brand-cta',
  },
  light: {
    default:
      'bg-brand-light border border-brand-light text-primary-main active:bg-brand-light',
    notDisabled:
      'lg:hover:bg-primary-main lg:hover:text-text-dark-bg-contrast-white',
    active: 'bg-brand-light',
  },
  white: {
    default:
      'bg-other-white !border border-grey-200 text-text-secondary active:bg-opacity-30',
    notDisabled: 'lg:hover:bg-grey-100 lg:hover:border-primary-main',
    active: 'bg-grey-200',
  },
  outline: {
    default:
      'bg-transparent text-primary-main !border border-primary-main border-px hover:border-grey-700',
    notDisabled: 'lg:hover:bg-other-white lg:hover:text-primary-main',
    active: '',
  },
  dog: {
    default: 'bg-brand-dogs text-other-white active:bg-opacity-90',
    notDisabled: 'lg:hover:bg-opacity-90',
    active: 'bg-brand-dogs',
  },
  cat: {
    default:
      'bg-main-colours-orange-cat-ligth text-other-white active:bg-opacity-90',
    notDisabled: 'lg:hover:bg-opacity-90',
    active: 'bg-main-colours-orange-cat-ligth',
  },
  blackFriday: {
    default:
      'bg-other-white !border border-grey-200 text-text-secondary active:bg-opacity-30 text-other-black',
    notDisabled: 'lg:hover:bg-grey-100 lg:hover:border-primary-main',
    active: 'bg-grey-200',
  },
  blank: {
    default: '',
    notDisabled: '',
    active: '',
  },
  selectedCTA: {
    default:
      'bg-other-white !text-new-brand-green !border border-px border-new-brand-green',
    notDisabled: '',
    active: '',
  },
};

const Button = forwardRef<any, ButtonProps>((props, ref) => {
  const intl = useIntl();
  const {
    as,
    type,
    disabled,
    children,
    layout,
    className,
    onClick,
    size,
    active,
    href,
    target = '_self',
    loading,
    style = {},
    translationValues,
    ...restProps
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const layoutClassNames = LayoutClassNames[layout!] || {};

  const buttonClassName = cn(
    'rounded relative focus-default uppercase tracking-wide font-black flex items-center justify-center leading-none border-0',
    className,
    layoutClassNames.default,
    !disabled && layoutClassNames.notDisabled,
    active && layoutClassNames.active,
    {
      'opacity-75': disabled,
      // Default Size
      'px-4 text-sm h-10': !size || size === 'base',
      'pt-1 px-4 text-sm h-8': size === 'sm',
      'py-1 px-3 text-xs h-8': size === 'xs',
      'text-base md:text-lg pb-3 pt-4 md:py-0 md:h-12 px-5 md:px-8':
        size === 'lg',
    }
  );

  const buttonChildren =
    typeof children === 'string'
      ? intl.formatMessage({ id: children }, translationValues)
      : children;

  // Return a link element when href is set
  if (href) {
    return (
      <Link as={as} href={href} passHref>
        <a
          ref={ref}
          onClick={onClick}
          className={buttonClassName}
          target={target}
          style={style}
          {...restProps}
        >
          <span className="block">{buttonChildren}</span>
        </a>
      </Link>
    );
  }

  return (
    <button
      ref={ref}
      onClick={onClick}
      type={type}
      className={buttonClassName}
      style={style}
      disabled={disabled}
      {...restProps}
    >
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <IconAnimatedSpinner className="h-auto w-5" />
        </div>
      )}
      <span className={cn('block w-full', loading && 'opacity-0')}>
        {buttonChildren}
      </span>
    </button>
  );
});

Button.defaultProps = {
  type: 'button',
  disabled: false,
  layout: 'brand',
  active: false,
};

export default Button;
