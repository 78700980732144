import { SegmentationIds } from 'constants/segmentation';

export default {
  'landingpage:foodlines-presenter:usps:no-animal-testing':
    'Ohne{br}Tierversuche',
  'landingpage:foodlines-presenter:usps:best-quality':
    'Beste{br}Rohstoffqualität',
  'landingpage:foodlines-presenter:usps:high-acceptance':
    'besonders hohe{br}Akzeptanz',
  'landingpage:foodlines-presenter:usps:no-grain':
    'ohne{br}Getreide &amp; Gluten',
  'landingpage:general:control-variant:discover':
    'Ich möchte die Ernährung verbessern für',
  'landingpage:general:control-variant:for-dogs': '<span>m</span>einen Hund',
  'landingpage:general:control-variant:for-cats': '<span>m</span>eine Katze',

  'landingpage:value-proposition:header':
    'Frisch & lecker, direkt in den Napf.',
  'landingpage:value-proposition:header-2':
    'Frische Zutaten. Bester Geschmack. Individuelle Auswahl.',
  'landingpage:value-proposition:sub-header':
    'Mit <strong>“Schmeckt nicht, gibt’s nicht.”</strong> -Garantie.',
  'landingpage:value-proposition:button': 'SPAR JETZT 15%',
  'landingpage:value-proposition:success':
    'Dein Neukunden-Code wird bei der Bezahlung automatisch angewendet',

  'landingpage:category-slider:title-NC': 'Stöber selbst',
  'landingpage:category-slider:title-EC': 'Unsere Futterkategorien',

  'landingpage:fube-blutte-point-1-dog': 'Beantworte 10 Fragen zu deinem Hund',
  'landingpage:fube-blutte-point-1-cat': 'Beantworte 10 Fragen zu deine Katze',
  'landingpage:fube-blutte-point-2':
    'Wir finden das perfekte Futter für deine Fellnase',

  'landingpage:collection-slider:title': ' Wir sind Problemlöser',
  'landingpage:segmentation-pulldown:sub-title': 'Wobei können wir helfen?',
  'landingpage:segmentation-pulldown:intolerance':
    'Mein Hund hat <b>Unverträglichkeiten</b>',
  'landingpage:segmentation-pulldown:pickyeater':
    'Mein Hund ist <b>sehr wählerisch</b>',
  'landingpage:segmentation-pulldown:digestion':
    'Mein Hund hat <b>Verdauungsprobleme</b>',
  'landingpage:segmentation-pulldown:skin-fur':
    'Mein Hund hat <b>Haut- & Fellprobleme</b>',
  'landingpage:segmentation-pulldown:welpe':
    'Mein Hund ist <b>ein Welpe (0-1 Jahre)</b>',
  'landingpage:segmentation-pulldown:senior':
    'Mein Hund ist <b>ein Senior (7+ Jahre)</b>',
  'landingpage:segmentation-pulldown:disease':
    'Mein Hund hat  eine <b>Erkrankung</b>',
  'landingpage:segmentation-pulldown:overweight':
    'Mein Hund hat <b>Übergewicht</b>',
  'landingpage:segmentation-pulldown:foodaid':
    'Nicht sicher? Jetzt beraten lassen',
  'landingpage:segmentation-pulldown:no-help-needed':
    '... hat keine besonderen Bedürfnisse',
  'landingpage:segmentation-pulldown:pickyeatercat':
    'Meine Katze ist <b>wählerisch</b>',
  'landingpage:segmentation-pulldown:digestioncat':
    'Meine Katze hat <b>Verdauungsprobleme</b>',
  'landingpage:segmentation-pulldown:overweightcat':
    'Meine Katze hat <b>Übergewicht</b>',
  'landingpage:segmentation-pulldown:sterilized':
    'Meine Katze ist <b>sterilisiert</b>',
  'landingpage:segmentation-pulldown:outdoor':
    'Mein Katze ist ein <b>Freigänger</b>',
  'landingpage:segmentation-pulldown:kitten':
    'Meine Katze ist ein <b>Kitten (0-1 Jahre)</b>',
  'landingpage:segmentation-pulldown:seniorcat':
    'Meine Katze ist ein <b>Senior (10+ Jahre)</b>',

  'landingpage:slider-selection-pickyeater': 'Mein Hund ist wählerisch',
  'landingpage:slider-selection-digestion': 'Mein Hund hat Verdauungsprobleme',
  'landingpage:slider-selection-intolerance':
    'Mein Hund hat Unverträglichkeiten',
  'landingpage:slider-selection-skin-fur':
    'Mein Hund hat Fell- oder Hautprobleme',
  'landingpage:slider-selection-overweight': 'Mein Hund hat Übergewicht',
  'landingpage:slider-selection-junior': 'Mein Hund ist ein Welpe (0-1 Jahre)',
  'landingpage:slider-selection-senior': 'Mein Hund ist ein Senior (7+ Jahre)',

  'landingpage:slider-selection:pickyeatercat': 'Meine Katze ist wählerisch',
  'landingpage:slider-selection:digestioncat':
    'Meine Katze hat Verdauungsprobleme',
  'landingpage:slider-selection:overweightcat': 'Meine Katze hat Übergewicht',
  'landingpage:slider-selection:sterilized': 'Meine Katze ist sterilisiert',
  'landingpage:slider-selection:outdoor': 'Mein Katze ist ein Freigänger',
  'landingpage:slider-selection:kitten':
    'Meine Katze ist ein Kitten (0-1 Jahre)',
  'landingpage:slider-selection:seniorcat':
    'Meine Katze ist ein Senior (10+ Jahre)',

  'landingpage:nutrition-needs-guidance:last-slide-redirect':
    'Mehr passende Produkte',
  'landingpage:influencer-slider:title':
    '#{name}s <RibbonText>Favoriten</RibbonText>',
  'landingpage:influencer-slider:cta-button': 'Weitere Produkte',

  'landingpage:special-and-limited-editions-slider:title': 'Unsere Highlights',
  'landingpage:last-slider-CTA-Snacks': 'Entdecke unsere Snacks',
  'landingpage:last-slider-CTA-Zubehör': 'Entdecke unser Zubehör',
  'landingpage:last-slider-CTA-Gesundheit': 'Gesundheit für Hunde',
  'landingpage:last-slider-CTA-Pflege': 'Entdecke unsere Pflegeprodukte',

  [`landingpage:breed-slider:breed:${SegmentationIds.Bulldog}`]: 'Bulldogge',
  [`landingpage:breed-slider:breed:${SegmentationIds.Chihuahua}`]: 'Chihuahua',
  [`landingpage:breed-slider:breed:${SegmentationIds.Dachshund}`]: 'Dackel',
  [`landingpage:breed-slider:breed:${SegmentationIds.Havanese}`]: 'Malteser',
  [`landingpage:breed-slider:breed:${SegmentationIds.Poodle}`]: 'Pudel',
  [`landingpage:breed-slider:breed:${SegmentationIds.Retriever}`]: 'Retriever',
  [`landingpage:breed-slider:breed:${SegmentationIds.ShepherdDog}`]:
    'Schäferhund',
  [`landingpage:breed-slider:breed:${SegmentationIds.Spitz}`]: 'Spitz',
  [`landingpage:breed-slider:breed:${SegmentationIds.Terrier}`]: 'Terrier',
  [`landingpage:breed-slider:breed:${SegmentationIds.Herdingdog}`]: 'Hütehund',
  [`landingpage:breed-slider:breed:${SegmentationIds.MixedSmall}`]:
    'Mischling - klein',
  [`landingpage:breed-slider:breed:${SegmentationIds.MixedLarge}`]:
    'Mischling - groß',

  'landingpage:problem-solver:description':
    'Das richtige Futter zu finden, kann ganz schön frustrierend sein. Schließlich ist jeder Hund einzigartig - Mit individuelle Probleme und Bedürfnisse! Wir haben uns zum Ziel gesetzt, für jedes Bedürfnis die passende Rezeptur zu entwickeln.',
  'landingpage:problem-solver-cat:description':
    'Das richtige Futter zu finden, kann ganz schön frustrierend sein.Schließlich ist jede Katze einzigartig - mit individuellen Problemen und Bedürfnissen! Wir haben uns zum Ziel gesetzt, für jedes Bedürfnis die passende Rezeptur zu entwickeln.',

  'landingpage:nutrition-usps:grains': 'Ohne Getreide{br}& Zucker',
  'landingpage:nutrition-usps:meat': 'Frisches{br}Fleisch',
  'landingpage:nutrition-usps:additives':
    'Frei von Aroma-,{br}Farb- & Füllstoffen',
  'landingpage:nutrition-usps:texture': 'Ansprechende{br}Konsistenz & Geruch',
  'landingpage:nutrition-usps:fruits': 'Frisches Obst{br}& Gemüse',
  'landingpage:nutrition-usps:gentle': 'Schonend{br}verarbeitet',

  'landingpage:problem-solver:sub-headline':
    'Natürlich unter den höchsten Pets Deli Qualitätsstandards:',
  'landingpage:problem-solver:cta': 'Mehr über Pets Deli erfahren',

  'landingpage:greeting': 'SCHÖN Dich Wieder Zu Sehen!',

  'landingpage:ad-tile:cta-button:pickyeater': 'Futter für wählerisch Esser',
  'landingpage:ad-tile:cta-button:intolerance':
    'Futter für Hunde mit Allergien',
  'landingpage:ad-tile:cta-button:skin-fur':
    'Futter zur Stärkung von Haut & Fell',
  'landingpage:ad-tile:cta-button:digestion':
    'Futter für Hunde mit sensibler Verdauung',
  'landingpage:ad-tile:cta-button:overweight': 'Futter für eine schlanke Linie',
  'landingpage:ad-tile:cta-button:senior': 'Futter für ältere Hunde',
  'landingpage:ad-tile:cta-button:welpe': 'Futter für Welpen',
  'landingpage:ad-tile:cta-button:bulldog': 'Futter für Bulldogen',
  'landingpage:ad-tile:cta-button:chihuahua': 'Futter für Chihuahuas',
  'landingpage:ad-tile:cta-button:dachshund': 'Futter für Dackel',
  'landingpage:ad-tile:cta-button:havanese': 'Futter für Malteser',
  'landingpage:ad-tile:cta-button:herdingdog': 'Futter für Hütehunde',
  'landingpage:ad-tile:cta-button:poodle': 'Futter für Pudel',
  'landingpage:ad-tile:cta-button:retriever': 'Futter für Retriever',
  'landingpage:ad-tile:cta-button:shepherd-dog': 'Futter für Schäferhunde',
  'landingpage:ad-tile:cta-button:spitz': 'Futter für Spitze',
  'landingpage:ad-tile:cta-button:terrier': 'Futter für Terrier',
  'landingpage:ad-tile:cta-button:pickyeatercat': 'Futter für wählerisch Esser',
  'landingpage:ad-tile:cta-button:seniorcat': 'Futter für ältere Katzen',
  'landingpage:ad-tile:cta-button:kitten': 'Futter für Kitten',
  'landingpage:ad-tile:cta-button:digestioncat':
    'Futter für Katzen mit sensibler Verdauung',
  'landingpage:ad-tile:cta-button:overweightcat':
    'Futter für eine schlanke Linie',
  'landingpage:ad-tile:cta-button:outdoor': 'Futter für Freigänger',
  'landingpage:ad-tile:cta-button:sterilized':
    'Futter für sterilisierte Katzen',

  'landingpage:ad-tile:cta-button:set-new-option':
    'Oder passe dein Bedürfnis an',

  'landingpage:bestseller-slider:title:dogs': 'Beliebt bei Doggos',
  'landingpage:bestseller-slider:title:cats': 'Beliebt bei Miezen',

  'landingpage:home:home:title': 'Gesund beginnt \nim Napf!',
  'landingpage:home:home:sub-title':
    'Für wen suchst du das <span>perfekte Futter?</span>',

  'landingpage:home:sub-headline': 'Wir finden das perfekte Futter für euch!',
  'landingpage:home:cta-button': 'Bedürfnis wählen...',
  'landingpage:home:breed-selector:headline': 'Oder wähle eine Rasse',

  'landingpage:home:judge-me-values': '{rating} ({count} Produktbewertungen)',

  'landingpage:home:slider:headline':
    'Individuelle Bedürfnisse. Individuelle Rezepturen.',

  'landingpage:home:fube-entry:headline':
    'Für die schwierigeren Fälle Digitaler futterberater',
  'landingpage:home:fube-entry:image-alt': 'Genau fûr mich',
  'landingpage:home:fube-entry:usp-1':
    'Hat schon <span>12.567</span> Kunden bei der Futtersuche geholfen',
  'landingpage:home:fube-entry:usp-2:dogs':
    'Beantworte 7 Fragen. Wir sagen was dein <span>Doggo</span> braucht.',
  'landingpage:home:fube-entry:usp-2:cats':
    'Beantworte 7 Fragen. Wir sagen dir was <span>deine Mieze</span> braucht',

  'landingpage:home:dog-cat:slider:headline':
    'Probierpakete mit Kennenlern Rabatt & gratis Futterguide',
  'landingpage:standalone-cdp:headline':
    'Entdecke unsere <RibbonText>Vielfalt</RibbonText>',
  'landingpage:standalone-cdp:ec-dog:sub-headline':
    'Einfach mal gesund probieren',
  'landingpage:standalone-cdp:nc-cat:sub-headline':
    'Einfach mal gesund probieren',
  'landingpage:standalone-cdp:nc-dog:sub-headline':
    'Einfach mal gesund probieren',
  'landingpage:standalone-cdp:home:sub-headline':
    'Individuelle Bedürfnisse. Individuelle Rezepturen.',

  'landingpage:need-capture:which-need-most-applied':
    'Welches Bedfürnis trifft am <b>EHESTEN</b> zu?',
  'landingpage:need-capture:which-need-most-applied-dog':
    'Verrate uns dazu, welches Bedürfnis am <b>EHESTEN</b> auf deinen Hund zutrifft <b>ODER</b> wähle eine Rasse:{br} <a>Wann wähle ich lieber ein Bedürfnis? Wann wähle ich lieber eine Rasse?</a>',
  'landingpage:need-capture:which-need-most-applied-cat':
    'Verrate uns dazu welches Bedürfnis am<b> EHESTEN </b> auf deine Katze zutrifft',
  'landingpage:need-capture:title': 'Lieblingsfutter.{br} genau für dich!',
  'landingpage:need-capture:sub-title':
    'Gesund beginnt im Napf. Genau nach diesem Motto helfen wir dir, das perfekte Futter für deinen Doggo zu finden!',
  'landingpage:need-capture:age:text': 'Mein Hund ist ein',
  'landingpage:need-capture:age:placeholder': 'Alter',
  'landingpage:need-capture:breed:placeholder': 'Rasse',
  'landingpage:need-capture:breed:text:mobile': 'und hat',
  'landingpage:need-capture:breed:text:desktop': 'und',
  'landingpage:need-capture:needs:placeholder': 'Folgendes Bedürfnis',
  'landingpage:need-capture:needs:text:desktop': 'hat',

  [`landingpage:need-capture:age:${SegmentationIds.Welpe}`]: 'Junior',
  [`landingpage:need-capture:age:${SegmentationIds.DogAdult}`]: 'Adult',
  [`landingpage:need-capture:age:${SegmentationIds.DogSenior}`]: 'Senior',
  [`landingpage:need-capture:breed:${SegmentationIds.Bulldog}`]: 'Bulldogge',
  [`landingpage:need-capture:breed:${SegmentationIds.Chihuahua}`]: 'Chihuahua',
  [`landingpage:need-capture:breed:${SegmentationIds.Dachshund}`]: 'Dackel',
  [`landingpage:need-capture:breed:${SegmentationIds.Havanese}`]: 'Havaneser',
  [`landingpage:need-capture:breed:${SegmentationIds.Poodle}`]: 'Pudel',
  [`landingpage:need-capture:breed:${SegmentationIds.Retriever}`]: 'Retriever',
  [`landingpage:need-capture:breed:${SegmentationIds.ShepherdDog}`]:
    'Schäferhund',
  [`landingpage:need-capture:breed:${SegmentationIds.Spitz}`]: 'Spitz',
  [`landingpage:need-capture:breed:${SegmentationIds.Terrier}`]: 'Terrier',
  [`landingpage:need-capture:breed:${SegmentationIds.Herdingdog}`]: 'Hütehund',
  [`landingpage:need-capture:breed:${SegmentationIds.MixedSmall}`]:
    'Mischling (klein)',
  [`landingpage:need-capture:breed:${SegmentationIds.MixedLarge}`]:
    'Mischling (groß)',
  'landingpage:need-capture:selector':
    'Bitte wähle bis zu 2 spezielle Bedürfnisse:',
  [`landingpage:need-capture:need:selector:option:${SegmentationIds.PickyEater}`]:
    'Wählerischer Esser',
  [`landingpage:need-capture:need:selector:option:${SegmentationIds.Digestion}`]:
    'Verdauungsprobleme',
  [`landingpage:need-capture:need:selector:option:${SegmentationIds.SkinFur}`]:
    'Haut- & Fellprobleme',
  [`landingpage:need-capture:need:selector:option:${SegmentationIds.Intolerance}`]:
    'Unverträglichkeiten',
  [`landingpage:need-capture:need:selector:option:${SegmentationIds.Overweight}`]:
    'Übergewicht',
  [`landingpage:need-capture:need:selector:cta:confirm`]: 'Auswahl bestätigen',
  [`landingpage:need-capture:need:option:${SegmentationIds.PickyEater}`]:
    'wählerisches Essverhalten',
  [`landingpage:need-capture:need:option:${SegmentationIds.Digestion}`]:
    'Verdauungsprobleme',
  [`landingpage:need-capture:need:option:${SegmentationIds.SkinFur}`]:
    'Haut- & Fellprobleme',
  [`landingpage:need-capture:need:option:${SegmentationIds.Intolerance}`]:
    'Unverträglichkeiten',
  [`landingpage:need-capture:need:option:${SegmentationIds.Overweight}`]:
    'Übergewicht',
  [`landingpage:need-capture:need:option:${SegmentationIds.Intolerance}`]:
    'Unverträglichkeiten',
  'landingpage:need-capture:products-list:title':
    '  Diese Bestseller sind genau passend für euch:',
  'landingpage:need-capture:products-list:cta': 'Alle Produkte anzeigen →',
  'landingpage:about-us-modal:headline':
    'Hi! Wir sind Pets Deli und machen Futter, das richtig was kann.',
  'landingpage:about-us-modal:section:1':
    '<b>Was das bedeutet: </b> Wir wissen, dass Futter individuell sein muss. Denn jeder Vierbeiner hat andere Bedürfnisse. Ob Welpe, Senior, Krankheiten, Allergien oder schlechter Esser - Futter von der Stange passt in den wenigsten Fällen. Denn bei herkömmlichem Futter wird meist wenig Wert auf hochwertige Zutaten oder die Zusammensetzung gelegt. Es besteht oft aus einem sehr großen Anteil Getreide oder anderen billigen Füllstoffen, einem sehr geringen Fleischanteil sowie Geschmacksverstärkern, Zucker und schwer verdaulichen Abfällen der Lebensmittelindustrie wie Krallen, Federn oder Hufen. Das klingt nicht nur ekelig, sondern ist auch ernährungsphysiologisch gesehen nicht das Beste, was du deinem Vierbeiner bieten kannst.',
  'landingpage:about-us-modal:section:2':
    '<b>Wir machen das anders: </b> Mit unseren Produkten gehen wir speziell auf die individuellen Ernährungsbedürfnisse deines Vierbeiners ein. Wir bieten maßgeschneiderte Menüs, die Dank ihrer ausgeklügelten Rezepturen dabei helfen, einen Großteil aller Futterprobleme zu lösen. Dafür investieren wir viel Zeit in die Produktentwicklung: Unsere Tierärztinnen arbeiten auf Basis der neuesten Ernährungswissenschaften und sorgen stets für eine artgerechte, hochwertige und geschmacksintensive Zusammensetzung.',
  'landingpage:about-us-modal:section:3:dogs':
    '<b>Unser Versprechen: </b> Besonderen Wert legen wir dabei auf die Qualität unserer Rohstoffe, eine schonende Verarbeitung und die Zusammensetzung unserer Produkte. Natürlich lassen wir auch Kundenfeedbacks in unsere Produktentwicklungen einfließen und erweitern unser Angebot stetig. Was dabei herauskommt? Eine breite Produktpalette für die verschiedensten Bedürfnisse: Köstliches Nassfutter, vollwertiges Trockenfutter, frisches BARF, fleischlose Alternativen sowie jede Menge funktionale, gesunde und leckere Snacks. Dabei sorgen frische Zutaten, wie echtes Fleisch, vitaminreiche Obst- & Gemüsesorten sowie wertvolle Öle und Mineralien für den erstklassigen Pets Deli Geschmack und unterstützen nicht nur die Gesundheit, sondern auch die Lebensqualität deines Doggos.',
  'landingpage:about-us-modal:section:3:cats':
    '<b>Unser Versprechen: </b> Besonderen Wert legen wir dabei auf die Qualität unserer Rohstoffe, eine schonende Verarbeitung und die Zusammensetzung unserer Produkte. Natürlich lassen wir auch Kundenfeedbacks in unsere Produktentwicklungen einfließen und erweitern unser Angebot stetig. Was dabei herauskommt? Eine breite Produktpalette für die verschiedensten Bedürfnisse: Köstliches Nassfutter, vollwertiges Trockenfutter sowie jede Menge funktionale, gesunde und leckere Snacks. Dabei sorgen frische Zutaten, wie echtes Fleisch, vitaminreiche Obst- & Gemüsesorten sowie wertvolle Öle und Mineralien für den erstklassigen Pets Deli Geschmack und unterstützen nicht nur die Gesundheit, sondern auch die Lebensqualität deiner Mieze.',

  'landingpage:home:fte-segmentation:dog:info-text':
    'Um das perfekte Futter zu finden, verrate uns, welches Bedürfnis {br} am ehesten auf deinen Hund zutrifft oder wähle die passende Rasse:',
  'landingpage:home:fte-segmentation:cat:info-text':
    '   Verrate uns dazu welches Bedürfnis am<b> EHESTEN </b> auf deine Katze zutrifft',
  'landingpage:home:need-capture:no-results': `<p><b>Kein passendes Produkt verfügbar</b></p>
        <p>Wir erweitern unser Sortiment ständig, können dir jedoch aktuell auf Grundlage deiner Auswahl leider keine Empfehlung anzeigen. Bitte
          reduziere die Anzahl der ausgewählten Filter, um Produkte angezeigt zubekommen.</p>
    `,
};
