import config from 'config';
import { SubscriptionInterval } from 'constants/order';
import { useCommon } from 'contexts/common';
import {
  CollectionProduct,
  CollectionVariant,
} from 'models/collection/collection';
import React from 'react';

interface PetsDeliProduct {
  product: CollectionProduct;
  selectedVariant: CollectionVariant;

  oneOffPrice: number;
  oneOffComparePrice: number | null;
  subscriptionPrice: number | null;
  subscriptionComparePrice: number | null;

  /**
   * This is a context aware price, if you pass the type in the second argument,
   * it will return the correct price, to make it easier in frontend in some places
   */
  price: number;
  comparePrice: number | null;
}

interface ProductOptions {
  selectedVariantId?: string | null;
  isSubscriptionSelected?: boolean;
  interval?: SubscriptionInterval;
}

/**
 * @TODO : After migration to PDEX stacks, logics of discount calculations will be obsolete
 */
const useProduct = (
  product: CollectionProduct,
  settings: ProductOptions = {}
): PetsDeliProduct => {
  const { globalDiscount } = useCommon();

  const options: ProductOptions = React.useMemo(
    () => ({
      isSubscriptionSelect: false,
      selectedVariant: null,
      interval: SubscriptionInterval.TWO_WEEKS,
      ...settings,
    }),
    [settings]
  );

  /**
   * selected variant
   */
  const selectedVariant = React.useMemo(() => {
    if (options.selectedVariantId) {
      return (
        product.variants.find((v) => v.id === options.selectedVariantId) ||
        product.getCheapestVariant()
      );
    }

    return product.getCheapestVariant();
  }, [options, product]);

  /**
   * Check if product is personalized
   */
  const personalizedDiscount = React.useMemo(() => {
    if (!globalDiscount.loading && globalDiscount.discount) {
      if (globalDiscount.discount.productTag) {
        if (
          // If tagType = include, return normal price if tag is not present
          (globalDiscount.discount.tagType === 'include' &&
            product.tags.indexOf(globalDiscount.discount.productTag) === -1) ||
          // if tagType = exclude, return normal price if tag is present
          (globalDiscount.discount.tagType === 'exclude' &&
            product.tags.indexOf(globalDiscount.discount.productTag) > -1)
        )
          return 0;
      }
      /**
       * global discount without product tag limitation
       */
      return globalDiscount.discount.amountInPercent;
    }

    return 0;
  }, [globalDiscount, product]);

  /**
   * One Off Pricing
   */
  const oneOffPrice = React.useMemo(() => {
    return (
      (parseFloat(selectedVariant.priceV2.amount) *
        (100 - personalizedDiscount)) /
      100
    );
  }, [selectedVariant, personalizedDiscount]);

  const oneOffComparePrice = React.useMemo(() => {
    // Price is personalized, so we either return the compare price or the normal product price, whichever may be higher
    if (personalizedDiscount > 0) {
      return selectedVariant.compareAtPrice &&
        selectedVariant.compareAtPrice / 100 >
          parseFloat(selectedVariant.priceV2.amount)
        ? selectedVariant.compareAtPrice / 100
        : parseFloat(selectedVariant.priceV2.amount);
    }

    // Probably due the fac that a product can be injected afterwards via a test,
    // we need to check if the compareAtPrice is a number or string to display the correct value.
    // TODO: Further investigation needed
    return selectedVariant.compareAtPrice
      ? typeof selectedVariant.compareAtPrice === 'number'
        ? selectedVariant.compareAtPrice / 100
        : parseFloat(selectedVariant.compareAtPrice)
      : null;
  }, [selectedVariant, personalizedDiscount]);

  const subscriptionPrice = React.useMemo(() => {
    if (product.tags.indexOf('SubscriptionEnabled') > -1) {
      /**
       * if product is persoanlized AND firstSubscriptionOrderDiscount is present, subtract it
       */
      if (
        personalizedDiscount > 0 &&
        globalDiscount.discount?.firstSubscriptionOrderDiscount
      ) {
        return (
          oneOffPrice *
          ((100 - globalDiscount.discount.firstSubscriptionOrderDiscount) / 100)
        );
      } else {
        return oneOffPrice * ((100 - config.subscriptionDiscount) / 100);
      }
    }

    return null;
  }, [personalizedDiscount, product, globalDiscount, oneOffPrice]);

  const subscriptionComparePrice = React.useMemo(() => {
    return oneOffComparePrice || oneOffPrice;
  }, [oneOffPrice, oneOffComparePrice]);

  const price = React.useMemo(() => {
    return options.isSubscriptionSelected && subscriptionPrice
      ? subscriptionPrice
      : oneOffPrice;
  }, [options, oneOffPrice, subscriptionPrice]);

  const comparePrice = React.useMemo(() => {
    return options.isSubscriptionSelected
      ? subscriptionComparePrice
      : oneOffComparePrice;
  }, [options, oneOffComparePrice, subscriptionComparePrice]);

  return {
    product,
    selectedVariant,

    oneOffPrice,
    oneOffComparePrice,

    subscriptionPrice,
    subscriptionComparePrice,

    price,
    comparePrice,
  };
};

export default useProduct;
