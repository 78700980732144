import { useProfile } from '@ninetailed/experience.js-next';
import { getAbTestById, getAllAbTestIds } from 'api/contentful-graphql';
import { GetAbTestByIdQuery } from 'generated/graphql';
import useSetToCookie, { CookieName } from 'hooks/common/use-set-to-cookie';
import React, { useEffect, useMemo, useState } from 'react';
import { useMap, useSessionStorage } from 'react-use';
import useSWR from 'swr';

interface Context {
  audiencesHeaderStr?: string;
  experimentHeaderStr: string;
  isLoading: boolean;
  deleteHeader: ({ experienceId }: { experienceId: string }) => void;
  getTestEntry: (id: string) => Promise<GetAbTestByIdQuery | undefined>;
  setToHeader: ({
    experienceId,
    variant,
  }: {
    experienceId: string;
    variant: number;
  }) => void;
}

/**
 * the idea is to have one global state for test variants
 * so that we only ever have to create the callback ONCE for each test
 */

const NinetailedAbTestContext = React.createContext({} as Context);

const NinetailedAbTestProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data: abTestIds, isValidating } = useSWR(
    'ninetailedAbIds',
    getAllAbTestIds,
    {
      revalidateOnFocus: false,
    }
  );

  const [, { set: setAbTestEntryMap, get: getAbTestEntryMap }] = useMap<{
    [key: string]: GetAbTestByIdQuery;
  }>({});

  /**
   * Local storage to hold the last experiment results
   */
  const [abTestResultStore, setAbTestResultStore] = useSessionStorage<{
    [id: string]: number;
  }>('pd:experimentStore', {});

  const [abTestResult, setAbTestResult] = useState<{
    [id: string]: number;
  }>(abTestResultStore || {});

  useEffect(() => {
    setAbTestResultStore(abTestResult);
  }, [abTestResult, setAbTestResultStore]);

  const getTestEntry = React.useCallback(
    async (id: string) => {
      if (!abTestIds) {
        return undefined;
      }
      const entry = getAbTestEntryMap(id);
      if (entry) {
        return entry;
      }
      if (!abTestIds.includes(id)) {
        return undefined;
      } else {
        const result = await getAbTestById({ id });
        setAbTestEntryMap(id, result);
        return result;
      }
    },
    [abTestIds, getAbTestEntryMap, setAbTestEntryMap]
  );

  /**
   * Set result to communicate with backend
   */
  const setToHeader: Context['setToHeader'] = React.useCallback(
    ({ experienceId, variant }) =>
      setAbTestResult((prev) => ({
        ...prev,
        [experienceId]: variant,
      })),
    [setAbTestResult]
  );
  /**
   * Delete result from header
   */
  const deleteHeader: Context['deleteHeader'] = React.useCallback(
    ({ experienceId }) => {
      setAbTestResult((prev) => {
        if (!prev) {
          return {};
        } else {
          return Object.fromEntries(
            Object.entries(prev).filter((entry) => {
              return entry[0] !== experienceId;
            })
          );
        }
      });
    },
    [setAbTestResult]
  );

  const experimentHeaderStr = useMemo(() => {
    if (!abTestResult) return '';
    return Object.keys(abTestResult)
      .map((key) => `${key}.${abTestResult[key]}`)
      .join(',');
  }, [abTestResult]);
  const { profile } = useProfile();

  const audiencesHeaderStr = useMemo(
    () => profile?.audiences.join(','),
    [profile?.audiences]
  );

  useSetToCookie({
    cookieId: CookieName.Audiences,
    cookieValue: audiencesHeaderStr,
  });

  useSetToCookie({
    cookieId: CookieName.Experiments,
    cookieValue: experimentHeaderStr,
  });

  return (
    <NinetailedAbTestContext.Provider
      value={{
        audiencesHeaderStr,
        experimentHeaderStr,
        isLoading: isValidating,
        deleteHeader,
        getTestEntry,
        setToHeader,
      }}
    >
      {children}
    </NinetailedAbTestContext.Provider>
  );
};

const useNinetailedAbTestContext = (): Context =>
  React.useContext(NinetailedAbTestContext);

export { NinetailedAbTestProvider, useNinetailedAbTestContext };
