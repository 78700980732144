export default {
  'pdp:buy-panel:free-shipping-existing-customer':
    '<div><strong>Versandkostenfrei</strong><p> ab CHF 100 Bestellwert</p></div>',
  'pdp:buy-panel:free-shipping-existing-customer:without-style':
    'Versandkostenfrei ab CHF 100 Bestellwert',
  'pdp:buy-panel:free-shipping-new-customer:test':
    'CHF 0 Versand - du sparst CHF 9.95',
  'pdp:buy-panel:coupon-price-personalization:mov-info':
    'Gutschein gilt für Bestellung ab CHF {mov} Bestellwert',
};
