export default {
  'pet-profile:header:title': '<p>Eine</p> <p>Persönliche</p> <p>Auswahl</p>',
  'pet-profile:display:not-chosen': 'Nicht gewählt',
  'pet-profile:display:breed-not-chosen': 'Rasse: Keine Angabe',
  'pet-profile:display:age-not-chosen': 'Alter: Keine Angabe',
  'pet-profile:display:needs-not-chosen': 'Bedürfnis: Keine Angabe',
  'pet-profile:display:food-type-not-chosen': 'Futtertyp: Keine Angabe',
  'pet-profile:display:your-pet': 'Für Deinen Hund',
  'pet-profile:display:edit': 'Bearbeiten',
  'pet-profile:display:edit-your-pet-profile':
    'Bearbeite dein persönliches Profil',
  'pet-profile:display:food-preference': 'Futtermittelpräferenz',
  'pet-profile:cdp:title':
    'Wau! Empfehlungen - abgestimmt auf eure Bedürfnisse',
};
