import { SegmentationIds } from 'constants/segmentation';
import { PetProfile } from 'contexts/pet-profile';
import { omitNullableHandler } from 'utils/type-helper';

const keysToOmit: (keyof PetProfile)[] = ['selectedAllergies', 'lastTouched'];

/**
 * Check if petProfile has enough properties for API
 */
export default function getPetProfileArray({
  petProfile,
}: {
  petProfile?: PetProfile;
}): Array<SegmentationIds> {
  if (!petProfile) {
    return [];
  }

  return Object.entries(petProfile)
    .map(([key, value]) => {
      if (!keysToOmit.includes(key as keyof PetProfile)) {
        return value;
      }
    })
    .filter((v) => typeof v !== 'boolean')
    .flat()
    .filter(omitNullableHandler);
}
