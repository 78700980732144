import { Session } from '../types';

type Int = number;
type Optional<T, Keys extends keyof T> = Omit<T, Keys> & Partial<Pick<T, Keys>>;

export type SimpleEvent<T> = {
  name: T;
};

export type Event<T, D> = {
  name: T;
  data: D;
};

export type Group<G, T> = T & {
  group: G;
};

interface NonInteractive {
  nonInteractive: true;
}

interface Wait {
  wait: true;
}

interface Product {
  productTitle: string;
  productId: string;
  productType: string;
  tags: string;
}

interface ListItem {
  list: string;
  position: Int;
}

interface Variant extends Product {
  variantTitle: string;
  variantId: string;
  sku: string;
}

export interface CheckoutItem extends Variant {
  quantity: Int;
  price: Int;
  taxRate: number;
  interval?: string;
}

export interface Marketing {
  marketing: boolean;
}

export interface Checkout {
  requestId: string;
  customer: {
    id: number;
    email: string;
    ordersCount: Int;
  };
  totalAmount: Int;
  totalVat: Int;
  items: Array<CheckoutItem>;
  itemsTotal: Int;
  discount?: {
    type: string;
    value: Int;
    code?: string;
  };
  shipping: {
    type: string;
    price: Int;
    vat: Int;
  };
  payment: PaymentChannel;
}

interface Customer {
  firstName: string;
  lastName: string;
}

export type State = { session: Partial<Session> };

export enum GroupName {
  Account = 'Account',
  Loyalty = 'Loyalty',
  Collection = 'Collection',
  Customer = 'Customer',
  Product = 'Product',
  Cart = 'Cart',
  CheckoutStandard = 'Checkout Standard',
  CheckoutExpress = 'Checkout Express',
  CheckoutWallet = 'Checkout Wallet',
  CheckoutQuestionnaire = 'Checkout Questionnaire',
  Shipping = 'Shipping',
  PromoBar = 'Promo Bar',
  PromoHeader = 'Promo Header',
  AdTile = 'Ad Tile',
  Debug = 'Debug',
  Form = 'Form',
  Consent = 'Consent',
  Story = 'Story',
  FuBe = 'FuBe',
  ConversationalWidget = 'Conversational Widget',
  Subscription = 'Subscription',
  /** Tracking group for generic tracking */
  Component = 'Component',
  /** Tracking group for weekly offer ad tile  */
  HomePromoTile = 'Home Promo Tile',
}

export enum ComponentEventName {
  Impression = 'Impression',
  Click = 'Click',
}

export enum CheckoutQuestionnaireEventName {
  View = 'View',
  Start = 'Start',
  End = 'End',
  Answer = 'Answer',
}

export enum LoyaltyEventName {
  RedeemOptionView = 'Redeem Option View',
  Redeem = 'Redeem',
}
export enum FuBeEventName {
  Question = 'Question',
  Answer = 'Answer',
}

export enum ProductEventName {
  Click = 'Click',
  AddToCart = 'Add To Cart',
  Details = 'Details',
  SwitchVariant = 'Switch Variant',
  Impressions = 'Impressions',
  InfoTabClick = 'InfoTabClick',
  ClickAdventCalendar = 'Click Advent Calendar',
}

export enum CustomerEventName {
  Authentication = 'Authentication',
}

export enum CollectionEventName {
  FilterOptionsOpen = 'Filter Options Open',
  FilterOptionsConfirm = 'Filter Options Confirm',
  FilterOptionsCancel = 'Filter Options Cancel',
  FilterOptionsClear = 'Filter Options Clear',
  FilterOptionsReset = 'Filter Options Reset',
  FilterOptionsClick = 'Filter Options Click',
  SortOptionsOpen = 'Sort Options Open',
  SortOptionsSelect = 'Sort Options Select',
}

export enum CartEventName {
  ReadyCart = 'Ready Cart',
  CheckoutOptionSelect = 'Select Checkout Method',
  OpenSubscriptionOptions = 'Open Subscription Options',
  SelectIntervalOption = 'Select Interval Option',
}

export enum CheckoutExpressEventName { //
  ReadySummary = 'Ready Summary',
  ReadyConfirmation = 'Ready Confirmation',
  SelectShippingOption = 'Select Shipping Method',
  SubmitOrder = 'Submit Order',
  Purchase = 'Purchase',
}

export enum CheckoutWalletEventName {
  SelectShippingOption = 'Select Shipping Method',
  SelectShippingAddress = 'Select Shipping Address',
  PaymentSuccess = 'Payment Success',
  OrderSuccess = 'Order Success',
}

export enum CheckoutExpressEventName { //
  Confirm = 'Confirm',
}

export enum CheckoutEventName {
  CheckoutLoaded = 'Checkout Loaded',
  ReadyUserRole = 'Ready User Role',
  SelectUserRole = 'Select User Role',
  SubmitUserRole = 'Submit User Role',
  UserIdentified = 'User Identified',
  ReadyAddresses = 'Ready Addresses',
  SubmitAddresses = 'Submit Addresses',
  ReadyShipping = 'Ready Shipping',
  SelectShippingOption = 'Select Shipping Method',
  SubmitShipping = 'Submit Shipping',
  ReadyPayment = 'Ready Payment',
  SelectPaymentOption = 'Select Payment Option',
  SubmitPayment = 'Submit Payment',
  Purchase = 'Purchase',
  ReadyConfirmation = 'Ready Confirmation',
}

export enum PromoBarEventName {
  SubmitCouponCode = 'Submit Coupon Code',
  Click = 'Click',
}

export enum CommonEventName {
  Click = 'Click',
  Impression = 'Impression',
  Submit = 'Submit',
  Ready = 'Ready',
  View = 'View',
  Success = 'Success',
}
export enum CheckoutType {
  Standard = 'standard',
  Express = 'express',
  Wallet = 'wallet',
}

export enum AccountEventName {
  Login = 'login',
  Logout = 'logout',
}

export enum PaymentGateway {
  Optile = 'optile',
  Klarna = 'klarna',
  Stripe = 'stripe',
}

export enum ConsentEventName {
  Ok = 'ok',
  Settings = 'einstellungen',
  AcceptAll = 'alle akzeptieren',
  ConfirmSelection = 'auswahl bestatigen',
}

export enum StoryEventName {
  Impressions = 'Impressions',
  Views = 'Views',
  Next = 'Next',
  Previous = 'Previous',
  Exit = 'Exit',
}

interface PaymentChannel {
  gateway: string;
  method: string;
}
/**
 * @TODO : this type is expecting at least one mandatory fields. Which should accept only partial too.
 */
type SessionPatch<
  Required extends keyof Session,
  Optional extends keyof Session = never,
> = {
  session: { [P in Required]: Session[P] } & Partial<Pick<Session, Optional>>;
};

export enum SubscriptionEventName {
  MoveOptionsView = 'Move Options View',
  MoveOptionsConfirm = 'Move Options Confirm',
  PauseOptionsView = 'Pause Options View',
  PauseOptionsPauseSelect = 'Pause Options Pause Select',
  PauseOptionsPauseConfirm = 'Pause Options Pause Confirm',
  PauseOptionsMoveConfirm = 'Pause Options Move Confirm',
  CancelOptionsView = 'Cancel Options View',
  CancelOptionsConfirm = 'Cancel Options Confirm',
  Restart = 'Restart',
  AddItem = 'Add Item',
  RemoveItem = 'Remove Item',
  RestoreItem = 'Restore Item',
  IncreaseItemQuantity = 'Increase Item Quantity',
  DecreaseItemQuantity = 'Decrease Item Quantity',
  SelectFilter = 'Select Filter',
  IntervalSelect = 'Interval Select',
  OrderTypeSelect = 'Order Type Select',
  AddToSubscriptionClick = 'AddToSubscriptionClick',
  UpsellListView = 'Upsell List View',
  UpsellListSelect = 'Upsell List Select',
  UpsellListClickItem = 'Upsell List Click Item',
  UpsellListModalView = 'Upsell List Modal View',
  UpsellListAddItem = 'Upsell List Add Item',
}

type SubscriptionEvents = Group<
  GroupName.Subscription,
  | SimpleEvent<
      | SubscriptionEventName.Restart
      | SubscriptionEventName.CancelOptionsView
      | SubscriptionEventName.MoveOptionsView
      | SubscriptionEventName.MoveOptionsConfirm
      | SubscriptionEventName.PauseOptionsView
      | SubscriptionEventName.PauseOptionsPauseSelect
      | SubscriptionEventName.PauseOptionsMoveConfirm
      | SubscriptionEventName.IncreaseItemQuantity
      | SubscriptionEventName.DecreaseItemQuantity
      | SubscriptionEventName.AddToSubscriptionClick
      | SubscriptionEventName.UpsellListModalView
    >
  | Event<
      | SubscriptionEventName.PauseOptionsPauseConfirm
      | SubscriptionEventName.SelectFilter
      | SubscriptionEventName.CancelOptionsConfirm
      | SubscriptionEventName.IntervalSelect
      | SubscriptionEventName.OrderTypeSelect
      | SubscriptionEventName.UpsellListView
      | SubscriptionEventName.UpsellListSelect
      | SubscriptionEventName.UpsellListClickItem
      | SubscriptionEventName.UpsellListAddItem,
      { label: string }
    >
  | Event<
      | SubscriptionEventName.AddItem
      | SubscriptionEventName.RemoveItem
      | SubscriptionEventName.RestoreItem,
      { item: { variantId: string } }
    >
>;

export type Events =
  | SubscriptionEvents
  | Group<
      GroupName.Collection,
      | (Event<
          CommonEventName.Ready,
          { products: Array<Product & ListItem & { variantId: string }> }
        > &
          NonInteractive)
      | SimpleEvent<
          | CollectionEventName.FilterOptionsOpen
          | CollectionEventName.FilterOptionsConfirm
          | CollectionEventName.FilterOptionsClear
          | CollectionEventName.FilterOptionsReset
          | CollectionEventName.SortOptionsOpen
          | CollectionEventName.FilterOptionsCancel
        >
      | Event<CollectionEventName.FilterOptionsClick, { label: string }>
      | Event<CollectionEventName.SortOptionsSelect, { label: string }>
    >
  | Group<GroupName.Form, Event<CommonEventName.Submit, { label: string }>>
  | Group<
      GroupName.Consent,
      | SimpleEvent<ConsentEventName.Ok>
      | SimpleEvent<ConsentEventName.Settings>
      | SimpleEvent<ConsentEventName.AcceptAll>
      | Event<ConsentEventName.ConfirmSelection, { label: string }>
    >
  | Group<
      GroupName.Story,
      | (Event<StoryEventName.Impressions, { label: string }> & NonInteractive)
      | Event<StoryEventName.Views, { label: string }>
      | Event<StoryEventName.Next, { label: string }>
      | Event<StoryEventName.Previous, { label: string }>
      | Event<StoryEventName.Exit, { label: string }>
    >
  | Group<
      GroupName.Account,
      | (Event<CommonEventName.Impression, { label: string }> & NonInteractive)
      | SimpleEvent<AccountEventName.Login>
      | SimpleEvent<AccountEventName.Logout>
    >
  | Group<
      GroupName.Product,
      | (Event<
          ProductEventName.Impressions,
          {
            products: Array<Product & ListItem>;
          }
        > &
          NonInteractive)
      | Event<ProductEventName.Click, { product: Product & ListItem }>
      | Event<ProductEventName.Click, { product: Product }>
      | (Event<
          ProductEventName.Details,
          { product: Product & ListItem & { variantId: string } }
        > &
          NonInteractive)
      | Event<
          ProductEventName.SwitchVariant,
          { product: Product & { variantId: string } }
        >
      | Event<
          ProductEventName.AddToCart,
          {
            list?: string;
            item: Omit<CheckoutItem, 'taxRate'>;
          }
        >
      | Event<
          ProductEventName.InfoTabClick,
          { product: Product & { tabName: string } }
        >
      | Event<ProductEventName.ClickAdventCalendar, { label: string }>
    >
  | Group<
      GroupName.Cart,
      | (Event<CartEventName.ReadyCart, { checkout: Pick<Checkout, 'items'> }> &
          NonInteractive)
      | SimpleEvent<CartEventName.OpenSubscriptionOptions>
      | Event<CartEventName.SelectIntervalOption, { type: string }>
      | Event<
          CartEventName.CheckoutOptionSelect,
          | {
              type: CheckoutType.Standard;
              checkout: Pick<Checkout, 'items'>;
            }
          | {
              type: CheckoutType.Express;
              payment: PaymentChannel;
              checkout: Pick<Checkout, 'items'>;
            }
          | {
              type: CheckoutType.Wallet;
              payment: PaymentChannel;
              checkout: Pick<Checkout, 'items'>;
            }
        >
    >
  | Group<
      GroupName.CheckoutStandard,
      | (Event<
          CheckoutEventName.CheckoutLoaded,
          {
            checkout: Optional<Checkout, 'shipping' | 'discount' | 'payment'>;
          }
        > &
          NonInteractive)
      | (SimpleEvent<CheckoutEventName.ReadyUserRole> & NonInteractive)
      | Event<
          CheckoutEventName.SelectUserRole | CheckoutEventName.SubmitUserRole,
          {
            type: 'signup' | 'login' | 'guest';
          }
        >
      | (Event<
          CheckoutEventName.UserIdentified,
          {
            type: 'signup' | 'login' | 'guest';
            checkout: Pick<Checkout, 'items'>;
          }
        > &
          SessionPatch<'customerEmail' | 'customerId' | 'customerOrdersCount'> &
          NonInteractive)
      | (SimpleEvent<CheckoutEventName.ReadyAddresses> & NonInteractive)
      | Event<
          CheckoutEventName.SubmitAddresses,
          {
            customer: Customer;
            checkout: Pick<Checkout, 'items'>;
          }
        >
      | (SimpleEvent<CheckoutEventName.ReadyShipping> & NonInteractive)
      | Event<
          | CheckoutEventName.SelectShippingOption
          | CheckoutEventName.SubmitShipping,
          {
            type: string;
            checkout: Pick<Checkout, 'items'>;
          }
        >
      | (SimpleEvent<CheckoutEventName.ReadyPayment> & NonInteractive)
      | Event<
          CheckoutEventName.SelectPaymentOption,
          {
            payment: PaymentChannel;
          }
        >
      | Event<
          CheckoutEventName.SubmitPayment,
          {
            payment: PaymentChannel;
            checkout: Pick<Checkout, 'items'>;
          }
        >
      | (Event<
          CheckoutEventName.ReadyConfirmation,
          {
            payment: PaymentChannel;
          }
        > &
          NonInteractive)
      | (Event<CheckoutEventName.Purchase, { checkout: Checkout } & Marketing> &
          SessionPatch<
            | 'customerOrdersCount'
            | 'customerSubscriptionStatus'
            | 'customerFirstName'
            | 'customerLastName'
            | 'customerCity'
            | 'customerStreet'
            | 'customerPostcode',
            'customerPhone'
          > &
          NonInteractive)
    >
  | Group<
      GroupName.CheckoutWallet,
      | SimpleEvent<CheckoutWalletEventName.SelectShippingAddress>
      | Event<
          CheckoutWalletEventName.SelectShippingOption,
          {
            type: string;
          }
        >
      | (SimpleEvent<CheckoutWalletEventName.PaymentSuccess> & NonInteractive)
      | (SimpleEvent<CheckoutWalletEventName.OrderSuccess> &
          SessionPatch<'customerEmail' | 'customerId' | 'customerOrdersCount'> &
          NonInteractive)
    >
  | Group<
      GroupName.CheckoutExpress,
      | (Event<
          CheckoutExpressEventName.ReadySummary,
          {
            customer: Customer;
            payment: PaymentChannel;
            checkout: Pick<Checkout, 'items'>;
          }
        > &
          NonInteractive &
          SessionPatch<'customerEmail' | 'customerId' | 'customerOrdersCount'>)
      | Event<
          CheckoutExpressEventName.SelectShippingOption,
          {
            type: string;
          }
        >
      | (Event<
          CheckoutExpressEventName.SubmitOrder,
          {
            checkout: Pick<Checkout, 'items'>;
            payment: PaymentChannel;
            shipping: { type: string };
          }
        > &
          Wait)
      | (Event<
          CheckoutExpressEventName.ReadyConfirmation,
          { payment: PaymentChannel }
        > &
          NonInteractive)
      | (Event<
          CheckoutExpressEventName.Purchase,
          { checkout: Checkout } & Marketing
        > &
          SessionPatch<
            | 'customerOrdersCount'
            | 'customerSubscriptionStatus'
            | 'customerFirstName'
            | 'customerLastName'
            | 'customerStreet'
            | 'customerCity'
            | 'customerPostcode',
            'customerPhone'
          > &
          NonInteractive)
    >
  | Group<
      GroupName.CheckoutQuestionnaire,
      | (SimpleEvent<CheckoutQuestionnaireEventName.Start> & NonInteractive)
      | (SimpleEvent<CheckoutQuestionnaireEventName.View> & NonInteractive)
      | (SimpleEvent<CheckoutQuestionnaireEventName.End> & NonInteractive)
      | Event<
          CheckoutQuestionnaireEventName.Answer,
          {
            answer: {
              key: string;
              value?: any;
            };
          }
        >
    >
  | Group<GroupName.PromoBar, SimpleEvent<PromoBarEventName.SubmitCouponCode>>
  | Group<GroupName.PromoBar, Event<CommonEventName.Click, { id: string }>>
  | Group<
      GroupName.PromoHeader,
      | Event<CommonEventName.Click, { title: string }>
      | Event<CommonEventName.View, { title: string }>
    >
  | Group<
      GroupName.AdTile,
      | Event<
          CommonEventName.Click,
          {
            click: ListItem;
          }
        >
      | Event<
          CommonEventName.Impression,
          {
            impression: ListItem;
          } & NonInteractive
        >
    >
  | Group<
      GroupName.Loyalty,
      | SimpleEvent<LoyaltyEventName.RedeemOptionView>
      | Event<LoyaltyEventName.Redeem, { value: number }>
      | (Event<CommonEventName.Impression, { label: string }> & NonInteractive)
      | Event<CommonEventName.Click, { label: string }>
      | Event<CommonEventName.Success, { label: string }>
    >
  | Group<GroupName.Debug, Event<'Experiment Loaded', { label: string }>>
  | Group<
      GroupName.FuBe,
      | Event<
          FuBeEventName.Question,
          {
            key: string;
            step: number;
          }
        >
      | Event<
          FuBeEventName.Answer,
          {
            key: string;
            step: number;
            value: string;
          }
        >
    >
  | Group<
      GroupName.ConversationalWidget,
      | Event<CommonEventName.Click, { label: string }>
      | (SimpleEvent<CommonEventName.Impression> & NonInteractive)
    >
  | Group<
      GroupName.Component,
      | (Event<ComponentEventName.Click, { group?: string; label: string }> &
          SessionPatch<'petProfile' | 'allSegments' | 'lastSegment'>)
      | Event<ComponentEventName.Impression, { label: string }>
      | Event<ComponentEventName.Click, { label: string }>
    >;
