import { SegmentationIds } from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';
import { omitNullableHandler } from 'utils/type-helper';

const DEFAULT_KEYS_TO_OMIT: (keyof SegmentationStore)[] = ['lastSegmentation'];

/**
 * Convert segmentation store to array string for Ninetailed Trails or GA
 * Currently specific allergies are omitted for business decision
 * Returns a sorted array with unique values
 */
export default function convertSegmentationToArray({
  store,
  shouldOmitLastSegment = false,
  keysToOmit,
}: {
  store: SegmentationStore;
  shouldOmitLastSegment?: boolean;
  keysToOmit?: (keyof SegmentationStore)[];
}): Array<SegmentationIds> {
  const _keysToOmit = [
    ...(shouldOmitLastSegment ? DEFAULT_KEYS_TO_OMIT : []),
    ...(keysToOmit ? keysToOmit : []),
  ];

  return [
    ...new Set(
      Object.entries(store)
        .map(([key, value]) => {
          if (!_keysToOmit.includes(key as keyof SegmentationStore)) {
            return value;
          }
        })
        .flat()
        .filter(omitNullableHandler)
    ),
  ].sort();
}
