import { useNinetailed } from '@ninetailed/experience.js-next';
import { setUser } from '@sentry/browser';
import * as authApi from 'api/auth';
import { getCheckoutSession, isIdentifiedCustomer } from 'api/checkout';
import { AxiosResponse } from 'axios';
import {
  activateShopifyCustomer,
  recoverShopifyCustomer,
  resetShopifyCustomer,
} from 'connectors/shopify-graphql';
import cookie from 'cookie';
import { Address } from 'interfaces/address';
import { Customer } from 'interfaces/customer';
import { referalTokenStore } from 'local-storage';
import { LoginResponse } from 'models/auth';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAsyncFn, useSessionStorage } from 'react-use';
import { useCommonStore } from 'stores/common';
import { useCookieStore } from 'stores/cookie';
import { gtm } from 'tracking/gtm';
import { encodePassword } from 'utils/auth';
import { axios, isAxiosError } from 'utils/axios';
import { getIpAddress } from 'utils/get-ip-address';

export const customerLocalStorageItemsCleanup = () => {
  referalTokenStore.remove();
};

interface AuthContextType {
  isUserAuthenticated: boolean;
  referalToken?: string;
  isUserLoggedIn: boolean;
  isGuest: boolean;
  authError: any;
  setCustomer: (customer: Customer | undefined) => void;
  onLogin: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<LoginResponse['data']['customer']>;
  onSocialLogin: (
    token: string,
    source: 'google' | 'facebook',
    accountPage?: boolean
  ) => Promise<LoginResponse['data']['customer']>;
  onSignUp: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<{ customerId: number; email: string }>;
  onLogout: () => void;
  onRecoverAccount: (email: string) => Promise<any>;
  onResetPassword: (resetUrl: string, email: string) => Promise<any>;
  onActivateAccount: (activationUrl: string, password: string) => Promise<any>;
  setAuthState: ({
    referalToken,
    customer,
  }: {
    referalToken: string;
    customer: Customer;
  }) => void;
  customer?: Customer;
  loading: boolean;
  loadingGoogle: boolean;
  loadingFacebook: boolean;
  isCustomerReady: boolean;
  fetchCustomer: () => Promise<void>;
  /**
   * indicates if getSession is already called
   * */
  isSessionFetched: boolean;

  shippingAddress: Address | null;
  usingBillingAddress: boolean;
  setBillingAddress: (address: Address | null) => void;
  shouldUseBillingAddress: (useBilling: boolean) => void;
  setShippingAddress: (address: Address | null) => void;
  billingAddress: Address | null;
}

const AuthContext = createContext<AuthContextType>({} as any);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();
  const { cookies, updateCookie } = useCookieStore();
  const [referalToken, setReferalToken] = useState(referalTokenStore.get());
  const [authError, setAuthError] = useState();

  const [shippingAddress, setShippingAddress] =
    useSessionStorage<Address | null>('pd:checkout-shipping', null);
  const [usingBillingAddress, shouldUseBillingAddress] =
    useSessionStorage<boolean>('pd:checkout-use-billing', false);
  const [billingAddress, setBillingAddress] = useSessionStorage<Address | null>(
    'pd:checkout-billing-address',
    null
  );

  const { identify } = useNinetailed();

  const [customer, setCustomer] = useState<Customer | undefined>();
  const [loading, setLoading] = useState(true);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    !!Number(cookies?.isLoggedIn)
  );

  useEffect(() => {
    setIsUserLoggedIn(!!Number(cookies?.isLoggedIn));
  }, [cookies?.isLoggedIn]);

  /** Push shopId to identify shop */
  React.useEffect(() => {
    identify('', { shopId: process.env.SHOP_ID });
  }, [identify]);

  // Push created at timestamp to Ninetailed with every shop visit
  React.useEffect(() => {
    if (customer && customer.createdAt) {
      identify(customer.customerId + '', {
        createdAt: customer.createdAt,
        ...(customer.subscriptionStatus
          ? { subscriptionStatus: customer.subscriptionStatus }
          : {}),
      });
    }
  }, [customer, identify]);

  React.useEffect(() => {
    if (customer) {
      gtm({
        session: {
          customerEmail: customer.email,
          customerId: customer.customerId,
          customerOrdersCount: customer.ordersCount,
        },
      });

      identify(customer.customerId + '', {
        isUserLoggedIn: true,
      });
    } else if (!loading) {
      gtm({
        session: {
          customerEmail: undefined,
          customerId: undefined,
          customerOrdersCount: undefined,
        },
      });
    }
  }, [customer, loading, identify]);

  /**
   * Set up context for Sentry logs
   */
  React.useEffect(() => {
    if (!loading && customer) {
      setUser({
        email: customer.email,
        id: customer.customerId,
      });
    } else if (!loading) {
      setUser(null);
    }
  }, [customer, loading]);

  /**
   * Push release version to Ninetailed with every shop visit
   */
  useEffect(() => {
    if (cookies.pd_rv) {
      identify(`${customer ? customer.customerId : ''}`, {
        releaseVersion: cookies.pd_rv,
      });
    }
  }, [cookies.pd_rv, customer, identify]);

  /**
   * @TODO
   * This state should be indicated if customer is undefined or not.
   * But we differentiate EC or NC by this way.
   * Should be refactored
   */
  const [isSessionFetched, setIsSessionFetched] = useState(false);

  /** The client IPv6 address is fetched only once and stored in the common store */
  const { clientIpv6, isIpv6fetched, setClientIpv6, setIsIpv6fetched } =
    useCommonStore();

  useEffect(() => {
    if (!isIpv6fetched) {
      getIpAddress().then((ipAddress) => {
        setIsIpv6fetched(true);
        setClientIpv6(ipAddress);
      });
    }
  }, [isIpv6fetched, setClientIpv6, setIsIpv6fetched]);

  const fetchCustomer = useCallback(async () => {
    try {
      const data = await getCheckoutSession();

      if (!data) {
        setLoading(false);
        return;
      }

      setIsSessionFetched(true);
      gtm({
        //customerEmail etc will also be set by setCustomer, but be careful, the very first gtm push for sessions needs to have as well all data available
        session: {
          clientIp: data.clientIp,
          ...(clientIpv6 && { clientIpv6 }),
          landingPageUrl: data.landingPageUrl,
          ...(isIdentifiedCustomer(data)
            ? {
                customerId: data.customerId,
                customerEmail: data.email,
                ...(data?.firstName
                  ? { customerFirstName: data?.firstName }
                  : {}),
                ...(data?.lastName && { customerLastName: data?.lastName }),
                ...(data?.phone && { customerPhone: data?.phone }),
                ...(data?.postcode && { customerPostcode: data?.postcode }),
                ...(data?.street && { customerStreet: data?.street }),
                ...(data?.city && { customerCity: data?.city }),
                ...(data?.countryCode && {
                  customerCountry: data?.countryCode,
                }),
                customerOrdersCount: data.ordersCount,
                customerSubscriptionStatus: data.subscriptionStatus,
              }
            : {}),
        },
      });

      if (isIdentifiedCustomer(data)) {
        setCustomer({ ...data, id: data.customerId });
      } else {
        setCustomer(undefined);
      }
    } catch (e) {
      console.error(e, 'getCheckoutSession');
    } finally {
      setLoading(false);
    }
  }, [clientIpv6]);

  React.useEffect(() => {
    // Should avoid calling the endpoint without the ref parameter
    if (typeof window !== 'undefined') {
      fetchCustomer();
    }
  }, [isUserLoggedIn, fetchCustomer]);

  // isUserAuthenticated is true only when pd_cat is true
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(
    cookies?.pd_ca === '1'
  );
  const isGuest = React.useMemo(() => {
    return Boolean(customer) && isUserLoggedIn === false && isUserAuthenticated;
  }, [customer, isUserLoggedIn, isUserAuthenticated]);

  React.useEffect(() => {
    if (!isUserLoggedIn) {
      setCustomer(undefined);
    }
  }, [isUserLoggedIn]);

  /** Should be set true if user is logged in as a registered customer*/
  const setLoggedIn = React.useCallback(
    (login: boolean): void => {
      const date = new Date();
      date.setDate(date.getDate() + 90);
      updateCookie('isLoggedIn', `${login ? 1 : 0}`, {
        expires: date,
        path: '/',
      });
    },
    [updateCookie]
  );

  const authInterceptor = React.useCallback(
    (res: AxiosResponse): any => {
      const currentCookies = cookie.parse(document.cookie);
      if (currentCookies.pd_ca) {
        setIsUserAuthenticated(currentCookies.pd_ca === '1');
      } else {
        setIsUserAuthenticated(false);
        setLoggedIn(false);
      }
      return res;
    },
    [setIsUserAuthenticated, setLoggedIn]
  );

  React.useEffect(() => {
    const interceptor = axios.interceptors.response.use(authInterceptor);

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [authInterceptor]);

  const [, shopifyReset] = useAsyncFn(
    async ({
      variables,
    }: {
      variables: Parameters<typeof resetShopifyCustomer>[0];
    }) => {
      return resetShopifyCustomer({ ...variables });
    }
  );
  const [, shopifyActivateAccount] = useAsyncFn(
    async ({
      variables,
    }: {
      variables: Parameters<typeof activateShopifyCustomer>[0];
    }) => {
      return activateShopifyCustomer({ ...variables });
    }
  );
  const [, shopifyRecover] = useAsyncFn(
    async ({
      variables,
    }: {
      variables: Parameters<typeof recoverShopifyCustomer>[0];
    }) => {
      return recoverShopifyCustomer({ ...variables });
    }
  );

  const petsdeliSignUp = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const data = await authApi.register({ email, password });

      if (data.error) {
        throw Error(data?.error.type);
      }

      setReferalToken(data.data?.referalToken);
      referalTokenStore.set(data.data?.referalToken as string);

      return {
        customerId: data.data?.customer.id,
        email: data.data?.customer.email,
      };
    },
    []
  );

  /** Set state required after authentication */
  const setAuthState: AuthContextType['setAuthState'] = useCallback(
    ({ referalToken, customer }) => {
      setReferalToken(referalToken);
      referalTokenStore.set(referalToken);

      const _customer: Customer = {
        email: customer.email,
        customerId: customer.id,
        id: customer.id,
        ordersCount: customer.ordersCount,
        createdAt: customer.createdAt,
      };

      setCustomer(_customer);

      setLoggedIn(true);
      setIsUserLoggedIn(true);
      setIsUserAuthenticated(true);
    },
    [setLoggedIn]
  );

  const onLogin: AuthContextType['onLogin'] = useCallback(
    async ({ email, password }) => {
      try {
        const response = await authApi.login({ email, password });

        const { customer, referalToken } = response.data.data;

        setIsUserAuthenticated(true);

        // Assign address if user login while in checkout flow.
        if (customer.defaultAddress) {
          setShippingAddress({
            id: customer.defaultAddress.id,
            firstName: customer.defaultAddress.firstName,
            lastName: customer.defaultAddress.lastName,
            address1: customer.defaultAddress.address1,
            company: customer.defaultAddress.company,
            city: customer.defaultAddress.city,
            zip: customer.defaultAddress.zip,
            countryCode: customer.defaultAddress.countryCode,
            phone: customer.defaultAddress.phone,
            default: customer.defaultAddress.default,
          });
        }

        setAuthState({
          customer: {
            ...customer,
            customerId: customer.id,
          },
          referalToken,
        });

        return customer;
      } catch (error) {
        setAuthError(error);

        if (isAxiosError<LoginResponse>(error)) {
          throw error.response?.data.error;
        }

        throw error;
      } finally {
        fetchCustomer();
      }
    },
    [setAuthState, setShippingAddress, fetchCustomer]
  );

  const onSocialLogin: AuthContextType['onSocialLogin'] = useCallback(
    async (token, source, accountPage) => {
      if (source === 'google') setLoadingGoogle(true);
      if (source === 'facebook') setLoadingFacebook(true);
      try {
        const {
          data: { customer, referalToken },
        } = await authApi.socialLogin(token, source);

        setShippingAddress(customer.defaultAddress);

        setAuthState({
          customer: {
            ...customer,
            customerId: customer.id,
          },
          referalToken,
        });
        if (accountPage) router.push('/account');
        return customer;
      } catch (error) {
        setAuthError(error);

        if (isAxiosError<LoginResponse>(error)) {
          throw error.response?.data.error;
        }

        throw error;
      } finally {
        if (source === 'google') setLoadingGoogle(false);
        if (source === 'facebook') setLoadingFacebook(false);
      }
    },
    [setAuthState, router]
  );

  const onSignUp: AuthContextType['onSignUp'] = useCallback(
    async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<{ customerId: number; email: string }> => {
      try {
        const res = await petsdeliSignUp({ email, password });
        setCustomer({
          id: res.customerId,
          customerId: res.customerId,
          email: res.email,
          ordersCount: 0,
          createdAt: Math.floor(Date.now() / 1000),
        });

        // Send data to ninetailed
        identify(res.customerId + '', {
          createdAt: Math.floor(Date.now() / 1000),
          ordersCount: 0,
          isUserLoggedIn: true,
        });

        setLoggedIn(true);
        return res;
      } catch (error) {
        setAuthError(error);

        if (isAxiosError<LoginResponse>(error)) {
          throw error.response?.data.error;
        }

        throw error;
      } finally {
        fetchCustomer();
      }
    },
    [petsdeliSignUp, setLoggedIn, identify, fetchCustomer]
  );

  const onLogout = useCallback(async () => {
    await authApi.logout();
    setReferalToken(undefined);
    setShippingAddress(null);
    if (customer) {
      identify(customer.customerId + '', {
        isUserLoggedIn: false,
      });
    }
    setBillingAddress(null);
    shouldUseBillingAddress(false);
    customerLocalStorageItemsCleanup();
    setCustomer(undefined);
    setLoggedIn(false);
  }, [
    setBillingAddress,
    setLoggedIn,
    setShippingAddress,
    shouldUseBillingAddress,
    identify,
    customer,
  ]);

  const onRecoverAccount = useCallback(
    async (email: string) => {
      try {
        return new Promise((resolve, reject) => {
          shopifyRecover({
            variables: {
              email,
            },
          })
            .then((response) => {
              if (
                response.customerRecover?.customerUserErrors &&
                response.customerRecover?.customerUserErrors.length > 0
              ) {
                reject(response.customerRecover?.customerUserErrors[0].message);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } catch (error) {
        setAuthError(error);
      }
    },
    [shopifyRecover]
  );

  /**
   * Pass the full URL from the reset password email and the new password
   */
  const onResetPassword = useCallback(
    async (resetUrl: string, password: string) => {
      try {
        return new Promise((resolve, reject) => {
          shopifyReset({
            variables: {
              resetUrl,
              password: encodePassword(password),
            },
          })
            .then((response) => {
              if (
                response.customerResetByUrl?.customerUserErrors &&
                response.customerResetByUrl?.customerUserErrors.length > 0
              ) {
                reject(
                  response.customerResetByUrl?.customerUserErrors[0].message
                );
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } catch (error) {
        setAuthError(error);
      }
    },
    [shopifyReset]
  );

  /**
   * Pass the full URL from the activation email and the new password
   */
  const onActivateAccount = useCallback(
    async (activationUrl: string, password: string) => {
      try {
        return new Promise((resolve, reject) => {
          shopifyActivateAccount({
            variables: {
              activationUrl,
              password,
            },
          })
            .then((response) => {
              if (
                response.customerActivateByUrl?.customerUserErrors &&
                response.customerActivateByUrl?.customerUserErrors.length > 0
              ) {
                reject(
                  response.customerActivateByUrl.customerUserErrors[0].message
                );
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } catch (error) {
        setAuthError(error);
      }
    },
    [shopifyActivateAccount]
  );

  return (
    <AuthContext.Provider
      value={{
        referalToken,
        isUserLoggedIn,
        isUserAuthenticated,
        authError,
        onLogin,
        onSocialLogin,
        onSignUp,
        onLogout,
        onResetPassword,
        onActivateAccount,
        onRecoverAccount,
        customer,
        setCustomer,
        loading,
        loadingGoogle,
        loadingFacebook,
        isGuest,
        isCustomerReady: !loading && (!isUserLoggedIn || Boolean(customer)),
        setAuthState,
        fetchCustomer,
        isSessionFetched,

        shippingAddress,
        setBillingAddress,
        setShippingAddress,
        usingBillingAddress,
        shouldUseBillingAddress,
        billingAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => useContext(AuthContext);
