import { useNinetailed, useProfile } from '@ninetailed/experience.js-next';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  NutritionSegmentationIds,
  SegmentationData,
  SegmentationIds,
  getSegmentationFromUrl,
  isAllergySegmentation,
} from 'constants/segmentation';
import useIsAdultRelated from 'hooks/common/use-is-adult-related';
import useDynamicTest from 'hooks/test/use-dynamic-ux';
import { DynamicTestScenario } from 'hooks/test/use-dynamic-ux/const';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';

import { isEmpty } from 'lodash';
import convertSegmentationToArray from 'utils/segmentation/convert-segmentation-to-array';
import mergeSegmentationStores from 'utils/segmentation/merge-segmentaion-stores';
import updateSegmentationStore from 'utils/segmentation/update-segmentation-store';
import { isEmptyObject } from 'utils/type-helper';
import { isTraits } from '../utils/ninetailed';
import { useAuth } from './auth';

export type SegmentationContextType = {
  segmentationData: typeof SegmentationData;
  /** Method to push segmentation */
  pushSegmentation: (arg: { segmentation: string | SegmentationStore }) => void;
  /** Method to remove segmentation */
  removeSegmentation: (arg: { segmentation: string }) => void;
  /** Current selected segmentation. it's stored in local storage to bridge sessions */
  multipleSegmentation?: SegmentationStore;
  /** Last pushed segmentation, when only one segmentation matter, value should be taken from here  */
  lastSegmentation?: SegmentationIds;
  /** segmentation state. Based on set up, it could be single or multiple segmentation */
  segmentation?: SegmentationIds | SegmentationStore;
  /** Get segmentation data from associated URL */
  getSegmentationFromUrl: (arg: {
    url: string;
  }) => (typeof SegmentationData)['segmentation'][0] | undefined;
  /** true if segmentation data is being validated  */
  isSegmentationReady: boolean;
  /** true if personalization should be omitted e.g welpe personalization on adult page */
  shouldOmitPersonalization: boolean;
  /** true if segmentation should considered to be multiple  */
  shouldUseMultipleSegmentation: boolean;
};

const SegmentationContext = createContext<SegmentationContextType>({} as any);

export type SegmentationStore = {
  breedSegmentation?: BreedSegmentationIds;
  /** Segmentation store for multiple need */
  needSegmentation?: Array<
    NutritionSegmentationIds | CatNutritionSegmentationIds
  >;
  ageSegmentation?: AgeSegmentationIds;
  /** Segmentation store for multiple allergies */
  allergySegmentation?: Array<AllergySegmentationIds>;
  lastSegmentation?: SegmentationIds;
};

const FEATURE_FLAG = getFeatureFlag(FeatureFlag.segmentation);

/**
 * Context to share pet needs segmentation through app and take care of updating it
 */
export const SegmentationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { testScenario } = useDynamicTest();

  const { asPath, isReady } = useRouter();

  const [localSegmentationStore, setLocalSegmentationStore] =
    useLocalStorage<SegmentationStore>('pd:segmentationStore', {});

  const [segmentationStoreState, setSegmentationStoreState] =
    useState<SegmentationStore>(localSegmentationStore || {});

  const { identify } = useNinetailed();
  const { profile, loading } = useProfile();
  const { customer } = useAuth();

  const pushSegmentation: SegmentationContextType['pushSegmentation'] =
    useCallback(({ segmentation }) => {
      if (!FEATURE_FLAG) {
        return;
      }

      if (isEmpty(segmentation)) {
        console.warn('empty segmentation');
        return;
      }

      if (typeof segmentation !== 'string') {
        setSegmentationStoreState(segmentation);
        return;
      }

      const _setSegmentationStoreState = (segmentation) =>
        setSegmentationStoreState((prev) => {
          return updateSegmentationStore({
            existingStore: {
              ...prev,
              // omit allergy
              ...(!isAllergySegmentation(segmentation as SegmentationIds)
                ? { lastSegmentation: segmentation }
                : {}),
            },
            segmentation,
          });
        });

      _setSegmentationStoreState(segmentation);
    }, []);

  const removeSegmentation: SegmentationContextType['removeSegmentation'] =
    useCallback(({ segmentation }) => {
      const _setSegmentationStoreState = (segmentation) =>
        setSegmentationStoreState((prev) => {
          return updateSegmentationStore({
            existingStore: { ...prev },
            segmentation,
            mode: 'remove',
          });
        });

      _setSegmentationStoreState(segmentation);
    }, []);

  /** Sync with local state */
  useEffect(() => {
    setLocalSegmentationStore(segmentationStoreState);
  }, [segmentationStoreState, setLocalSegmentationStore]);

  /**
   * Sync segmentation to Ninetailed
   */
  useEffect(() => {
    identify(`${customer ? customer.customerId : ''}`, {
      allSegments: convertSegmentationToArray({
        store: segmentationStoreState,
        shouldOmitLastSegment: true,
      }),
      ...(segmentationStoreState.lastSegmentation
        ? { lastSegment: segmentationStoreState.lastSegmentation }
        : {}),
    });
  }, [customer, identify, segmentationStoreState]);

  /**
   * Initial state set up from localStorage or Ninetailed trails
   */
  const triggerInitialPush = useRef(false);
  useEffect(() => {
    if (!isReady || triggerInitialPush.current || loading) return;
    triggerInitialPush.current = true;

    let initialSegmentaionState = {};

    const _segmentation = getSegmentationFromUrl({ url: asPath });
    if (_segmentation) {
      initialSegmentaionState = updateSegmentationStore({
        segmentation: _segmentation.id as SegmentationIds,
        existingStore: initialSegmentaionState,
      });
    }

    if (localSegmentationStore && !isEmptyObject(localSegmentationStore)) {
      initialSegmentaionState = mergeSegmentationStores({
        target: initialSegmentaionState,
        source: localSegmentationStore,
      });
    } else if (
      profile &&
      isTraits(profile.traits) &&
      profile.traits.allSegments
    ) {
      let segmentationStore = {};
      profile.traits.allSegments.forEach((seg) => {
        segmentationStore = updateSegmentationStore({
          segmentation: seg as SegmentationIds,
          existingStore: segmentationStore,
        });
      });
      initialSegmentaionState = mergeSegmentationStores({
        target: initialSegmentaionState,
        source: segmentationStore,
      });
    }

    pushSegmentation({ segmentation: initialSegmentaionState });
  }, [
    asPath,
    isReady,
    loading,
    localSegmentationStore,
    profile,
    pushSegmentation,
    segmentationStoreState,
  ]);

  const { shouldOmitPersonalization } = useIsAdultRelated({
    segmentation:
      testScenario === DynamicTestScenario.control ||
      testScenario === DynamicTestScenario.variantA
        ? segmentationStoreState.lastSegmentation
        : segmentationStoreState,
  });

  const value = React.useMemo(() => {
    return {
      segmentationData: SegmentationData,
      multipleSegmentation: segmentationStoreState,
      lastSegmentation: segmentationStoreState.lastSegmentation,
      pushSegmentation,
      removeSegmentation,
      getSegmentationFromUrl,
      isSegmentationReady: true,
      shouldOmitPersonalization,
      shouldUseMultipleSegmentation:
        testScenario !== DynamicTestScenario.control &&
        testScenario !== DynamicTestScenario.variantA,
      segmentation:
        testScenario !== DynamicTestScenario.control &&
        testScenario !== DynamicTestScenario.variantA
          ? segmentationStoreState
          : segmentationStoreState.lastSegmentation,
    };
  }, [
    pushSegmentation,
    removeSegmentation,
    segmentationStoreState,
    shouldOmitPersonalization,
    testScenario,
  ]);

  return (
    <SegmentationContext.Provider value={value}>
      {children}
    </SegmentationContext.Provider>
  );
};

export const useSegmentationContext = () => useContext(SegmentationContext);
