import { PetProfile } from 'contexts/pet-profile';
import { omitNullableHandler } from 'utils/type-helper';

const keysToOmit: (keyof PetProfile)[] = [
  'selectedAllergies',
  'lastTouched',
  'selectedFoodTypes',
];

/**
 * Check if petProfile has enough properties for API
 */
export default function isPetProfileEmpty({
  petProfile,
}: {
  petProfile?: PetProfile;
}): boolean {
  if (!petProfile) {
    return true;
  }

  return (
    Object.entries(petProfile)
      .map(([key, value]) => {
        if (!keysToOmit.includes(key as keyof PetProfile)) {
          return value;
        }
      })
      .filter((v) => typeof v !== 'boolean')
      .flat()
      .filter(omitNullableHandler).length === 0
  );
}
