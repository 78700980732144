import { CollectionProductResponse } from 'api/product';
import { SmartCollectionResponse } from 'api/product/getSmartColleciton/types';
import { isSmartCollectionItem } from 'api/product/getSmartColleciton/utils';
import { SegmentationIds } from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';
import isString from 'lodash/isString';
import {
  ProductSegmentation,
  StaticPDPProduct,
} from 'modules/product-details/types';
import { slugify } from 'utils/strings';
import getPersonalizedSubtitle from './utils/getPersonalizedSubtitle';

const getResizedImageUrl = (url: string, size: number) => {
  const lastDot = url.lastIndexOf('.');
  const resizedUrl = `${url.substr(0, lastDot)}_${String(size)}x${url.substr(
    lastDot
  )}`;
  return resizedUrl;
};

const sortVariants = (a: any, b: any) => +a.priceV2.amount - +b.priceV2.amount;

type CollectionProductInput = Omit<
  CollectionProductResponse,
  '_updatedAt' | '_updated' | 'sk' | 'pk'
>;

function assertIsStaticPDPProduct(data: unknown): data is StaticPDPProduct {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (
    !isSmartCollectionItem(data) &&
    (typeof data['pk'] !== 'undefined' ||
      typeof data['objectID'] !== 'undefined')
  ) {
    return false;
  }
  return true;
}

function assertIsSmartCollectionProduct(
  product: unknown
): product is SmartCollectionResponse['items'][number] {
  if (typeof product !== 'object' || product === null) {
    return false;
  }

  const prod = product as SmartCollectionResponse['items'][number];

  if (typeof prod.subtitle !== 'string') {
    return false;
  }

  if (prod.bestseller !== undefined && typeof prod.bestseller === 'boolean') {
    return false;
  }

  return true;
}

export type Variant = Omit<
  CollectionProductInput['variants'][number],
  'compareAtPrice'
> & {
  compareAtPrice?: string;
};

enum MetafieldNameSpaces {
  Petsdeli = 'petsdeli',
  Subscription = 'subscription',
  Accentuate = 'accentuate',
  Rating = 'rating',
}

export type Metadata = {
  [x in MetafieldNameSpaces]: {
    [key in string]?: any;
  };
};

/**
 * Empty Object returned from BE for migration to PDEX
 * All the required information should be already assigned to product response
 */
type EmptyMetadata = {
  [MetafieldNameSpaces.Accentuate]: Record<string, never>;
};

type SearchProductInput = Omit<
  CollectionProductInput,
  'variants' | 'metadata' | 'createdAt'
> & {
  variants: Array<Variant>;
  metadata: Metadata;
};

export type CollectionVariant = Omit<
  CollectionProductResponse['variants'][number],
  'compareAtPrice' | 'id'
> & {
  id: string;
  /**
   * Price to be compared with discount price.
   * decimal is omitted from the value e.g 4999 meaning 49€ 99cent"
   * */
  compareAtPrice?: number;
};

/** Common Product interface */
export class CollectionProduct {
  public id: string;
  public productType: string;
  public productId: string;
  public title: string;
  public tags: string[];
  public handle: string;
  /** Make it private to hide implementation  */
  private metadata: Metadata | EmptyMetadata = {
    [MetafieldNameSpaces.Accentuate]: {},
    [MetafieldNameSpaces.Subscription]: {},
    [MetafieldNameSpaces.Rating]: {},
    [MetafieldNameSpaces.Petsdeli]: {},
  };
  public availableForSale: boolean;
  public variants: Array<CollectionVariant>;
  public image: Partial<CollectionProductResponse['image']> = {};

  /** This property is assigned when a variant is assigned as product to promote a specific variant on dashboard */
  public promoteVariantId?: string;

  public createdAt: string;

  private segmentation?: ProductSegmentation;

  private ribbonText?: string;
  private ribbonColor?: string;
  /** Sub title for product which is generated from segmentation input */
  public subTitle?: string | React.ReactNode;
  /** Total score of a product score calculated by logics */
  public totalRecommendationScore?: number;

  private isBestSeller?: boolean;
  private isStaticCollectionItem?: boolean;
  private variantLength: number;
  /** Array of variant title */
  private variantTitles?: Array<string>;
  private isSmartCollectionItem: boolean;
  /**
   * If true, subtitle from BE should be ignored.
   * This is a hot fix for CAT food type CDP subtitle personation
   * */
  private shouldIgnoreSubtitle: boolean;

  public getIsSmartCollectionItem = (): boolean => {
    return this.isSmartCollectionItem;
  };

  public getSlugifiedTags = (): Array<string> => {
    return this.tags.map(slugify);
  };

  public getProductTitle = (): string => {
    return this.title || this.metadata.accentuate.mainTitle;
  };

  /**
   * get subtitle for collection item
   * Since for cat collection, BE can't generate subtitle, subtitle is decided based on tags
   */
  public getProductSubtitle = ({
    segmentation,
  }: {
    segmentation?: SegmentationIds;
  } = {}): string | React.ReactNode | undefined => {
    if (!this.shouldIgnoreSubtitle && !this.isStaticCollectionItem) {
      return this.subTitle;
    }
    if (segmentation) {
      const subtitle = getPersonalizedSubtitle({
        segmentation,
        tags: this.tags,
      });
      if (subtitle) return subtitle;
    }
    return this.subTitle ?? this.metadata.accentuate.subTitle;
  };

  public getRibbon = () => {
    if (this.metadata['accentuate'].ribbonText1) {
      return {
        ribbonText: this.metadata['accentuate'].ribbonText1,
        ribbonText2: this.metadata['accentuate'].ribbonText2,
        ribbonColor: this.metadata['accentuate'].ribbonColor,
      };
    } else {
      return {
        ribbonText: this.ribbonText,
        ribbonColor: this.ribbonColor,
      };
    }
  };

  public getIsBestSeller = ({
    segment,
  }: {
    segment?: SegmentationIds | SegmentationStore;
  } = {}): boolean => {
    if (this.segmentation && segment) {
      const key =
        typeof segment === 'string' ? segment : segment.lastSegmentation;
      if (key && this.segmentation && this.segmentation[key]) {
        return !!this.segmentation[key].isBestSeller;
      }
    }
    return !!this.isBestSeller;
  };

  /**
   * Check if from price e.g "ab 19.99" should be shown
   */
  public getShouldShowFromPrice = (): boolean => {
    return this.variantLength > 1 || this.variants.length > 1;
  };

  /**
   * Retrieve weight string from variant title.
   * Variant title are conventionally named in the form of "[weight per one product] / [packing unit]"
   * e.g "200g / Einzeldose" , "Huhn / 12 x 400g"
   */
  public getWeights = (): string[] => {
    const titles = this.isSmartCollectionItem
      ? (this.variantTitles ?? [])
      : this.variants.map((v) => v.title);
    const trimmed = titles.map((t) => t.split('/')[0].trim());

    if (this.isSmartCollectionItem) {
      return Array.from(new Set(trimmed));
    }

    return trimmed.filter(
      (weight: string, index: number) =>
        index ===
        this.variants.findIndex((variant) => variant.title.includes(weight))
    );
  };

  /** Returns the cheapest variant */
  public getCheapestVariant = () => {
    const _variants = [...this.variants];
    return _variants.sort(sortVariants)[0];
  };

  /** Returns the first cheapest subscribable variant (if available) */
  public getFirstCheapestAvailableSubsVariant =
    (): CollectionVariant | null => {
      const _tags = [...this.tags];
      const _variants = [...this.variants];
      const variant = _variants
        .filter((variant) => variant.availableForSale)
        .sort((a, b) => +a.priceV2.amount - +b.priceV2.amount)[0];
      return _tags.includes('SubscriptionEnabled') ? variant : null;
    };

  constructor(
    product:
      | CollectionProductInput
      | SearchProductInput
      | StaticPDPProduct
      | SmartCollectionResponse['items'][number],
    shouldIgnoreSubtitle?: boolean
  ) {
    this.isSmartCollectionItem = false;
    this.shouldIgnoreSubtitle = shouldIgnoreSubtitle || false;
    if (isSmartCollectionItem(product)) {
      this.isSmartCollectionItem = true;
      this.variantTitles = product.variant_titles;
      this.id = product.product_id;
      this.productType = product.product_category;
      this.title = product.product_title;

      try {
        // Backend returns bold tag as "** some word** "
        const formattedText = product.subtitle.replace(
          /\*\*(.*?)\*\*/g,
          '<span class="font-medium">$1</span>'
        );
        this.subTitle = (
          <span dangerouslySetInnerHTML={{ __html: formattedText }} />
        );
      } catch (error) {
        this.subTitle = product.subtitle;
      }

      this.tags = product.tags;
      this.handle = product.slug;
      this.productId = product.product_id.toString();
      this.metadata = { [MetafieldNameSpaces.Accentuate]: {} };
      this.availableForSale = product.available;
      this.image.src = product.image_src;
      this.image.alt = product.image_alt || undefined;
      this.variants = [
        {
          availableForSale: product.available,
          image: {
            alt: product.image_alt || undefined,
            src: product.image_src,
          },
          weight: Number(product.net_grams),
          id: product.variant_id,
          title: product.variant_title,
          sku: product.sku,
          priceV2: {
            currencyCode: process.env.CURRENCY_CODE,
            amount: product.price,
          },
          weightUnit: 'GRAMS',
          compareAtPrice: product.compare_price
            ? Math.round(parseFloat(product.compare_price) * 100)
            : undefined,
        },
      ];
      this.createdAt = '0';
      this.ribbonText = product.ribbon?.text;
      this.ribbonColor = product.ribbon?.color;
      this.isBestSeller = product.bestseller;
      this.variantLength = product.variant_length;
      /** @TODO : assertIsStaticPDPProduct is pretty weak check  */
    } else if (assertIsStaticPDPProduct(product)) {
      this.id = typeof product.id === 'number' ? `${product.id}` : product.id;
      this.productType = product.productType;
      this.title = product.title || product.metadata.accentuate.mainTitle;
      this.tags = product.tags;
      this.handle = product.handle;
      this.productId = product.id + '';
      this.metadata = product.metadata as Metadata;
      this.availableForSale = product.variants.some(
        (variant) => variant.availableForSale
      );
      /**
       * Due to the different implementation on BE, compareAtPrice could be number e.g 4999 or string e.g "49.99"
       * Therefore we align it to be number type without decimal.
       */
      this.variants = product.variants.map((variant) => {
        return {
          ...variant,
          id: variant.id + '',
          image: {
            src: variant.image.originalSrc,
            alt: variant.image.altText || '',
          },
          weightUnit: variant.weightUnit as 'GRAMS',
          compareAtPrice: variant.compareAtPrice ?? undefined,
          // compareAtPrice,
        };
      });
      this.variantLength = product.variants.length;
      this.image.src = product.variants[0].image.originalSrc;
      this.image.alt = product.variants[0].image.altText || '';
      this.createdAt = '0';
      this.promoteVariantId = product.id + '';
    } else {
      this.id = product.masterProductId
        ? product.masterProductId + ''
        : product.id + '';
      this.productType = product.productType;
      this.title = product.title || product.metadata.accentuate?.mainTitle;
      this.tags = product.tags;
      this.handle = product.handle;
      this.productId = product.productId;
      this.metadata = product.metadata;
      this.availableForSale = product.variants.some(
        (variant) => variant.availableForSale
      );
      /**
       * Due to the different implementation on BE, compareAtPrice could be number e.g 4999 or string e.g "49.99"
       * Therefore we align it to be number type without decimal.
       */
      this.variants = product.variants.map(
        (variant: Variant | CollectionProductInput['variants'][number]) => {
          const compareAtPrice = variant.compareAtPrice
            ? isString(variant.compareAtPrice)
              ? +variant.compareAtPrice * 100
              : variant.compareAtPrice
            : undefined;
          return {
            ...variant,
            id: variant.id + '',
            compareAtPrice,
          };
        }
      );
      this.variantLength = product.variants.length;
      this.image.src = product.image.src;
      this.image.alt = product.image.alt;
      this.createdAt = product['createdAt'] ?? '0';
      this.promoteVariantId = product.masterProductId
        ? product.id + ''
        : undefined;
    }

    if (
      !assertIsSmartCollectionProduct(product) &&
      !assertIsStaticPDPProduct(product)
    ) {
      this.isStaticCollectionItem = true;
      if (
        product.segmentation &&
        Object.values(product.segmentation).length > 0
      ) {
        this.segmentation = product.segmentation;
      }
    }
  }
}
