import useExpressCheckoutError from 'hooks/checkout/useExpressCheckoutError';
import { ShopifyNamespace } from 'interfaces/shopify';
import maxBy from 'lodash/maxBy';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';
import { Gtm, gtm } from 'tracking/gtm';
import { useCart } from '../../contexts/cart';
import useMOVConditions from '../../hooks/common/use-mov-conditions';
import { Product } from '../../interfaces/product';
import {
  isCheckoutCreateMutation,
  normalizeShopifyResponse,
  shopifyGid,
  shopifyGidToId,
} from '../../utils/shopify';
import { showErrorToast } from '../../utils/toasts';

export const useCartState = () => {
  const {
    loading: checkoutLoading,
    canUseCoupon,
    cart,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsRedemptionItem,
    containsSubscriptionItem,
    discount,
    effectiveCart,
    effectiveCartErrors,
    isBonusModalOpen,
    isCartEmpty,
    isCartReady,
    isCartUpdating,
    isCheckoutBeingCreated,
    items,
    itemsCount,
    oosItems,
    setCouponCode,
    addProductsToCart,
    goToCheckout,
    setIsBonusModalOpen,
  } = useCart();

  const {
    shouldShowMinimumBasketNotice,
    minimumBasketValue,
    insufficientMoney,
  } = useMOVConditions({ effectiveCart });

  const router = useRouter();

  const error = useExpressCheckoutError();

  useEffect(() => {
    if (error) {
      showErrorToast({
        error: `error:express-checkout:${error.error}`,
        caller: 'useCartState',
        autoClose: false,
      });
    }
  }, [error]);

  useEffect(() => {
    if (router.isReady && router.query['interactionReason'] === 'DECLINED') {
      showErrorToast({
        error: 'error:express-checkout:common-error',
        caller: 'useCartState',
      });
    }
  }, [router.isReady, router.query]);

  const mostExpensiveProduct = useMemo(() => {
    const lineItem = maxBy(cart.items, (lineItem) => lineItem.variant.price);
    const product = lineItem?.variant?.product;

    if (product && product.productType !== 'Testpaket') {
      return {
        ...product,
        id: shopifyGidToId(product.id),
      } as Pick<Product, 'id' | 'tags' | 'productType' | 'title'>;
    }

    return null;
  }, [cart.items]);

  /** Execute production addition from url params of add  */
  const addItemsByUrlPerformed = useRef(false);
  useEffect(() => {
    if (!isCartReady || addItemsByUrlPerformed.current) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const productsToAdd = params.getAll('add').map((value) => {
      const [variantId, quantity = '1', interval] = value.split(',');
      return {
        variantId: parseInt(variantId, 10),
        quantity: parseInt(quantity, 10),
        customAttributes: interval
          ? [{ key: 'interval', value: interval }]
          : undefined,
      };
    });
    if (!addItemsByUrlPerformed.current && productsToAdd.length > 0) {
      const _addProductsToCart = async (): Promise<void> => {
        try {
          const res = await addProductsToCart(productsToAdd);
          addItemsByUrlPerformed.current = true;
          let lineItems;
          if (isCheckoutCreateMutation(res)) {
            lineItems = res.checkoutCreate?.checkout?.lineItems;
          } else {
            lineItems = res.checkoutLineItemsReplace?.checkout?.lineItems;
          }
          const items = lineItems && normalizeShopifyResponse(lineItems);
          productsToAdd.map((product) => {
            const gId = shopifyGid(
              ShopifyNamespace.ProductVariant,
              product.variantId
            );
            const lineItem = items.find((item) => item.variant.id === gId);

            if (lineItem) {
              gtm({
                group: Gtm.GroupName.Product,
                name: Gtm.ProductEventName.AddToCart,
                data: {
                  item: {
                    productId: shopifyGidToId(lineItem.variant.product.id) + '',
                    productTitle: lineItem.title,
                    variantTitle: lineItem.variant.title,
                    variantId: product.variantId + '',
                    productType: lineItem.variant.product.productType,
                    sku: lineItem.variant.sku,
                    price: Math.round(
                      parseFloat(lineItem.variant.priceV2.amount) * 100
                    ),
                    tags: lineItem.variant.product.tags.join(','),
                    quantity: product.quantity,
                    interval:
                      product.customAttributes &&
                      product.customAttributes[0].value,
                  },
                },
              });
            }
          });
        } catch (error) {
          console.error(error);
          throw error;
        } finally {
          const query = router.query;
          if (query.add) delete query.add;
          router.replace(
            {
              pathname: router.pathname,
              query,
            },
            undefined,
            { shallow: true }
          );
        }
      };
      _addProductsToCart();
    }
  }, [isCartReady, addProductsToCart, router]);

  return {
    canUseCoupon,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsRedemptionItem,
    containsSubscriptionItem,
    discount,
    effectiveCart,
    effectiveCartErrors,
    insufficientMoney,
    isBonusModalOpen,
    isCartEmpty,
    isCartUpdating,
    isCheckoutBeingCreated,
    items,
    itemsCount,
    loading: checkoutLoading,
    minimumBasketValue,
    mostExpensiveProduct,
    oosItems,
    shouldShowMinimumBasketNotice,
    goToCheckout,
    setCouponCode,
    setIsBonusModalOpen,
  };
};
